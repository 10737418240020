import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { InputFieldProps } from '../types';

export interface StackedInputProps extends InputFieldProps {
  label?: string;
  helperText?: React.ReactNode;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

const StackedInput = React.forwardRef<HTMLInputElement, StackedInputProps>(
  (
    {
      id,
      label,
      isInvalid,
      helperText,
      errorText,
      type = 'text',
      isRequired,
      leftElement,
      rightElement,
      ...props
    },
    _ref
  ) => {
    return (
      <FormControl id={id} isInvalid={isInvalid} isRequired={isRequired}>
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup>
          {leftElement && leftElement}
          <Input ref={_ref} type={type} isRequired={isRequired} {...props} />
          {rightElement && rightElement}
        </InputGroup>
        {isInvalid ? (
          <FormErrorMessage>{errorText}</FormErrorMessage>
        ) : (
          helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default StackedInput;
