/**
 * A utility function that copies a given text string to the user's clipboard
 * @param text - the string value to copy
 * @returns `void`
 */
export const copyTextToClipboard = (text: string) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text);
  }
  document.execCommand('copy', true, text);
};
