import { URLAttachment as URLAttachmentType } from 'dashboard/models/Message';
import * as React from 'react';

import { Image } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

interface URLAttachmentProps {
  urlAttachment: URLAttachmentType;
  isMobile: boolean;
  style?: React.CSSProperties;
}

const URLAttachment: React.FC<URLAttachmentProps> = ({
  urlAttachment,
  isMobile,
  style,
}) => {
  const renderTextContainer = () => {
    const title = urlAttachment.title || '';
    const url = urlAttachment.url || '';
    const description = urlAttachment.description || '';
    return (
      <div style={styles.textContainer}>
        {title && (
          <div style={styles.title}>
            {title.length > 60 ? title.slice(0, 60) + '...' : title}
          </div>
        )}
        {url && (
          <div style={styles.url}>
            {url.length > 60 ? url.slice(0, 60) + '...' : url}
          </div>
        )}
        {description && (
          <div style={styles.description}>
            {description.length > 175
              ? description.slice(0, 175) + '...'
              : description}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        ...styles.mainContainer,
        maxWidth: isMobile ? '100%' : '30em',
        ...style,
      }}
      role="button"
      tabIndex={0}
      onKeyDown={() => window.open(urlAttachment.url, '_blank')}
      onClick={() => window.open(urlAttachment.url, '_blank')}
    >
      {urlAttachment.image ? (
        <Image
          objectFit="contain"
          onClick={() => null}
          src={urlAttachment.image}
          style={styles.image}
        />
      ) : null}
      {renderTextContainer()}
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    cursor: 'pointer',
  },
  image: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    cursor: 'pointer',
  },
  textContainer: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '10px 5px',
  },
  title: {
    color: '#101b4f',
    fontSize: 15,
    fontWeight: 600,
    overflowWrap: 'break-word',
    marginBottom: 1,
  },
  url: {
    fontSize: 11,
    fontWeight: 500,
    overflowWrap: 'break-word',
    marginBottom: 3,
    color: 'cornflowerblue',
  },
  description: {
    color: '#101b4f',
    fontSize: 14,
    fontWeight: 300,
    overflowWrap: 'break-word',
  },
});

export default URLAttachment;
