import * as React from 'react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

const Footer: React.FC = () => (
  <div style={styles.mainContainer}>
    <div style={styles.subContainer}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://xqmsg.co/about"
        style={styles.link}
      >
        About
      </a>
      <div style={styles.dividerBullet}>&#9679;</div>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://xqmsg.co/contact-us"
        style={styles.link}
      >
        Contact Us
      </a>
      <div style={styles.dividerBullet}>&#9679;</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://xqmsg.co/privacy-policy"
        style={styles.link}
      >
        Privacy Policy
      </a>
      <div style={styles.dividerBullet}>&#9679;</div>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://xqmsg.co/terms"
        style={styles.link}
      >
        Terms of Service
      </a>
    </div>
  </div>
);

const styles = StyleSheet.create({
  mainContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    backgroundColor: '#fbfbfb',
    width: '100%',
    display: 'flex',
    height: '1.5em',
  },
  subContainer: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#fbfbfb',
  },
  link: {
    color: '#aeb0b9',
    fontSize: 12,
  },
  dividerBullet: { fontSize: 5, margin: '0 10px', color: '#aeb0b9' },
});

export default Footer;
