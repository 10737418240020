import { resetMessageState } from 'store/reducers/messages';
import {
  DesktopViewOptions,
  MobileViewOptions,
  setDesktopView,
  setMobileView,
} from 'store/reducers/application';
import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setSelectedSettings } from 'dashboard/services/auth';
import { setSelectedConversationById } from 'store/reducers/conversations';

const revertToEmptyState = (dispatch: Dispatch) => {
  dispatch(setDesktopView(DesktopViewOptions.EMPTY_STATE));
  dispatch(setMobileView(MobileViewOptions.CONVERSATION_LIST));
};

const navigateToCleanThread = (dispatch: Dispatch, conversationId: string) => {
  dispatch(setSelectedConversationById(conversationId));
  dispatch(setDesktopView(DesktopViewOptions.THREAD));
  dispatch(setMobileView(MobileViewOptions.THREAD));
  dispatch(resetMessageState());
};

const startLoadingSequence = (dispatch: Dispatch) => {
  dispatch(setDesktopView(DesktopViewOptions.LOADING_CONVERSATION));
  dispatch(setMobileView(MobileViewOptions.LOADING_CONVERSATION));
};

/**
 * a thunk utilized to select a new conversation and update the user's selection settings
 * @param conversationId - the conversation ID to switch to
 * @param workspaceId - an optional value, the workspace ID of the current conversation ID
 */

export const onSelectConversation = createAsyncThunk<
  void,
  { conversationId: string; workspaceId?: string }
>(
  'conversations/select',
  async ({ conversationId = '', workspaceId = '' }, { dispatch, getState }) => {
    const {
      conversations: { selectedConversation },
      user: { currentUser, currentWorkspaceId },
      application: { currentView },
    } = getState() as RootState;

    const updatedWorkspaceId = workspaceId || currentWorkspaceId;

    // fallback to an empty state if there is no selected conversation AND no other conversation
    // to select from
    if (!conversationId && !selectedConversation.id) {
      return revertToEmptyState(dispatch);
    }

    if (conversationId === selectedConversation.id) {
      // only check within desktop environemnt since the user can only see the thread in mobile
      // and has to navigate back to the conversation list to select another.
      if (currentView.desktop.main === DesktopViewOptions.THREAD) {
        // if the user is focused on a given thread, they should not be able to re-select a thread
        // re-selection would cause a re-render and fetch the already existing data
        return;
      }

      return navigateToCleanThread(dispatch, conversationId);
    }

    // start loading sequence
    startLoadingSequence(dispatch);

    await setSelectedSettings(
      currentUser.id,
      conversationId,
      updatedWorkspaceId
    );

    if (!conversationId) {
      return revertToEmptyState(dispatch);
    }

    return navigateToCleanThread(dispatch, conversationId);
  }
);
