import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { useMemo } from 'react';

/**
 * This hook is used for getting the recipient list for a selected conversation for the initial
 * conversation name, as well as the initials for the initial conversation avatar badge.
 *
 * @param selectedConversation current selected conversation
 * @param currentUser current authorized user
 * @returns string with the list of users in a conversation
 */
export const useGetRecipientsString = (
  selectedConversation: ProcessedConversation,
  currentUser: User
) => {
  const recipients = useMemo(() => {
    if (!selectedConversation) {
      return '';
    }

    const isMultiUserConversation = selectedConversation.recipients.length > 2;

    const otherRecipientUsers = selectedConversation.recipients.filter(
      (user: { id: string; name: string; email: string }) =>
        user.id !== currentUser.id
    );

    if (isMultiUserConversation) {
      return (
        otherRecipientUsers
          .map((user: { name: string; email: string }) =>
            user.name ? user.name : user.email
          )
          .join(', ')
          .substring(0, 30) + '...'
      );
    }

    // default to name, else use email
    let name = otherRecipientUsers[0].name || otherRecipientUsers[0].email;

    if (!name) {
      return '';
    }

    if (name.length > 30) {
      name = name.substring(0, 30) + '...';
    }

    return name;
  }, [selectedConversation, currentUser.id]);

  return recipients;
};
