import React, { useState } from 'react';
import { Input, Button, Box } from '@chakra-ui/react';

import NameScreenState from 'dashboard/features/AddScreenName/NameScreenState';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { DeviceType } from 'dashboard/models/Device';
import { User } from 'dashboard/models/User';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';

type AddScreenNameProps = {
  currentDeviceType: DeviceType;
  currentUser: User;
  onConfirmAddName: (name: string) => void;
};

const AddScreenName: React.FC<AddScreenNameProps> = ({
  currentDeviceType,
  currentUser,
  onConfirmAddName,
}) => {
  const [name, setName] = useState('');

  const handleSubmit = (key: string) => {
    if (key === 'Enter') {
      onConfirmAddName(name);
    }
  };

  const onUseEmailButtonClick = async () => {
    return onConfirmAddName(currentUser.email);
  };

  return (
    <Box
      style={{
        width: '95%',
      }}
      height={useIsLargerThan980() ? '100vh' : 'unset'}
    >
      <div
        style={
          currentDeviceType === DeviceType.MOBILE
            ? styles.mainMobileContainer
            : styles.mainDesktopContainer
        }
      >
        <div style={{ marginBottom: 20 }}>
          <NameScreenState />
        </div>
        <Input
          placeholder="Enter Your Screen Name Here"
          style={{
            ...styles.input,
            width: currentDeviceType === DeviceType.MOBILE ? '100%' : '70%',
          }}
          variant="filled"
          value={name}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setName(e.currentTarget.value)
          }
          onKeyDown={(e) => handleSubmit(e.key)}
        />

        <span style={{ marginTop: 25 }}>OR</span>

        <Button
          style={styles.useEmailButton}
          onClick={onUseEmailButtonClick}
          colorScheme="transparent"
        >
          <span style={styles.useEmailButtonText}>
            I&apos;ll just use my email for now
          </span>
        </Button>
      </div>
    </Box>
  );
};

const styles = StyleSheet.create({
  mainMobileContainer: {
    position: 'relative',
    overflowY: 'auto',
    top: '8em',
    width: '100%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainDesktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%',
    width: '100%',
    alignSelf: 'center',
  },
  input: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 6,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.06)',
    display: 'flex',
    fontSize: 18,
    height: '3em',
    width: '100%',
    marginTop: '15px',
  },
  useEmailButton: {
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  useEmailButtonText: {
    color: 'cornflowerblue',
    fontSize: 18,
    cursor: 'pointer',
    userSelect: 'none',
  },
});

export default AddScreenName;
