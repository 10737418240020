import * as React from 'react';

import { User } from 'dashboard/models/User';
import { ProcessedConversation } from 'dashboard/models/Conversation';

import BackButton from 'dashboard/components/Backbutton';
import ConversationMenu from 'dashboard/components/ConversationMenu';
import RecipientHeader from 'dashboard/features/DashboardHeader/components/RecipientHeader';
import { IconButton } from '@chakra-ui/react';

import { StyleSheet } from 'dashboard/scss/StyleSheet';

import secureLock from 'assets/svg/icon-security-small-active-copy.svg';
import { DeviceType } from 'dashboard/models/Device';
import getRecipientList from '../utils/getRecipientList';
import { useGetHeaderText } from '../hooks/useGetHeaderText';

interface MobileThreadHeaderProps {
  currentUser: User;
  selectedConversation: ProcessedConversation;
  onNavigateBack: () => void;
  onActivateProfileMode: (user: User) => void;
  onActivateLocatorTokenMode: () => void;
  selectedConversationId: string;
}

/**
 * A component which renders the Dashboard header for the secure chat mobile variant
 */
const ThreadHeader: React.FC<MobileThreadHeaderProps> = ({
  currentUser,
  selectedConversation,
  onNavigateBack,
  onActivateProfileMode,
  onActivateLocatorTokenMode,
  selectedConversationId,
}) => {
  const recipientList = getRecipientList(
    selectedConversation.recipients,
    currentUser.id
  );

  const headerText = useGetHeaderText(recipientList, selectedConversation);

  if (!selectedConversation) {
    return null;
  }

  return (
    <div style={styles.mainContainer}>
      <BackButton onClick={onNavigateBack} />

      <div style={styles.headerCenterContainer}>
        <RecipientHeader
          selectedConversation={selectedConversation}
          onActivateProfileMode={onActivateProfileMode}
          currentDeviceType={DeviceType.MOBILE}
          headerText={selectedConversation.name || headerText}
          recipientList={recipientList}
          currentUser={currentUser}
          avatar={selectedConversation.avatar}
        />
        {!currentUser.settings.isAliasUser && (
          <IconButton
            onClick={onActivateLocatorTokenMode}
            aria-label="secure-lock"
            size="sm"
            style={{
              ...styles.iconButton,
              marginLeft: recipientList.length > 1 ? 4 : 8,
            }}
            isRound
          >
            <img alt="xqLogo" style={styles.lockIcon} src={secureLock} />
          </IconButton>
        )}
      </div>

      <ConversationMenu conversationId={selectedConversationId} />
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  backIcon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
  headerCenterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  lockIconContainer: {
    color: 'green',
    display: 'flex',
    alignItems: 'center',
  },
  lockIcon: { display: 'initial', width: 24, height: 24 },
  iconButton: {
    backgroundColor: 'white',
  },
});

export default ThreadHeader;
