import React from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';

import 'dashboard/scss/Home.scss';

import {
  setLocatorTokenMode,
  setSelectedConversationById,
  setSelectedMessage,
} from 'store/reducers/conversations';
import {
  DesktopViewOptions,
  MobileViewOptions,
  setDesktopView,
  setMobileView,
  setSidebarView,
  SidebarViewOptions,
} from 'store/reducers/application';
import { setCurrentUser, setSelectedUserProfile } from 'store/reducers/user';

import useSelectConversation from 'dashboard/hooks/useSelectConversation';
import useInitializeConversations from 'dashboard/hooks/useInitializeConversations';

import ActivityView from 'dashboard/features/Sidebar/components/SecurityPanel/ActivityView';
import { updateUser } from 'dashboard/services/auth';

import NavigationSidebar from 'dashboard/features/NavigationSidebar';
import { ProcessedMessage } from 'dashboard/models/Message';
import { User, UserModelFields } from 'dashboard/models/User';
import { DeviceType } from 'dashboard/models/Device';

import AddScreenName from 'dashboard/features/AddScreenName';
import ComposeConversation from 'dashboard/features/ComposeConversation';
import Sidebar from 'dashboard/features/Sidebar';
import DashboardHeader from 'dashboard/features/DashboardHeader/index';
import DesktopEmptyState from 'dashboard/features/Thread/components/EmptyState';
import FeedbackForm from 'dashboard/features/FeedbackForm';
import LoadingScreen from 'dashboard/components/LoadingScreen';
import Thread from 'dashboard/features/Thread';
import { Box, Flex } from '@chakra-ui/react';
import useSetMessageListViewportHeight from './features/Thread/hooks/useSetMessageListViewportHeight';
import { useIsLargerThan980 } from './hooks/useDeviceType';

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const {
    application: {
      currentDeviceType,
      currentView,
      isFeedbackMode,
      messageListViewportHeight,
      threadOuterHeight,
    },
    conversations: {
      isLocatorTokenMode,
      selectedConversation,
      selectedMessage,
    },
    user: { currentUser },
  } = useSelector((state: RootState) => ({
    application: state.application,
    conversations: state.conversations,
    user: state.user,
  }));

  /** A function utilized to activate the Add Name screen for desktop and mobile views */
  const onActivateAddNameMode = () => {
    dispatch(setDesktopView(DesktopViewOptions.ADD_NAME));
    dispatch(setMobileView(MobileViewOptions.ADD_NAME));
  };

  // subscribe to firestore conversations
  const [isInit] = useInitializeConversations(currentUser);

  // handle selecting a conversation
  useSelectConversation(isInit, onActivateAddNameMode);

  // determine height of message list
  useSetMessageListViewportHeight(messageListViewportHeight);

  const onNavigateBack = () => {
    dispatch(setSelectedConversationById(''));
    onResetSidebarView();
  };

  /** A function utilized to activate the security screen of the side bar (desktop) and mobile views */
  const onActivateLocatorTokenMode = () => {
    dispatch(setLocatorTokenMode(!isLocatorTokenMode));
  };

  /** A function utilized to activate the Compose message screen for desktop and mobile views */
  const onActivateComposeMode = () => {
    // There should not be a selected conversation when composing a new message
    dispatch(setSelectedConversationById(''));

    if (currentView.desktop.sidebar !== SidebarViewOptions.CONVERSATION_LIST) {
      dispatch(setSidebarView(SidebarViewOptions.CONVERSATION_LIST));
    }

    dispatch(setDesktopView(DesktopViewOptions.COMPOSE));
    dispatch(setMobileView(MobileViewOptions.COMPOSE));
  };

  /** A function utilized to activate the Profile screen of a given user */
  const onActivateProfileMode = (user: User) => {
    dispatch(setSelectedUserProfile(user));

    dispatch(setSidebarView(SidebarViewOptions.PROFILE));
    dispatch(setMobileView(MobileViewOptions.PROFILE));
  };

  /** A function utilized to select a given message and set it as the currently selected message */
  const onSelectMessage = (message: ProcessedMessage) => {
    dispatch(setSidebarView(SidebarViewOptions.SECURITY));
    dispatch(setMobileView(MobileViewOptions.SECURITY));
    dispatch(setSelectedMessage(message));
  };

  const onResetSidebarView = () => {
    dispatch(setSidebarView(SidebarViewOptions.CONVERSATION_LIST));
    dispatch(setMobileView(MobileViewOptions.CONVERSATION_LIST));
  };

  /** A function utilized to update the name of the current user. Typically used in the Add Name screen */
  const onConfirmAddName = async (name: string) => {
    const updatedUserData = {
      [UserModelFields.NAME]: name,
    };
    dispatch(setCurrentUser({ ...currentUser, name }));
    await updateUser(updatedUserData, currentUser);
    dispatch(setDesktopView(DesktopViewOptions.THREAD));
    dispatch(setMobileView(MobileViewOptions.THREAD));
  };

  const isLargerThan980 = useIsLargerThan980();

  /**
   * A function utilized to render specific screens based on the current View, dependent on the current device type
   */
  const renderBody = () => {
    if (currentDeviceType === DeviceType.MOBILE) {
      switch (currentView.mobile) {
        case MobileViewOptions.CONVERSATION_LIST:
        case MobileViewOptions.PROFILE:
          return (
            <Sidebar
              onActivateComposeMode={onActivateComposeMode}
              onResetSidebarView={onResetSidebarView}
            />
          );
        case MobileViewOptions.CONVERSATION_SETTINGS:
          return (
            <Sidebar
              onActivateComposeMode={onActivateComposeMode}
              onResetSidebarView={onResetSidebarView}
            />
          );
        case MobileViewOptions.COMPOSE:
          return <ComposeConversation isMobile />;
        case MobileViewOptions.THREAD:
          return (
            <Box h={`calc(${threadOuterHeight}px)`} w="100%">
              <Thread onSelectMessage={onSelectMessage} />
            </Box>
          );

        case MobileViewOptions.LOADING_CONVERSATION:
          return <LoadingScreen text="conversation" />;
        case MobileViewOptions.LOADING:
          return <LoadingScreen text="application" />;
        case MobileViewOptions.SECURITY:
          return (
            <ActivityView
              onNavigateBack={() => {
                dispatch(setDesktopView(DesktopViewOptions.THREAD));
                dispatch(setMobileView(MobileViewOptions.THREAD));
              }}
              selectedMessage={selectedMessage}
              isMobile
            />
          );
        case MobileViewOptions.ADD_NAME:
          return (
            <AddScreenName
              currentDeviceType={currentDeviceType}
              currentUser={currentUser}
              onConfirmAddName={onConfirmAddName}
            />
          );
      }
    }

    if (currentDeviceType === DeviceType.DESKTOP) {
      switch (currentView.desktop.main) {
        case DesktopViewOptions.LOADING_CONVERSATION:
          return <LoadingScreen text="conversation" />;
        case DesktopViewOptions.LOADING:
          return <LoadingScreen text="application" />;
        case DesktopViewOptions.EMPTY_STATE:
          return (
            <DesktopEmptyState
              currentUser={currentUser}
              onActivateComposeMode={onActivateComposeMode}
            />
          );
        case DesktopViewOptions.THREAD:
          return <Thread onSelectMessage={onSelectMessage} />;
        case DesktopViewOptions.COMPOSE:
          return <ComposeConversation isMobile={false} />;
        case DesktopViewOptions.ADD_NAME:
          return (
            <AddScreenName
              currentDeviceType={currentDeviceType}
              currentUser={currentUser}
              onConfirmAddName={onConfirmAddName}
            />
          );
        case DesktopViewOptions.FEEDBACK_FORM:
          return <FeedbackForm />;
      }
    }
  };

  const renderMainView = () => {
    if (isFeedbackMode) {
      return <FeedbackForm />;
    }
    return (
      <Flex
        width="100%"
        // mobile device's can't have 100vh here or it'll cause additional whitespacing issues with the message input
        height={isLargerThan980 ? '100vh' : 'unset'}
        flexDirection="column"
      >
        <DashboardHeader
          currentDeviceType={currentDeviceType}
          currentUser={currentUser}
          currentView={currentView}
          onActivateProfileMode={onActivateProfileMode}
          onActivateLocatorTokenMode={onActivateLocatorTokenMode}
          onNavigateBack={onNavigateBack}
          selectedConversation={selectedConversation}
        />

        <Flex overflowY="hidden" height="100%">
          {renderBody()}
          {currentDeviceType === DeviceType.DESKTOP && (
            <Sidebar
              onActivateComposeMode={onActivateComposeMode}
              onResetSidebarView={onResetSidebarView}
            />
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="row" width="100vw" overflowY="hidden">
      {currentDeviceType === DeviceType.DESKTOP && <NavigationSidebar />}
      {renderMainView()}
    </Flex>
  );
};

export default Home;
