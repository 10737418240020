import React from 'react';

import { Box } from '@chakra-ui/react';

import goodKey from 'assets/svg/icon-key-condition-good.svg';
import badKey from 'assets/svg/icon-key-condition-alert.svg';

import styles from './LocatorToken.module.scss';

interface LocatorTokenProps {
  isCurrentUser: boolean;
  isVisible: boolean;
  locatorToken: string;
  messageStatus: number;
  onClick: () => void;
}

/**
 * A functional component which renders the locator token icon and text below a given message. Only active in security mode.
 * @param isCurrentUser - `boolean`; represents whether or not a user is the owner of this message
 * @param isVisible - `boolean`; represents whether or not security mode is activated.
 * @param messageState - `number`; represents the current HTTP response status code of the message
 * @param locatorToken - `string`; represents the locator token of the given message
 * @param onClick - `() => void`; selects a message based on the displayed message's locator token
 * @returns `React.FC`
 */
const LocatorToken: React.FC<LocatorTokenProps> = ({
  isCurrentUser,
  isVisible,
  locatorToken,
  messageStatus,
  onClick,
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <Box
      className={`${styles['main-container']} ${
        isCurrentUser && styles['container-current-user']
      }`}
    >
      <img src={messageStatus === 200 ? goodKey : badKey} alt="key" />
      <Box
        className={`${styles.text} ${
          isCurrentUser && styles['text-current-user']
        }`}
        onClick={onClick}
        as="button"
      >
        {locatorToken}
      </Box>
    </Box>
  );
};

export default LocatorToken;
