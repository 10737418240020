import { ProcessedConversation } from 'dashboard/models/Conversation';
import {
  EncryptedMessageDocument,
  MessageState,
  ProcessedMessage,
} from 'dashboard/models/Message';
import { User } from 'dashboard/models/User';
import { isAfter } from 'date-fns';

interface filterMessageDocumentsProps {
  docs: EncryptedMessageDocument[];
  existingMessageIds: string[];
  selectedConversation: ProcessedConversation;
  currentUser: User;
  processedMessages?: ProcessedMessage[];
}

const filterMessageDocuments = ({
  docs,
  existingMessageIds,
  selectedConversation,
  currentUser,
  processedMessages,
}: filterMessageDocumentsProps) => {
  return (
    docs
      .filter((messageDocument) => {
        if (
          processedMessages &&
          messageDocument.state === MessageState.DELETED
        ) {
          //  Ensure all deleted messages are listened for and handled messages are listened for and handled
          const findProcessedMessage = processedMessages.find(
            (message) => messageDocument.id === message.id
          );
          if (
            findProcessedMessage &&
            findProcessedMessage.text !== 'This message has been deleted'
          ) {
            return messageDocument;
          }
        }
        return !existingMessageIds.includes(messageDocument.id);
      })

      // filter by ensuring no hidden messages are processed/decrypted
      .filter((messageDocument) => {
        const isHidden = selectedConversation.isHidden[currentUser.id];

        const isHiddenDate = isHidden?.date?.toDate();
        const messageDate = messageDocument.date.toDate();

        if (!isHiddenDate) {
          return messageDocument;
        }

        return isAfter(messageDate, isHiddenDate);
      })
  );
};

export default filterMessageDocuments;
