import * as React from 'react';

import { BusinessOverview } from 'backend/api-types/dashboard';

import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';

import ConversationSection from 'dashboard/features/Sidebar/components/ConversationSection';
import SearchBar from 'dashboard/features/Sidebar/components/SearchBar';
import PeopleSection from 'dashboard/features/Sidebar/components/PeopleSection';
import { Flex } from '@chakra-ui/react';

interface ConversationPanelProps {
  conversationList: ProcessedConversation[];
  currentUser: User;
  currentWorkspaceId: string;
  searchInput: string;
  selectedConversation: ProcessedConversation;
  setSearchInput: (input: string) => void;
  workspacesList: BusinessOverview[];
}

const ConversationPanel: React.FC<ConversationPanelProps> = ({
  conversationList,
  currentUser,
  currentWorkspaceId,
  selectedConversation,
  searchInput,
  setSearchInput,
  workspacesList,
}) => {
  const externalConversation = conversationList.filter(
    (conversation) => Number.parseInt(conversation.workspaceId, 10) === 0
  );

  const renderExternalConversationSection = () => (
    <ConversationSection
      selectedConversationId={selectedConversation.id}
      conversationList={externalConversation}
      currentUser={currentUser}
      headerTitle="External"
      searchInput={searchInput}
      currentWorkspaceId={currentWorkspaceId}
    />
  );

  const renderWorkspaceConversationSections = () =>
    workspacesList
      .filter(
        (workspace: { id: number; name: string }) =>
          conversationList.filter(
            (conversation) =>
              conversation.workspaceId === workspace.id.toString()
          ).length
      )
      .sort(
        (a: { id: number; name: string }, b: { id: number; name: string }) =>
          a.name.localeCompare(b.name)
      )
      .map((workspace: { id: number; name: string }) => (
        <ConversationSection
          selectedConversationId={selectedConversation.id}
          conversationList={conversationList.filter(
            (conversation) =>
              conversation.workspaceId === workspace.id.toString()
          )}
          currentUser={currentUser}
          headerTitle={workspace.name}
          key={workspace.id}
          searchInput={searchInput}
          currentWorkspaceId={currentWorkspaceId}
        />
      ));

  return (
    <Flex style={{ width: '100%' }} flexDirection="column" height="fit-content">
      <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
      <PeopleSection
        currentUser={currentUser}
        resetSearchInput={() => setSearchInput('')}
        searchInput={searchInput}
      />
      {externalConversation.length > 0 && renderExternalConversationSection()}
      {renderWorkspaceConversationSections()}
    </Flex>
  );
};

export default ConversationPanel;
