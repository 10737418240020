import { baseUserDocument, User, UserModelFields } from 'dashboard/models/User';
import dismissNotifications from './user/dismissNotifications';
import {
  AvailableCollections,
  FIREBASE_AUTH,
  FIREBASE_FIRESTORE,
  createFirestoreDocument,
  updateFirestoreDocument,
  getDocumentById,
} from './firebase';

/**
 * Asynchronously signs in as an anonymous user via Firebase Auth.
 *
 * If there is already an anonymous user signed in, that user will be returned; otherwise,
 * a new anonymous user identity will be created and returned.
 *
 * @note in order to interact with the Firestore database, a user must be signed in.
 */
export const FirebaseSignInAnonymously = async () => {
  await FIREBASE_AUTH.signInAnonymously();
};

export const signOutAnonymously = async () => {
  await FIREBASE_AUTH.signOut();
};

export const randomOneToFive = Math.floor(Math.random() * 6);

/**
 * A function utilized to find or create a user document. If the user doesn't exist it will save it to the `users` collection in firebase for a new user.
 * If a user with the same email already exists we simply cancel the save operation and return the document.
 * @param payload - a Map of `email`, `name`, and `isAliasUser`
 * @property email - a string value representing the email of the new user
 * @property name - an optional string value representing the name of the new user
 * @property isAliasUser - an optional boolean value representing whether or not a user is an Alias user (guest)
 * @returns Promise<void | null | User>
 */
export const findOrCreateUser = async ({
  email,
  name = '',
  isAliasUser = false,
}: {
  email: string;
  name?: string;
  isAliasUser?: boolean;
}) => {
  const userQueryDocument = await getDocumentById(
    UserModelFields.EMAIL,
    [email],
    AvailableCollections.USERS
  );

  const isUserDocumentFound = !!userQueryDocument[0];

  if (isUserDocumentFound) {
    // the user exists based on our initial query via email. Simply return. In most cases, this data isn't used.
    // as of 3.15.22, the only use case is for alias/guest users entering the app
    const existingUserData = userQueryDocument[0] as User;

    return existingUserData;
  }

  const newUserDocumentReferenceId = FIREBASE_FIRESTORE.collection(
    AvailableCollections.USERS
  ).doc().id;

  const newUserDocument: User = {
    ...baseUserDocument,
    [UserModelFields.AVATAR]: `icon_avatar_chat_${randomOneToFive}.svg`,
    [UserModelFields.CREATED_AT]: new Date(),
    [UserModelFields.EMAIL]: email,
    [UserModelFields.ID]: newUserDocumentReferenceId,
    [UserModelFields.NAME]: name,
    [UserModelFields.SETTINGS]: {
      ...baseUserDocument[UserModelFields.SETTINGS],
      isAliasUser,
    },
  };

  await createFirestoreDocument(
    AvailableCollections.USERS,
    newUserDocumentReferenceId,
    newUserDocument
  );

  return newUserDocument;
};

/**
 * A function utilized to update a user document. Updates made will overwrite
 * the relevant fields and their data in Firestore.
 *
 * @param data - `Partial<User>`, the data of the `User` document to be updated.
 * @param currentUser - the current `User`'s document data
 * @returns `Promise<void>`
 * */
export const updateUser = async (data: Partial<User>, currentUser: User) => {
  await updateFirestoreDocument(
    AvailableCollections.USERS,
    currentUser.id,
    data
  );
};

/**
 * A function utilized to update a user document's `selected` settings map field.
 * We keep track of the user's selected `workspaceId` and `converationId`.
 *
 * @param userId - `string`, the current `User`'s id
 * @param conversationId - `string`, the `Conversation` id which is being selected
 * @param workspaceId - `string`, the `Workspace` id which is being selected
 * @returns `Promise<void>`
 * */
export const setSelectedSettings = async (
  userId: string,
  conversationId: string,
  workspaceId: string
) => {
  if (userId) {
    await updateFirestoreDocument(AvailableCollections.USERS, userId, {
      selected: {
        workspaceId,
        conversationId,
      },
    });

    const _updateNotifications = await dismissNotifications(
      userId,
      conversationId
    );
  }
};
