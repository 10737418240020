import getRecipientList from 'dashboard/features/DashboardHeader/utils/getRecipientList';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';

const getMessageInputPlaceholder = (
  selectedConversation: ProcessedConversation,
  currentUser: User
) => {
  if (!selectedConversation) {
    return '';
  }

  const currentUserId = currentUser?.id;

  const recipientList = getRecipientList(
    selectedConversation.recipients,
    currentUserId
  );
  const firstRecipient = recipientList[0]?.name || recipientList[0]?.email;

  if (recipientList.length === 1) {
    return `Message ${firstRecipient}`;
  }

  const secondRecipient = recipientList[1]?.name || recipientList[1]?.email;

  const placeholder =
    recipientList.length > 2
      ? `${firstRecipient} and ${recipientList.length - 1} others`
      : `${firstRecipient} and ${secondRecipient}`;

  return `Message ${placeholder}`;
};

export default getMessageInputPlaceholder;
