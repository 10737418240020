import React from 'react';

import { CurrentView } from 'store/reducers/application';

import { DeviceType } from 'dashboard/models/Device';
import { User } from 'dashboard/models/User';
import { ProcessedConversation } from 'dashboard/models/Conversation';

import { Box } from '@chakra-ui/react';
import HeaderWeb from './HeaderWeb';
import MobileHeader from './mobile';

interface DashboardHeaderProps {
  currentDeviceType: DeviceType;
  currentUser: User;
  currentView: CurrentView;
  onActivateProfileMode: (user: User) => void;
  onActivateLocatorTokenMode: () => void;
  onNavigateBack: () => void;
  selectedConversation: ProcessedConversation;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  currentDeviceType,
  currentUser,
  currentView,
  onActivateProfileMode,
  onActivateLocatorTokenMode,
  onNavigateBack,
  selectedConversation,
}) => (
  <Box>
    {currentDeviceType === DeviceType.MOBILE ? (
      <MobileHeader
        currentViewMobile={currentView.mobile}
        currentUser={currentUser}
        onActivateLocatorTokenMode={onActivateLocatorTokenMode}
        onActivateProfileMode={onActivateProfileMode}
        onNavigateBack={onNavigateBack}
        selectedConversation={selectedConversation}
      />
    ) : (
      <HeaderWeb
        currentDeviceType={currentDeviceType}
        currentUser={currentUser}
        currentView={currentView}
        onActivateProfileMode={onActivateProfileMode}
        onActivateLocatorTokenMode={onActivateLocatorTokenMode}
        selectedConversation={selectedConversation}
      />
    )}
  </Box>
);

export default DashboardHeader;
