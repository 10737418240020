import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import store from 'store';
import customChakraTheme from './theme/customChakraTheme';
import App from './App';
import { MagicLinkQueryStoreContextProvider } from './auth/MagicLinkQueryStoreContext';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={customChakraTheme} resetCSS>
        <BrowserRouter>
          <HelmetProvider>
            <MagicLinkQueryStoreContextProvider>
              <App />
            </MagicLinkQueryStoreContextProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
