import axios from 'axios';

const trimTrailingSlash = (url?: string) => url?.replace(/\/+$/, '');

export const timezoneOffset = new Date().getTimezoneOffset() / 60;

// NOTE: Purposely using a strange syntax to specify the API keys. The reason for this
// is to avoid having an 'api-key' header set when the test cases are running. If
// on gets set on the test cases, we will need to explicitly mock out the OPTIONS requests
// as well as the request that we are interested in.
export const dashboardService = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_HOST,
  headers: process.env.REACT_APP_DASHBOARD_API_KEY
    ? {
        'api-key': process.env.REACT_APP_DASHBOARD_API_KEY,
      }
    : {},
});

export const dashboardServiceBaseURL = trimTrailingSlash(
  dashboardService.defaults.baseURL
);

export const subscriptionService = axios.create({
  baseURL: process.env.REACT_APP_SUBSCRIPTION_HOST,
  headers: process.env.REACT_APP_SUBSCRIPTION_API_KEY
    ? {
        'api-key': process.env.REACT_APP_SUBSCRIPTION_API_KEY,
      }
    : {},
});

export const subscriptionServiceBaseURL = trimTrailingSlash(
  subscriptionService.defaults.baseURL
);

export const validationService = axios.create({
  baseURL: process.env.REACT_APP_VALIDATION_HOST,
  headers: process.env.REACT_APP_VALIDATION_API_KEY
    ? {
        'api-key': process.env.REACT_APP_VALIDATION_API_KEY,
      }
    : {},
});

export function setAuthToken(token: string): void {
  const authHeader = `Bearer ${token}`;
  dashboardService.defaults.headers.Authorization = authHeader;
  subscriptionService.defaults.headers.Authorization = authHeader;
  validationService.defaults.headers.Authorization = authHeader;
}

export function removeAuthToken(): void {
  delete dashboardService.defaults.headers.Authorization;
  delete subscriptionService.defaults.headers.Authorization;
  delete validationService.defaults.headers.Authorization;
}
