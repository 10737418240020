import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceType } from 'dashboard/models/Device';
import { setCurrentDeviceType } from 'store/reducers/application';
import { useMediaQuery } from '@chakra-ui/react';

const useDeviceType = () => {
  const [isLargerThan1235] = useMediaQuery('(min-width: 1235px)');
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLargerThan1235) {
      dispatch(setCurrentDeviceType(DeviceType.DESKTOP));
    } else {
      dispatch(setCurrentDeviceType(DeviceType.MOBILE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargerThan1235]);
};

export default useDeviceType;

export const useIsLargerThan980 = () => {
  const [isLargerThan980] = useMediaQuery('(min-width: 980px)');
  return isLargerThan980;
};
