import { useEffect, useRef } from 'react';

/**
 * This hook can be used in functional components to determine whether or not the component is mounted
 * or unmounted. This is helpful for replicating `componentWillUnmount` behavior in a `useEffect` hook.
 */
const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return () => isMounted.current;
};

export default useIsMounted;
