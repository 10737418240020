import React, { useEffect } from 'react';
import { Image } from '@chakra-ui/react';

import { ProcessedMessage } from 'dashboard/models/Message';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import defaultFileImage from 'assets/svg/icon-lock-fil-large.svg';
import defaultFileVideo from 'assets/svg/icon-video-attachment.svg';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DeviceType } from 'dashboard/models/Device';
import { isSafari } from './detectBrowser';
import SVGComponent from './SVGComponent';

interface FileAttachmentProps {
  isImageExpanded?: boolean;
  message: ProcessedMessage;
  onExpandImage?: (messageId: string) => void;
  handleScrollToBottom?: () => void;
}

/**
 * A functional component utilized to display a `FileAttachment` from a `Message`
 * @param message - A `ProcessedMessage` (processed `MessageDocument` from Firestore)
 */
const FileAttachment: React.FC<FileAttachmentProps> = ({
  isImageExpanded,
  message,
  onExpandImage,
  handleScrollToBottom,
}) => {
  const { deviceType } = useSelector((state: RootState) => ({
    deviceType: state.application.currentDeviceType,
  }));
  useEffect(() => {
    // DisplayMessage.tsx shouldn't call scrollToBottom
    if (!handleScrollToBottom) return;
    // Scroll to bottom once videos have loaded
    const video = document?.getElementsByClassName('video-player')[0];
    video?.addEventListener('loadeddata', () => handleScrollToBottom(), false);

    return () => {
      video?.removeEventListener('loadeddata', () => handleScrollToBottom());
    };
  }, [handleScrollToBottom]);

  const { fileAttachment } = message;

  const isVideo = fileAttachment.type.includes('video');
  const isImage = fileAttachment.type.includes('image');

  const isViewable = isVideo || isImage;

  const determineImageWidth = () => {
    if (isViewable) {
      return isImageExpanded ? 400 : 250;
    }
    return '10em';
  };

  const renderVideoPlayer = () => {
    if (isVideo) {
      /**
       * @description
       * isSafari boolean is true on chrome
       * */
      // if (isSafari) {

      return (
        <video
          controls
          playsInline
          preload="metadata"
          style={{
            width: 400,
            aspectRatio: '2',
            borderRadius: 5,
          }}
          muted
          className="video-player"
          poster={
            deviceType === DeviceType.MOBILE ? defaultFileVideo : undefined
          }
        >
          <source
            src={fileAttachment.url}
            type={
              fileAttachment.type === 'video/quicktime'
                ? 'video/mp4'
                : fileAttachment.type
            }
          />
        </video>
      );
    }
    return null;

    // This never gets hit as the isSafari condition is always true
    // }
  };

  const renderImage = () => {
    if (fileAttachment.type.includes('svg')) {
      if (isSafari) {
        return (
          <Image
            onLoad={() =>
              handleScrollToBottom ? handleScrollToBottom() : null
            }
            objectFit="contain"
            src={defaultFileImage}
            alt="file-attachment"
            style={{
              width: determineImageWidth(),
              cursor: 'pointer',
            }}
          />
        );
      }

      return (
        <SVGComponent
          url={fileAttachment?.url}
          style={{
            width: determineImageWidth(),
            cursor: 'pointer',
          }}
          onClick={() =>
            isImage && onExpandImage ? onExpandImage(message.id) : null
          }
        />
      );
    }

    return (
      <Image
        onLoad={() => (handleScrollToBottom ? handleScrollToBottom() : null)}
        objectFit="contain"
        onClick={() =>
          isImage && onExpandImage ? onExpandImage(message.id) : null
        }
        src={isViewable ? fileAttachment?.url : defaultFileImage}
        alt="file-attachment"
        fallbackSrc={defaultFileImage}
        style={{
          width: determineImageWidth(),
          cursor: 'pointer',
          borderRadius: '5px',
        }}
      />
    );
  };

  return (
    <div
      style={{
        ...styles.mainContainer,
        border: isViewable ? '' : 'solid 1.5px #f0f0f0',
        padding: isViewable ? '1px' : '16px 11.4px 14px 16px',
        borderRadius: isViewable ? '' : '6',
      }}
    >
      {isVideo ? renderVideoPlayer() : renderImage()}
      <div style={styles.fileName}>{fileAttachment.name}</div>
      {!isViewable && (
        <div style={styles.sizeLabel}>{fileAttachment.size} bytes</div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 8,
  },
  sizeLabel: {
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 500,
    opacity: 0.5,
  },
  fileName: {
    fontStyle: 'italic',
    fontWeight: 500,
    overflowWrap: 'break-word',
    width: '100%',
    wordBreak: 'break-all',
  },
});

export default FileAttachment;
