import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { useMemo } from 'react';

/**
 * This hook is used for getting the header text of the initial conversation, before a user changes
 * the conversation name.
 *
 * @param recipientList list of recipients for a selected converstaion
 * @param selectedConversation the current selected conversation
 * @returns the header text for the thread on the dashboard
 */
export const useGetHeaderText = (
  recipientList: User[],
  selectedConversation: ProcessedConversation
) => {
  const headerText = useMemo(() => {
    if (!recipientList.length) {
      return '';
    }

    if (selectedConversation.name) {
      return selectedConversation.name;
    }

    const firstRecipient = recipientList[0].name || recipientList[0].email;

    if (recipientList.length === 1) {
      return `${firstRecipient}`;
    }

    const secondRecipient = recipientList[1].name || recipientList[1].email;

    const placeholder =
      recipientList.length > 2
        ? `${firstRecipient} and ${recipientList.length - 1} others`
        : `${firstRecipient} and ${secondRecipient}`;

    return `${placeholder}`;
  }, [recipientList, selectedConversation]);

  return headerText;
};
