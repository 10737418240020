import React, { useEffect, useState } from 'react';

import { Tooltip, Flex, Box } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';

import { FileResult } from 'dashboard/features/Thread';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import '../../../../scss/main.scss';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { setNewMessageViewed } from 'store/reducers/messages';

import FileAttachmentList from './Desktop/FileAttachmentList';

import DesktopInput from './Desktop';
import MobileInput from './Mobile';

interface MessageInputProps {
  fileAttachments: FileResult[];
  fileUploadProgress: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputFileRef: React.MutableRefObject<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputMessageRef: React.MutableRefObject<any>;
  inputPlaceholder: string;
  isMobile: boolean;
  isToolTipVisible: boolean;
  messageInput: string;
  onAddFileAttachment: () => void;
  onChangeFileAttachment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (acceptedFiles: File[]) => void;
  onKeyDownSubmit: (event: React.KeyboardEvent) => void;
  onRemoveFileAttachment: (fileAttachment: FileResult) => void;
  onSendMessage: () => void;
  setMessageInput: (input: string) => void;
  newMessageViewed: boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({
  fileAttachments,
  fileUploadProgress,
  inputFileRef,
  inputMessageRef,
  inputPlaceholder,
  isMobile,
  isToolTipVisible,
  messageInput,
  onAddFileAttachment,
  onChangeFileAttachment,
  onDrop,
  onRemoveFileAttachment,
  onSendMessage,
  setMessageInput,
  onKeyDownSubmit,
  newMessageViewed,
}) => {
  const [focussed, setFocussed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Focus input field when user loads chat
    inputMessageRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = () => {
    if (!newMessageViewed) {
      dispatch(setNewMessageViewed(true));
    }
  };

  return (
    <Dropzone onDrop={onDrop} noClick>
      {({ getRootProps, getInputProps }) => (
        <Flex
          flexDirection="column"
          outline="none"
          justifyContent="flex-end"
          alignItems="center"
          flex={1}
          height="fit-content"
          width="100%"
          mt="-8px"
          {...getRootProps()}
          cursor="auto"
        >
          <input {...getInputProps()} />
          <input
            type="file"
            id="file"
            multiple
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFileAttachment(e)
            }
          />
          <FileAttachmentList
            fileAttachments={fileAttachments}
            fileUploadProgress={fileUploadProgress}
            onAddFileAttachment={onAddFileAttachment}
            onRemoveFileAttachment={onRemoveFileAttachment}
          />

          <div
            style={{
              ...styles.inputSectionBackground,
            }}
          >
            <div style={styles.inputSectionContainer}>
              <Tooltip
                closeDelay={500}
                isOpen={isToolTipVisible}
                label="Message cannot be blank"
                placement="top-start"
              >
                <Box w="100%">
                  {isMobile ? (
                    <MobileInput
                      inputMessageRef={inputMessageRef}
                      onSendMessage={onSendMessage}
                      messageInput={messageInput}
                      fileAttachments={fileAttachments}
                      onAddFileAttachment={onAddFileAttachment}
                      focussed={focussed}
                      onFocus={() => setFocussed(true)}
                      onBlur={() => setFocussed(false)}
                      handleFocus={handleFocus}
                      inputPlaceholder={inputPlaceholder}
                      onKeyDownSubmit={onKeyDownSubmit}
                      setMessageInput={setMessageInput}
                    />
                  ) : (
                    <DesktopInput
                      inputMessageRef={inputMessageRef}
                      onSendMessage={onSendMessage}
                      setMessageInput={setMessageInput}
                      newMessageViewed={newMessageViewed}
                      messageInput={messageInput}
                      fileAttachments={fileAttachments}
                      onAddFileAttachment={onAddFileAttachment}
                      focussed={focussed}
                      onFocus={() => setFocussed(true)}
                      onBlur={() => setFocussed(false)}
                      handleFocus={handleFocus}
                    />
                  )}
                </Box>
              </Tooltip>
            </div>
          </div>
        </Flex>
      )}
    </Dropzone>
  );
};

const styles = StyleSheet.create({
  inputSectionBackground: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 20,
    width: '100%',
    alignSelf: 'flex-start',
  },
  inputSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '16px',
    marginRight: '16px',
  },
});

export default MessageInput;
