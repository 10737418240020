import * as React from 'react';

import BackButton from 'dashboard/components/Backbutton';

import { StyleSheet } from 'dashboard/scss/StyleSheet';

interface MobileComposeHeaderProps {
  onNavigateBack: () => void;
}

/**
 * A component which renders the Dashboard header for the Home screen
 */
const ComposeHeader: React.FC<MobileComposeHeaderProps> = ({
  onNavigateBack,
}) => {
  return (
    <div style={styles.mainContainer}>
      <BackButton onClick={onNavigateBack} />
      <div style={styles.headerText}>New Message</div>
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: 40,
  },
  iconButton: {
    height: 40,
    width: 40,
    backgroundColor: 'white',
  },
  backIcon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
  headerText: {
    color: '#101b4f',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'proxima-nova',
    margin: '0 16px',
    marginLeft: 15,
    float: 'left',
  },
});

export default ComposeHeader;
