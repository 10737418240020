import React from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';

import { Marker } from '../types';
import { generateCurvedLine } from './utils/generateCurvedLine';
import { generateLineLayer } from './utils/generateLineLayer';

interface TraceRouteProps {
  destinationPoint: Marker;
  originPoint: Marker;
}

const TraceRoute: React.FC<TraceRouteProps> = ({
  originPoint,
  destinationPoint,
}) => {
  if (!originPoint) {
    return null;
  }

  return (
    <Source
      id={`line-${destinationPoint.ip}`}
      type="geojson"
      data={
        generateCurvedLine(
          [originPoint.longitude, originPoint.latitude],
          [destinationPoint.longitude, destinationPoint.latitude]
        ) as GeoJSON.Feature
      }
    >
      <Layer
        {...(generateLineLayer(
          `line-destination-${destinationPoint.ip}`,
          destinationPoint.highestThreat,
          destinationPoint.ip === destinationPoint.originIp
        ) as LayerProps)}
      />
    </Source>
  );
};

export default TraceRoute;
