import { useDispatch, useSelector } from 'react-redux';

import { isConversationDisabled } from 'dashboard/features/Sidebar/utils/conversationFilter';
import {
  baseConversationDocument,
  ProcessedConversation,
} from 'dashboard/models/Conversation';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { DeviceType } from 'dashboard/models/Device';
import { onSelectConversation } from 'store/thunks/conversations/onSelectConversation';
import { UserModelFields } from 'dashboard/models/User';
import { updateUser } from 'dashboard/services/auth';

enum Switch {
  TRY_INVITE = 'invite',
  TRY_SAVED = 'saved',
  TRY_LATEST = 'latest',
  EMPTY = 'empty',
}

const useSelectConversation = (
  isInit: boolean,
  onActivateAddNameMode: () => void
) => {
  const [currentSwitch, setCurrentSwitch] = useState<Switch>(Switch.TRY_INVITE);

  const [isComplete, setIsComplete] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {
    application: { currentDeviceType },
    conversations: {
      conversationList,
      selectedConversation,
      selectedConversationId,
      selectedIncomingConversation,
    },
    currentUser,
  } = useSelector((state: RootState) => ({
    application: state.application,
    currentUser: state.user.currentUser,
    conversations: state.conversations,
  }));

  const isMobile = currentDeviceType === DeviceType.MOBILE;

  useEffect(() => {
    if (!selectedConversation.id && selectedConversationId) {
      const verifiedConversation = conversationList.find(
        (conversation: ProcessedConversation) =>
          conversation.id === selectedConversationId
      );

      if (verifiedConversation) {
        dispatch(
          onSelectConversation({ conversationId: selectedConversationId })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationList, selectedConversationId]);

  // remember conversation next time user signs in by saving new conversationId in firestore
  useEffect(() => {
    if (selectedIncomingConversation && currentUser.id) {
      const updatedSettings = {
        [UserModelFields.SETTINGS]: {
          ...currentUser.settings,
          conversationId: selectedIncomingConversation,
        },
      };
      updateUser(updatedSettings, currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncomingConversation]);

  useEffect(() => {
    if (isInit && !isComplete) {
      const onHandleSelectConversationId = (
        conversationId: string,
        next: Switch
      ) => {
        const verifiedConversation = conversationList.find(
          (conversation: ProcessedConversation) =>
            conversation.id === conversationId
        );

        if (verifiedConversation) {
          const isConversationActive =
            !isConversationDisabled(verifiedConversation);

          if (isConversationActive) {
            setIsComplete(true);
            dispatch(
              onSelectConversation({ conversationId: verifiedConversation.id })
            );
          }
        }

        if (next) {
          setCurrentSwitch(next);
        }
      };

      /**
       * A flow which determines which, if applicable, existing active conversation the user should
       * be navigated to. We first check if the user has a set username, then we do navigate to a
       * conversation via:
       * - if user navigated to app via magic link, navigate them to the the conversation they were invited to
       * - if user has a last "saved" conversation, i.e the last conversation they were participating in
       * - if user has _any_ conversations in their list. If so, navigate to the latest one.
       */
      const determineSelectedConversation = () => {
        // if (!currentUser.name) {
        //   // ensure user has a set username
        //   return onActivateAddNameMode();
        // }

        // @mitch is this needed?
        if (!conversationList.length) {
          onHandleSelectConversationId('', Switch.EMPTY);
        }

        switch (currentSwitch) {
          // determine if user is attempting to join a conversation via invite (includes guest users)
          case Switch.TRY_INVITE: {
            onHandleSelectConversationId(
              selectedIncomingConversation,
              Switch.TRY_SAVED
            );
            break;
          }
          // determine if user is has an active conversation they were apart of in their last session
          case Switch.TRY_SAVED: {
            /**
             * Persist the current conversationId to localStorage
             */
            const savedConversationId = currentUser.selected.conversationId;

            onHandleSelectConversationId(
              savedConversationId,
              Switch.TRY_LATEST
            );
            break;
          }

          // determine if user has any conversations we can navigate them to, if so, take 0-index of list.
          case Switch.TRY_LATEST: {
            const latestConversation =
              conversationList[0] ?? baseConversationDocument;

            const latestConversationId = latestConversation.id ?? '';

            onHandleSelectConversationId(latestConversationId, Switch.EMPTY);
            break;
          }

          case Switch.EMPTY: {
            setIsComplete(true);
            dispatch(onSelectConversation({ conversationId: '' }));
          }
        }
      };

      return determineSelectedConversation();
    }
  }, [
    conversationList,
    currentUser,
    currentSwitch,
    isComplete,
    isInit,
    selectedIncomingConversation,
    dispatch,
    isMobile,
    onActivateAddNameMode,
  ]);
};

export default useSelectConversation;
