import * as React from 'react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import { IconButton } from '@chakra-ui/react';

import { ReactComponent as Arrow } from '../assets/submit-button-desktop.svg';

interface NextButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({ isDisabled, onClick }) => (
  <div style={styles.mainContainer}>
    <IconButton
      aria-label="next-feedback-screen"
      style={styles.button}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Arrow />
    </IconButton>
    <div style={styles.textContainer}>
      <span style={styles.lineTwo}>Submit</span>
    </div>
  </div>
);

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    alignItems: 'center',
    backgroundColor: 'rgba(232,243,254, 1)',
    borderRadius: 40,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 60,
  },
  textContainer: {
    marginTop: 10,
  },
  lineOne: {
    color: 'rgba(32, 32, 32, 0.8)',
  },
  lineTwo: {
    color: 'rgba(32, 32, 32, 0.5)',
  },
});

export default NextButton;
