import {
  ConversationDocument,
  ConversationName,
  MessageExpiryTime,
} from 'dashboard/models/Conversation';

import { FileResult } from 'store/reducers/messages';

import {
  AvailableCollections,
  FIREBASE_FIRESTORE,
  FIREBASE_STORAGE,
} from '../firebase';

export const updateConversation = async ({
  conversationId,
  messageExpiryTime,
  avatar,
  conversationName,
}: {
  conversationId: string;
  messageExpiryTime?: MessageExpiryTime;
  avatar?: FileResult;
  name?: string;
  conversationName?: ConversationName;
}) => {
  const conversationDocumentReference = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  ).doc(conversationId);

  const updatedFields: Partial<ConversationDocument> = {};

  if (messageExpiryTime || conversationName) {
    // Only fetch if updated settings include messageExpiryTime or conversationName
    const existingConversationDocument = (
      await conversationDocumentReference.get()
    ).data() as ConversationDocument;

    if (messageExpiryTime) {
      updatedFields.messageExpiryTimeList = [
        ...existingConversationDocument.messageExpiryTimeList,
        messageExpiryTime,
      ];
    }

    if (conversationName) {
      // Instead of this we could do a migration, and add the conversationNameList field to each document.
      if (!existingConversationDocument.conversationNameList) {
        await conversationDocumentReference.update({
          conversationNameList: [conversationName],
          name: conversationName.value,
        });
      }

      updatedFields.name = conversationName.value;
      updatedFields.conversationNameList = [
        ...existingConversationDocument.conversationNameList,
        conversationName,
      ];
    }
  }

  if (avatar) {
    await FIREBASE_STORAGE.ref(`/conversations/${avatar.name}`).put(
      avatar.file
    );

    const fileAttachmentUrl = await FIREBASE_STORAGE.ref('conversations')
      .child(`${avatar.name}`)
      .getDownloadURL();
    updatedFields.avatar = fileAttachmentUrl;
  }

  // This operation set `isHidden` to true for the user.
  // This will hide the conversation for the current user
  // setIsHidden
  await conversationDocumentReference.set(updatedFields, { merge: true });
};
