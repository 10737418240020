import { UserOrContact } from 'dashboard/models/Contact';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { format } from 'date-fns';
import uniqBy from 'lodash/uniqBy';

/**
 * A utility function used in a filter operation for conversation search.
 *
 * If the `searchInput` is found within `latestMessage`
 * or the recipients emails then the function returns `true`
 *
 * @param {Map} conversation- the conversation object for either a one-to-one or multi-user conversation
 * @param {String} searchInput - the input from the search input bar
 *
 * @returns {Boolean} whether the conversation matches the `searchInput`
 *
 */
export const filteredCoversationSearchValidation = (
  conversation: ProcessedConversation,
  searchInput: string
): boolean => {
  if (!searchInput) {
    return true;
  }

  const input = searchInput.toLowerCase();

  /**
   * The conversation start timestamp generated as a Firebase Timestamp converted to a `Date`
   */
  const createdAtSearch = format(conversation.createdAt.toDate(), 'hh:mm a');

  if (createdAtSearch.includes(input)) {
    return true;
  }

  const updatedAtSearch = conversation.updatedAt
    ? format(conversation.updatedAt.toDate(), 'hh:mm a')
    : null;

  if (updatedAtSearch && updatedAtSearch.includes(input)) {
    return true;
  }

  const recipientSearch = conversation.recipients.filter(
    (recipient) =>
      recipient.name.includes(input) || recipient.email.includes(input)
  );

  if (recipientSearch.length) {
    return true;
  }

  return false;
};

/**
 * A utility function used in a filter operation for people search.
 *
 * If the `searchInput` is found within a user's `name` or `email` the function returns `true`
 *
 * @param {Map} user- the user object
 * @param {String} searchInput - the input from the search input bar
 *
 * @returns {Boolean} whether the user matches the `searchInput`
 *
 */

export const filteredPeopleSearchValidation = (
  currentUserEmail: string,
  user: UserOrContact,
  searchInput: string
): boolean => {
  if (!searchInput) {
    return false;
  }

  if (user.email === currentUserEmail) {
    return false;
  }

  const input = searchInput.toLowerCase();

  const name = user.name ? user.name.toLowerCase() : null;

  const email = user.email ? user.email.toLowerCase() : null;

  if (name && name.includes(input)) {
    return true;
  }

  if (email && email.includes(input)) {
    return true;
  }

  return false;
};

/**
 * A utility function used to get available contacts for the sidebar search component.
 */
export const getAvailableUsersFromConversations = (
  conversations: ProcessedConversation[],
  currentUserEmail: string,
  searchInput: string
) => {
  const users = conversations
    .map((conversation) =>
      conversation.recipients.map((recipient) => ({
        ...recipient,
        conversationId: conversation.id,
      }))
    )
    .flat()
    .map((recipient) => ({
      email: recipient.email,
      name: recipient.name,
      avatar: recipient.avatar,
      conversationId: recipient.conversationId,
    }))
    .filter((recipient) =>
      filteredPeopleSearchValidation(currentUserEmail, recipient, searchInput)
    );

  const filteredUsers = uniqBy(users, 'email');

  return filteredUsers;
};
