import { MutableRefObject } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default getWindowDimensions;

export const isScrollbarVisible = (
  element: MutableRefObject<HTMLDivElement>
) => {
  if (element.current) {
    return element.current.scrollHeight > element.current.clientHeight;
  }
};
