import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';
import Logo from 'shared/components/Logo';

export default function CenterLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const isDesktop = useIsLargerThan980();
  return (
    <>
      <Logo
        height={isDesktop ? '35px' : '20px'}
        position="absolute"
        left="24px"
        top={isDesktop ? '30px' : '19px'}
      />
      <Flex
        justifyContent="center"
        alignItems="center"
        h="100vh"
        w="100%"
        flexDirection="column"
      >
        {children}
      </Flex>
    </>
  );
}
