import { Box, Image, Text } from '@chakra-ui/react';
import { WorkspaceOptions } from 'dashboard/models/Contact';
import React from 'react';
import XBlack from '../../../../../assets/svg/x-black.svg';

export default function Recipient({
  recipient,
  handleClick,
  index,
}: {
  index: number;
  recipient: WorkspaceOptions;
  handleClick: (index: number, recipient: WorkspaceOptions) => void;
}) {
  return (
    <Box
      height="23.98px"
      background="#E6E6E6"
      borderRadius="2.17986px"
      justifyContent="space-between"
      alignItems="center"
      pl="8px"
      pr="8px"
      display="inline-flex"
      cursor="pointer"
      onClick={() => handleClick(index, recipient)}
      mr="6px"
      mb="6px"
    >
      <Text fontSize="12px">{recipient.value}</Text>
      <Image src={XBlack} alt="delete" w="8.7px" ml="7px" />
    </Box>
  );
}
