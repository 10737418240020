/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import ReactGA from 'react-ga';
import { Heading, ScaleFade } from '@chakra-ui/react';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { usePage } from 'shared/contexts/CurrentPageContext';

import { DeviceType } from 'dashboard/models/Device';
import RecentMessages from './components/RecentMessages';
import InviteNewUserForm from './InviteNewUserForm';
import useBuildWorkspaces from '../utils/useBuildWorkspaces';

const InviteNewUser = ({ isMobile }: { isMobile: boolean }) => {
  const {
    application: { currentDeviceType },
    conversations: { conversationList },
    user: {
      accessToken,
      currentUser,
      workspacesList: workspaces,
      contactsList,
    },
  } = useSelector((state: RootState) => ({
    application: state.application,
    conversations: state.conversations,
    user: state.user,
    contacts: state.user.contactsList,
  }));

  const page = usePage();
  if (!page.isCurrentPage()) {
    ReactGA.ga('clientTracker.send', {
      hitType: 'pageview',
      page: page.mkCurrentPage(),
    });
  }

  const builtWorkspaces = useBuildWorkspaces({
    workspaces: workspaces ?? [],
    conversationList,
    contacts: contactsList ?? [],
    currentUser,
  });

  return (
    <ScaleFade
      in
      style={{
        ...styles.mainContainer,
        marginTop: currentDeviceType === DeviceType.MOBILE ? '44px' : '',
      }}
    >
      <Heading style={styles.heading}>Invite as New User</Heading>
      <InviteNewUserForm
        isMobile={isMobile}
        builtWorkspaces={builtWorkspaces}
        currentUser={currentUser}
      />
      <div style={{ float: 'left', width: '100%', marginBottom: '25px' }}>
        <RecentMessages
          accessToken={accessToken}
          conversationList={conversationList}
          currentUser={currentUser}
        />
      </div>
    </ScaleFade>
  );
};
const styles = StyleSheet.create({
  mainContainer: {
    maxWidth: '464px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heading: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    paddingBottom: '11px',
    color: '#111D4C',
  },
  body: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    paddingBottom: '30px',
    color: '#111D4C',
  },
});

export default InviteNewUser;
