import * as React from 'react';

import { IconButton } from '@chakra-ui/react';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';

import xqLogo from 'assets/xq-logo-k.png';

import { User } from 'dashboard/models/User';
import { formatEmail } from 'shared/utils/stringFormatting';

import './MobileHeader.scss';

interface MobileHomeHeaderProps {
  currentUser: User;
  onActivateProfileMode: (user: User) => void;
}

/**
 * A component which renders the Dashboard header for the Home screen
 */
const HomeHeader: React.FC<MobileHomeHeaderProps> = ({
  currentUser,
  onActivateProfileMode,
}) => {
  return (
    <div id="mobile-header-container">
      <img alt="xqLogo" src={xqLogo} style={{ width: 65, height: 32.5 }} />
      <IconButton
        onClick={() => onActivateProfileMode(currentUser)}
        aria-label="avatar"
        size="sm"
        isRound
      >
        <DefaultAvatar
          name={currentUser.name || formatEmail(currentUser.email)}
          src={currentUser.avatar}
          isConversationAvatar
        />
      </IconButton>
    </div>
  );
};

export default HomeHeader;
