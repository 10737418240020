import React from 'react';

import { Avatar, Box } from '@chakra-ui/react';
import { avatarOptions } from 'dashboard/models/Avatar';
import { UserOrContact } from 'dashboard/models/Contact';

interface ContactProps {
  user: UserOrContact;
  index: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const Contact: React.FC<ContactProps> = ({
  user,
  isDisabled = false,
  isSelected = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const userAvatar = avatarOptions[user.avatar]
    ? avatarOptions[user.avatar]
    : user.avatar;

  return (
    <Box
      className="animated animatedFadeInUp fadeInUp"
      color="gray.500"
      fontWeight="semibold"
      letterSpacing="wide"
      fontSize="xs"
      overflow="hidden"
      textTransform="uppercase"
      onClick={isDisabled ? () => null : onClick}
      style={{
        ...styles.mainContainer,
        backgroundColor: isSelected ? '#B0D7FB' : '',
        userSelect: isDisabled ? 'none' : 'auto',
      }}
      onMouseEnter={isDisabled ? () => null : onMouseEnter}
      onMouseLeave={isDisabled ? () => null : onMouseLeave}
    >
      <div style={styles.contentsContainer}>
        <Avatar
          src={userAvatar}
          name={user.name || user.email}
          size="sm"
          style={styles.avatar}
        />

        <div style={styles.headerContainer}>
          <div
            style={{
              ...styles.name,
              fontWeight: isSelected ? 600 : 500,
            }}
          >
            {user.name ? `${user.name} (${user.email})` : user.email}
          </div>
        </div>
      </div>
    </Box>
  );
};

const styles = {
  mainContainer: {
    borderRadius: '5px',
    padding: '15px 5px',
  },
  contentsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
  },
  name: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap' as const,
    color: '#000000',
  },
  avatar: {
    height: '41px',
    width: '40px',
    boxShadow:
      '0 6px 8px 0 rgba(0, 0, 61, 0.2), inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    marginRight: 15,
  },
};
export default Contact;
