import React, { useMemo, useRef, useState } from 'react';
import { StaticMap as ReactMapGL, MapRef, ViewportProps } from 'react-map-gl';

import { MAP_STYLE } from './constants';
import { MapTracingProps } from './types';

import OriginPointMarker from './Markers/OriginPointMarker';
import DestinationMarkers from './Markers/Memo/DestinationMarkers';
import './styles.scss';
import TraceRoutes from './Layers/memo/TraceRoutes';
import { useFitMarkersToMap } from './hooks/useFitMarkersToMap';

/**
 *
 * This component is responsible for rendering the map containing all origin points, destination
 * points, and trace-routes for the available logs for a message.
 *
 */
const MapTracing: React.FC<MapTracingProps> = ({
  originPoint,
  destinationPoints,
}) => {
  const [viewport, setViewport] = useState<ViewportProps>({
    longitude: 0,
    latitude: 0,
  });
  const mapRef = useRef<MapRef>(null);

  const destinationMarkers = useMemo(
    () => <DestinationMarkers destinationPoints={destinationPoints} />,
    [destinationPoints]
  );

  const traceRoutes = useMemo(
    () => (
      <TraceRoutes
        destinationPoints={destinationPoints}
        originPoint={originPoint}
      />
    ),
    [originPoint, destinationPoints]
  );

  useFitMarkersToMap({
    originPoint,
    destinationPoints,
    setViewport,
    mapRef,
  });

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={MAP_STYLE}
      width="100%"
      height="250px"
      ref={mapRef}
    >
      {originPoint && (
        <OriginPointMarker
          longitude={originPoint.longitude}
          latitude={originPoint.latitude}
          threat={originPoint.highestThreat}
        />
      )}
      {!!originPoint && traceRoutes}
      {!!originPoint && destinationMarkers}
    </ReactMapGL>
  );
};

export default MapTracing;
