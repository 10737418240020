import React from 'react';

import { ReactComponent as StaticFeedbackLogo } from 'assets/svg/static-feedback.svg';

import './FeedbackFormButton.scss';

interface FeedbackFormButtonProps {
  onClick: () => void;
}

const FeedbackFormButton: React.FC<FeedbackFormButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    aria-label="add-feedback"
    type="button"
    id="feedback-button-container"
  >
    <StaticFeedbackLogo id="feedback-icon" />
  </button>
);

export default FeedbackFormButton;
