import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  CenteredLayoutHeading,
  CenteredLayoutContentInner,
} from 'shared/components/CenteredLayout';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useResendEmail, ResendMagicLinkStatus } from './useResendEmail';
import CodeError from '../components/CodeError';
import CodeSuccess from '../components/CodeSuccess';
import { Status, useLoginWithLink } from '../components/useLoginWithLink';
import CenterLayout from '../components/CenterLayout';

const EmailLoginResult: React.FC = () => {
  const { hasToken, status } = useLoginWithLink();
  const { email, handleResend, status: resendStatus } = useResendEmail();

  if (!email && !hasToken) {
    return <CodeError />;
  }

  if (hasToken) {
    return <CodeSuccess invalid={status === Status.Invalid} />;
  }

  return (
    <CenterLayout>
      <CenteredLayoutHeading>
        Check your email to access your account.
      </CenteredLayoutHeading>

      <CenteredLayoutContentInner maxWidth="2xl" textAlign="center">
        <Text mb={4}>
          We&apos;ve sent an email to <strong>{email}</strong> with instructions
          for accessing your account.
        </Text>

        <Text mb={8}>
          Didn&apos;t receive an email? Check your junk mail folder or request
          another email from us.
        </Text>

        <SpinnerButton
          type="button"
          onClick={handleResend}
          backgroundColor="hsla(216, 92%, 59%, 0.85)"
          isLoading={resendStatus === ResendMagicLinkStatus.Sending}
        >
          Resend Email
        </SpinnerButton>
      </CenteredLayoutContentInner>
    </CenterLayout>
  );
};

export default EmailLoginResult;
