import { randomOneToFive } from 'dashboard/services/auth';
import {
  AvailableCollections,
  FirestoreTimestamp,
  FIREBASE_FIRESTORE,
  setFirebaseTimestamp,
} from 'dashboard/services/firebase';

import { User } from './User';

/**
 * An interface representing a list of invites for the recipients of the conversation.
 * If the `userId` value is set to true, the specified user is an invited user.
 *
 * ex. `{ [user1]: true }` - user1 is an external guest of the `Conversation`'s workspace
 */
export interface InvitesField {
  [userId: string]: boolean;
}

export interface IsTypingField {
  [userId: string]: boolean;
}

/**
 * An interface representing a list of notifications for the recipients of the conversation.
 * Each increment represents an additional unread message for the user to view.
 *
 * ex. `{ [user1]: 2 }` - user1 has 2 unread message notifications
 */
export interface NotificationsField {
  [userId: string]: number;
}

/**
 * An interface representing a list of users who have hidden the `Conversation` from their conversation list.
 * The `now` value represents whether the hide `Conversation` value is in effect and the `date` value is when the last time the user has "hidden" the conversation (i.e deleted)
 *
 * We use the `date` value to filter messages sent prior to that deletion date in order to hide older/irrelevant messages to the user
 */
export type isHiddenField = {
  [userId: string]: {
    now: boolean;
    date: FirestoreTimestamp | null;
  };
};

export interface MessageExpiryTime {
  value: number;
  messageId: string | null;
  userId?: string;
  date: FirestoreTimestamp;
}

export interface ConversationName {
  value: string;
  messageId: string | null;
  userId?: string;
  date: FirestoreTimestamp;
}

/**
 * An interface representing a stored `Message` document in Firestore.
 *
 * @param createdAt - the creation date of the conversation represented by a firestore timestamp
 * @param id - the ID of the Message
 * @param isHidden - a Map structure that keeps track of which recipients should not see the conversation (typically after deleting converation)
 * @param isTyping - a Map structure that keeps track of which recipient is typing at a given time
 * @param recipients - a list of recipient user IDs that are participating in the conversation
 * @param updatedAt - the date of the latest message of the conversation represented by a firestore timestamp
 * @param workspaceId - the workspace ID of the conversation
 */
export interface ConversationDocument {
  createdAt: FirestoreTimestamp;
  id: string;
  invites: InvitesField;
  isHidden: isHiddenField;
  isTyping: {
    [userId: string]: boolean;
  };
  messageExpiryTimeList: MessageExpiryTime[];
  conversationNameList: ConversationName[];
  expiresAt: FirestoreTimestamp;
  longestMessageExpiryValue: number;
  avatar: string;
  name: string;
  notifications: NotificationsField;
  recipients: string[];
  sortedRecipients: string[];
  updatedAt: FirestoreTimestamp | null;
  workspaceId: string;
}

/**
 * An enum representing the current state of a message
 */
export enum ConversationState {
  ACTIVE = 'active', // Message is still encrypted
  EXPIRED = 'expired',
}

/**
 * An interface representing a processed `ConversationDocument` from Firestore.
 *
 * Since the `ConversationDocument` only provides the list of recipient IDs and not the associated
 * user metadata, we process the recipients after querying `users` via recipient ID
 * @param recipients - a list of recipient users alongside their metadata (name, email, avatar, etc)
 */
export interface ProcessedConversation
  extends Omit<ConversationDocument, 'recipients'> {
  eventLogStatus?: number;
  eventStatusMessage?: string;
  recipients: User[];
  messageExpiryTimeList: MessageExpiryTime[];
  conversationNameList: ConversationName[];
  state: ConversationState;
}

/**
 * A function utilized to set the expiry timestamp in Firestore format
 * @returns `Date` object
 */
export const setExpiresAtTimestamp = (hours: number) => {
  const createdAt = setFirebaseTimestamp().toDate();
  createdAt.setSeconds(createdAt.getSeconds() + 60 * 60 * hours);
  return setFirebaseTimestamp(createdAt);
};

export const createConversationId = () => {
  return FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS).doc()
    .id;
};

/**
 * A base template utilized to upload a `ConversationDocument` to Firestore
 */
export const baseConversationDocument: ConversationDocument = {
  createdAt: setFirebaseTimestamp(), // temporarily stub until timestamp set after starting Conversation
  id: '',
  invites: {},
  isHidden: {},
  isTyping: {},
  notifications: {},
  recipients: [],
  sortedRecipients: [],
  updatedAt: null,
  name: '',
  workspaceId: '',
  avatar: `icon_avatar_chat_${randomOneToFive}.svg`,
  expiresAt: setExpiresAtTimestamp(24),
  longestMessageExpiryValue: 24,
  messageExpiryTimeList: [
    { value: 24, messageId: null, date: setFirebaseTimestamp() },
  ],
  conversationNameList: [],
};

export const baseProcessedConversation: ProcessedConversation = {
  createdAt: setFirebaseTimestamp(), // temporarily stub until timestamp set after starting Conversation
  id: '',
  invites: {},
  isHidden: {},
  isTyping: {},
  notifications: {},
  recipients: [],
  sortedRecipients: [],
  avatar: `icon_avatar_chat_${randomOneToFive}.svg`,
  name: '',
  state: ConversationState.ACTIVE,
  updatedAt: null,
  workspaceId: '',
  expiresAt: setExpiresAtTimestamp(24),
  longestMessageExpiryValue: 24,
  messageExpiryTimeList: [
    { value: 24, messageId: null, date: setFirebaseTimestamp() },
  ],
  conversationNameList: [],
};
