import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import IntlTelInput, {
  IntlTelInputProps,
  CountryData,
} from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { ErrorResponse } from 'shared/utils/errors';
import './styles.scss';

export default function PhoneInputField({
  name,
  form,
  responseError,
  handleFocus,
  handleBlur,
  isValid,
}: {
  name: string;
  // Can use any form values for this field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<any>;
  responseError: ErrorResponse | undefined;

  handleFocus?: () => void;
  handleBlur?: () => void;
  isValid?: (arg0: boolean) => void;
}) {
  // @ts-ignore ignore key index
  const fieldError = name && form.errors[name];
  const isInvalid = !!responseError || !!fieldError;

  const handlePhoneNumberChange: IntlTelInputProps['onPhoneNumberChange'] = (
    _isValid: boolean,
    _val: string,
    _selectedCountryData: CountryData,
    _fullNumber: string
  ) => {
    // handle isValid callback
    if (typeof isValid === typeof Function) {
      // @ts-ignore undefined check is handled in condition
      isValid(_isValid);
    }

    const nextValue = _isValid ? _fullNumber.replace(/\s|-/g, '') : _val;

    return form.setValue('phone', nextValue);
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>Phone Number</FormLabel>
      <Controller
        inputClassName="form-control"
        containerClassName={`intl-tel-input overrides ${
          isInvalid ? 'overrides--error' : ''
        }`}
        as={IntlTelInput}
        control={form.control}
        fieldId={name}
        fieldName={name}
        format
        formatOnInit
        name={name}
        autoComplete="off"
        onPhoneNumberChange={handlePhoneNumberChange}
        onPhoneNumberFocus={handleFocus}
        onPhoneNumberBlur={handleBlur}
      />
      {isInvalid && (
        <FormErrorMessage>
          {fieldError?.message || responseError?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
