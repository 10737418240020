/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Box, Divider, Flex } from '@chakra-ui/react';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import useFindOrAddWorkspaces from 'dashboard/hooks/useFindOrAddWorkspaces';

import { DeviceType } from 'dashboard/models/Device';
import InviteNewUser from './InviteNewUser';
import InviteGuest from './InviteGuest';
import '../Thread/Thread.css';
import '../../scss/main.scss';

interface ComposeConversationProps {
  isMobile: boolean;
}
const ComposeConversation: React.FC<ComposeConversationProps> = ({
  isMobile,
}) => {
  const {
    application: { currentDeviceType },
    user: { currentUser },
  } = useSelector((state: RootState) => ({
    application: state.application,
    user: state.user,
  }));

  useFindOrAddWorkspaces();

  return (
    <Box
      className="scrollbar-without-transition"
      style={styles.mainContainer}
      pt={isMobile ? '5em' : 25}
      pb={isMobile ? 0 : 25}
      background="#FFFFFF"
    >
      {currentDeviceType === DeviceType.MOBILE ? (
        <>
          <InviteGuest currentUser={currentUser} />
          <Divider
            orientation="horizontal"
            style={{
              ...styles.divider,
              height: 'unset',
            }}
          />
          <InviteNewUser isMobile={isMobile} />
        </>
      ) : (
        <Flex flexDirection="row">
          <InviteNewUser isMobile={isMobile} />
          <Divider
            orientation="vertical"
            style={{
              ...styles.divider,
              height: '617px',
              marginLeft: '25px',
              marginRight: '25px',
            }}
          />
          <InviteGuest currentUser={currentUser} />
        </Flex>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // display: 'flex',
    height: '100%',
    overflowY: 'auto',
    paddingLeft: 25,
    paddingRight: 25,

    position: 'relative',
    width: '100%',
  },
  divider: {
    border: '1px solid #EDEEEF',
  },
});

export default ComposeConversation;
