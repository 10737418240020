const { REACT_APP_MAPTILER_API_KEY } = process.env;

export const MARKER_ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
export const MARKER_SIZE = 30;
export const ORIGINATION_COLOR = '#2f9207';
export const DESTINATION_COLOR = '#0a6496';
export const navControlStyle = {
  right: 10,
  bottom: 50,
};
export const MAP_STYLE = `https://api.maptiler.com/maps/2434b3f2-02bb-44dc-9d6e-aa308792a3ad/style.json?key=${REACT_APP_MAPTILER_API_KEY}`;
