import { DraftInlineStyleType, EditorState, Modifier } from 'draft-js';
import _ from 'lodash';

export const clearInlineStylesBlock = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  const lastBlock = currentContent.getLastBlock();

  const selection = editorState.getSelection().merge({
    anchorKey: lastBlock.getKey(),
    anchorOffset: 0,
    focusOffset: lastBlock.getText().length,
    focusKey: lastBlock.getKey(),
  });
  const anchorKey = selection.getAnchorKey();
  // Then based on the docs for SelectionState -
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  // Selected Text
  const selectedText = currentBlock.getText().slice(start, end);

  const contentWithoutStyles = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    selectedText,
    undefined
  );

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    'change-inline-style'
  );
};

export const clearInlineStyles = (
  editorState: EditorState,
  styles: DraftInlineStyleType[]
) => {
  const contentWithoutStyles = _.reduce(
    styles,
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style
      ),
    editorState.getCurrentContent()
  );

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    'change-inline-style'
  );
};
