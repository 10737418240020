import React, { useState } from 'react';

import { User } from 'dashboard/models/User';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSelectedConversationById } from 'store/reducers/conversations';
import { getAvailableUsersFromConversations } from '../../utils/searchInputValidation';

import Contact from './Contact';
import SectionHeader from '../SectionHeader';

interface PeopleSectionProps {
  currentUser: User;
  resetSearchInput: () => void;
  searchInput: string;
}

const PeopleSection: React.FC<PeopleSectionProps> = ({
  currentUser,
  resetSearchInput,
  searchInput,
}) => {
  const { conversations } = useSelector((state: RootState) => ({
    conversations: state.conversations.conversationList,
  }));

  const dispatch = useDispatch();

  const [selectedEmail, setSelectedEmail] = useState('');
  if (!searchInput) {
    return null;
  }

  const availableUsers = getAvailableUsersFromConversations(
    conversations,
    currentUser.email,
    searchInput
  );

  const contactList = availableUsers.length ? (
    availableUsers.map((user, index) => (
      <Contact
        user={user}
        index={index}
        isSelected={user.email === selectedEmail}
        onClick={async () => {
          dispatch(setSelectedConversationById(user.conversationId));
          resetSearchInput();
        }}
        onMouseEnter={() => setSelectedEmail(user.email)}
        onMouseLeave={() => setSelectedEmail('')}
      />
    ))
  ) : (
    <div style={styles.noResultText}>No Results</div>
  );

  return (
    <div style={styles.mainContainer}>
      <SectionHeader title="People" />
      {contactList}
    </div>
  );
};

const styles = {
  mainContainer: {},
  noResultText: {
    color: '#9b9b9b',
    fontSize: 16,
    fontWeight: 500,
    padding: '0 8px 0 8px',
  },
};

export default PeopleSection;
