import * as React from 'react';

import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { onDeleteConversation } from 'store/thunks/conversations/onDeleteConversation';
import { useDispatch } from 'react-redux';
import {
  setMobileView,
  MobileViewOptions,
  setSidebarView,
  SidebarViewOptions,
} from 'store/reducers/application';

interface DeleteConversationMenuProps {
  conversationId: string;
  onClick?: () => void;
}

const ConversationMenu: React.FC<DeleteConversationMenuProps> = ({
  conversationId,
  onClick,
}) => {
  const dispatch = useDispatch();
  return (
    <Menu placement="left" onOpen={onClick}>
      <MenuButton color="black">
        <IoEllipsisVerticalSharp size={22} color="black" />
      </MenuButton>
      <MenuList position="absolute" top={-6} right={2}>
        <MenuItem
          onClick={() => {
            dispatch(setMobileView(MobileViewOptions.CONVERSATION_SETTINGS));
            dispatch(setSidebarView(SidebarViewOptions.CONVERSATION_SETTINGS));
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => dispatch(onDeleteConversation({ conversationId }))}
          style={{ color: 'red' }}
        >
          Delete Conversation
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
export default ConversationMenu;
