import * as React from 'react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import instant from 'assets/svg/instant-screen-name.svg';

const NameScreenState: React.FC = () => {
  return (
    <div style={styles.mainContainer}>
      <div style={styles.subContainer}>
        <div style={styles.titleContainer}>
          <img style={styles.icon} src={instant} alt="message-icon" />

          <div style={styles.title}>Let Folks Know Your Nom de Plume</div>
        </div>
        <div style={styles.body}>
          Please enter a name to represent yourself in chat and press ENTER.
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    zIndex: 0,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  subContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  title: {
    color: '#000000',
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  icon: {
    height: 80,
    width: 80,
    marginBottom: 25,
  },
  body: {
    color: '#000000',
    fontSize: '18px',
    textAlign: 'center',
    width: '100%',
  },
});

export default NameScreenState;
