import { Icon, IconButton } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons/lib';

type InlineOrBlockStyleFn = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  type: string
) => void;

type EntityStyleFn = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  type: string,
  url: string
) => void;

interface ToolbarIconProps {
  type: string;
  toolTip: string;
  isActive: boolean;
  url?: string;
  disabled?: boolean;
  hasFocus?: boolean;
  icon: IconType;
  onMouseDown: InlineOrBlockStyleFn | EntityStyleFn;
}

const ToolbarIcon: React.FC<ToolbarIconProps> = ({
  type,
  toolTip,
  isActive,
  url,
  disabled,
  hasFocus,
  icon,
  onMouseDown,
}) => {
  return (
    <IconButton
      size="sm"
      aria-label={toolTip}
      key={type}
      title={toolTip}
      onMouseDown={(event) => onMouseDown(event, type, url as string)}
      onClick={(event) => event.preventDefault()}
      colorScheme="transparent"
      disabled={disabled}
      cursor={hasFocus ? 'pointer' : 'text'}
    >
      <Icon as={icon} fill={isActive ? '#0082ff' : '#9b9b9b'} />
    </IconButton>
  );
};

export default ToolbarIcon;
