import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationController, {
  isValidSession,
} from 'auth/AuthenticationController';
import MagicLinkQueryStoreContext from 'auth/MagicLinkQueryStoreContext';
import routes from 'routes';
import { useDispatch } from 'react-redux';
import { setSelectedIncomingConversation } from 'store/reducers/conversations';

export enum Status {
  Initial,
  Checking,
  Complete,
  Invalid,
}

export function useLoginWithLink(type?: string) {
  const magicLinkState = useContext(MagicLinkQueryStoreContext);
  const location = useLocation();
  const history = useHistory();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));
  const [status, setStatus] = useState<Status>(Status.Initial);
  const dispatch = useDispatch();

  useEffect(() => {
    let token = qs.current.get('access_token') as string;
    const convoId = qs.current.get('conversationId');

    if (convoId) {
      dispatch(setSelectedIncomingConversation(convoId));
    }

    const run = async () => {
      if (status !== Status.Initial) {
        return;
      }

      if (!token) {
        return setStatus(Status.Invalid);
      }

      setStatus(Status.Checking);

      try {
        if (type === 'sms') {
          token =
            await AuthenticationController.exchangeForDashboardAccessToken(
              token
            );

          const hasAccessToDashboard = await isValidSession();

          if (!hasAccessToDashboard) {
            return setStatus(Status.Invalid);
          }
        } else {
          await AuthenticationController.loginViaMagicLink(token);
        }

        setStatus(Status.Complete);
        // Go back to where the user originally wanted to go and then clear the state
        setTimeout(() => {
          const conversationId = qs.current.get('conversationId') ?? '';
          history.push({
            pathname: routes.home,
            search:
              conversationId !== '' ? '?conversationId=' + conversationId : '',
          });
        }, 3000);
      } catch (e) {
        setStatus(Status.Invalid);
      }
    };
    run();
  }, [history, magicLinkState, status, dispatch, type]);
  return {
    hasToken: qs.current.has('access_token'),
    status,
  };
}
