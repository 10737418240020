/* eslint-disable no-redeclare,@typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';

export type ErrorResponse = {
  message: string;
};

export function formatErrorResponse(error: AxiosError): ErrorResponse;
export function formatErrorResponse(error: Error): ErrorResponse;
export function formatErrorResponse(error: any): ErrorResponse {
  // This is an error returned from the backend
  if (error.response?.data?.status) {
    return {
      message: error.response?.data?.status,
    };
  }

  // This is any generic error
  if (error instanceof Error) {
    return {
      message: error.message,
    };
  }

  // This would happen if a developer threw an error that isn't actually an error class. Don't do that...
  console.error(
    'Caught an error that is not an instance of an error! Replacing with a proper error but please fix this.',
    error
  );

  return {
    message: 'An unknown error has occurred',
  };
}
