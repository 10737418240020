import { useCallback, useRef, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useToast } from '@chakra-ui/react';
import { formatErrorResponse } from 'shared/utils/errors';
import { AuthenticationController } from 'auth/AuthenticationController';
import { useLocation } from 'react-router-dom';

export type TokenData = {
  // Workspace name
  aud: string;

  // Expiry date in seconds epoch
  exp: number;

  // Issuer: "dashboard"
  iss: string;

  // Users email address
  sub: string;

  // Signature
  jti: string;
};

export enum ResendMagicLinkStatus {
  Initial,
  Sending,
  Sent,
  Error,
}
export function useResendEmail(showToast = true) {
  const location = useLocation<{ email?: string }>();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));

  const [status, setStatus] = useState(ResendMagicLinkStatus.Initial);
  const [error, setError] = useState<Error | undefined>(undefined);
  const toast = useToast();

  let email = location.state?.email;
  try {
    const jwtPayload = jwtDecode<TokenData>(
      qs.current.get('access_token') ?? ''
    );
    email = jwtPayload.sub;
  } catch {
    // Token is missing or invalid.
  }

  const handleResend = useCallback(async () => {
    if (email) {
      setStatus(ResendMagicLinkStatus.Sending);
      try {
        const body = {
          email,
          subject: 'Magic Link for Secure Chat',
        };
        await AuthenticationController.requestLoginLink(body);
        setStatus(ResendMagicLinkStatus.Sent);
        if (showToast) {
          toast({
            title: 'Your account access email has been resent',
            description: 'Check your mailbox for an email from us',
            status: 'success',
            position: 'bottom',
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
          setStatus(ResendMagicLinkStatus.Error);
          if (showToast) {
            toast({
              title: 'Could not resend email',
              description: formatErrorResponse(e).message,
              status: 'error',
              position: 'bottom',
            });
          }
        }
      }
    }
  }, [email, showToast, setStatus, setError, toast]);
  return {
    email,
    handleResend,
    status,
    error,
  };
}
