import React from 'react';

import { Flex } from '@chakra-ui/react';

import { MobileViewOptions } from 'store/reducers/application';
import { User } from 'dashboard/models/User';
import { ProcessedConversation } from 'dashboard/models/Conversation';

import HomeHeader from './home';
import ComposeHeader from './compose';
import ThreadHeader from './thread';

interface MobileHeaderProps {
  currentViewMobile: MobileViewOptions;
  currentUser: User;
  onActivateProfileMode: (user: User) => void;
  onActivateLocatorTokenMode: () => void;
  onNavigateBack: () => void;
  selectedConversation: ProcessedConversation;
}

/**
 * A component which renders the Dashboard Mobile Header
 */
const MobileHeader: React.FC<MobileHeaderProps> = ({
  currentViewMobile,
  currentUser,
  onActivateProfileMode,
  onActivateLocatorTokenMode,
  onNavigateBack,
  selectedConversation,
}) => {
  const renderHeaderContent = () => {
    switch (currentViewMobile) {
      case MobileViewOptions.CONVERSATION_LIST:
        return (
          <HomeHeader
            currentUser={currentUser}
            onActivateProfileMode={onActivateProfileMode}
          />
        );
      case MobileViewOptions.COMPOSE:
        return <ComposeHeader onNavigateBack={onNavigateBack} />;
      case MobileViewOptions.THREAD:
        return (
          <ThreadHeader
            currentUser={currentUser}
            onActivateProfileMode={onActivateProfileMode}
            onActivateLocatorTokenMode={onActivateLocatorTokenMode}
            onNavigateBack={onNavigateBack}
            selectedConversation={selectedConversation}
            selectedConversationId={selectedConversation.id}
          />
        );
    }
  };

  if (
    currentViewMobile === MobileViewOptions.SECURITY ||
    currentViewMobile === MobileViewOptions.PROFILE ||
    currentViewMobile === MobileViewOptions.CONVERSATION_SETTINGS
  ) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="5em"
      position="fixed"
      top={0}
      width="100%"
      backgroundColor="white"
      zIndex={1000}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={15}
        mb={5}
        flex={1}
        px="10px"
      >
        {renderHeaderContent()}
      </Flex>
    </Flex>
  );
};

export default MobileHeader;
