import React, { useState, useRef, useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import ToggleButton from 'shared/components/ToggleButton';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { FormProvider } from 'react-hook-form';
import { StackedInputField } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import PhoneInputField from 'shared/components/PhoneInputField';
import { Workspace, WorkspaceOptions } from 'dashboard/models/Contact';
import { User } from 'dashboard/models/User';
import useInviteNewUserForm from './useInviteNewUserForm';
import Recipient from './components/Recipient';
import 'react-intl-tel-input/dist/main.css';
import Dropdown from './components/Dropdown';
import useHandleKeystrokes from '../utils/useHandleKeystrokes';

export enum Toggle {
  email,
  phone,
}

export interface CursorIndex {
  workspaceIndex: number;
  optionsIndex: number;
}

export default function InviteNewUserForm({
  isMobile,
  builtWorkspaces,
  currentUser,
}: {
  isMobile: boolean;
  builtWorkspaces: Workspace;
  currentUser: User;
}) {
  const { onSubmit, form, error } = useInviteNewUserForm();
  // Cursor Index for handling up and down arrow keys in dropdown
  const [cursorIndex, setCursorIndex] = useState<CursorIndex>({
    workspaceIndex: 0,
    optionsIndex: 0,
  });
  const [inputFocussed, setInputFocussed] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [showInputValidationWarning, setShowInputValidationWarning] =
    useState(false);
  const [toggle, setToggle] = useState(Toggle.email);
  const dropdownRef = useRef<HTMLDivElement>();

  // clone of builtWorkspaces for removing and readding recipients
  const [builtWorkspacesClone, setBuiltWorkspacesClone] =
    useState(builtWorkspaces);

  // clone of builtWorkspaces for search filtering
  const [searchableBuiltWorkspacesClone, setSearchableBuiltWorkspacesClone] =
    useState(builtWorkspacesClone);

  const inputRef = useRef<HTMLDivElement>(null);

  // number of total workspaces
  const numberOfWorkspaces = useMemo(
    () =>
      (searchableBuiltWorkspacesClone &&
        Object.keys(searchableBuiltWorkspacesClone).length) ||
      0,
    [searchableBuiltWorkspacesClone]
  );

  // number of users in the previously highlighted workspace (used for handling Arrow up events)
  const numberOfUsersInPreviousWorkspace = useMemo(() => {
    if (cursorIndex.workspaceIndex > 0) {
      return Object.values(searchableBuiltWorkspacesClone)[
        cursorIndex.workspaceIndex - 1
      ].options.length;
    }
  }, [searchableBuiltWorkspacesClone, cursorIndex]);

  // number of users in the currently highlighted workspace
  const numberOfUsersInWorkspace = useMemo(() => {
    return (
      Object.values(searchableBuiltWorkspacesClone)[cursorIndex.workspaceIndex]
        ?.options?.length || 0
    );
  }, [searchableBuiltWorkspacesClone, cursorIndex]);

  // current values for each of the 3 form elements
  const currentEmailValue = form.watch('email');
  const currentPhoneValue = form.watch('phone');
  const currentRecipientsValue = form.watch('recipients');

  // If user clicks outside of the dropdown or input field, close the dropdown
  useOutsideClick({
    ref: inputRef,
    handler: () => setInputFocussed(false),
  });

  // Toggles UI and logic between invite methods
  const handleToggle = (val: boolean) => {
    if (val) {
      setToggle(Toggle.phone);
    } else {
      setToggle(Toggle.email);
    }
    setSearchableBuiltWorkspacesClone(builtWorkspacesClone);
    return form.reset({ ...form.getValues(), email: '', phone: '' });
  };

  // prevent unique recipients from being added to conversation
  const isCreatable = (): boolean => {
    let result = true;
    const parsedCurrentRecipientsValue: WorkspaceOptions[] =
      currentRecipientsValue && JSON.parse(currentRecipientsValue);

    // if there are no recipients, then we can skip the check
    if (parsedCurrentRecipientsValue.length <= 0) {
      return true;
    }

    // does recipient state contain current input value
    const hasOccurrence = (currentValue: string) =>
      parsedCurrentRecipientsValue.some(
        (recipient) => recipient.value === currentValue
      );

    // If available workspaces is zero and the input is empty display 'No Options'
    const hasOptions = (currentValue: string) => {
      if (
        Object.keys(searchableBuiltWorkspacesClone).length <= 0 &&
        currentValue.length <= 0
      ) {
        return false;
      }
      return true;
    };

    if (toggle === Toggle.email) {
      result = !hasOccurrence(currentEmailValue);
      result = hasOptions(currentEmailValue);
    }
    if (toggle === Toggle.phone) {
      result = !hasOccurrence(currentPhoneValue);
      result = hasOptions(currentPhoneValue);
    }

    return result;
  };

  // Adds a recipient to the form data and then resets all other values
  const addRecipient = (currentFieldValue: string, bid: number) => {
    if (
      currentFieldValue === currentUser.email ||
      currentFieldValue === currentUser.phone
    ) {
      return form.reset({ ...form.getValues(), email: '', phone: '' });
    }

    const parsedValue = JSON.parse(currentRecipientsValue || '[]');
    form.setValue(
      'recipients',

      // react hook form values must be in a string format because of the way yup schema is setup
      JSON.stringify([...parsedValue, { bid, value: currentFieldValue }])
    );
    // reset input field after recipient has been added to state
    form.reset({ ...form.getValues(), email: '', phone: '' });

    // filter recipient from workspace (builtWorkspacesClone)
    const filterCondition = (option: WorkspaceOptions) => {
      if (option.bid === bid) {
        return option.value !== currentFieldValue.toLowerCase();
      }
      return true;
    };

    const results = filterWorkspace(filterCondition);

    // unfocus input field when a recipient has been added to the list and the dropdown has been closed
    if (inputRef && inputRef.current) {
      (inputRef.current.children[0].lastChild?.lastChild as HTMLElement).blur();
    }

    setSearchableBuiltWorkspacesClone(results);
    setBuiltWorkspacesClone(results);
    setInputFocussed(false);
  };

  // Removes a pill and adds the recipient back into the workspace hash
  const handleRemoveRecipient = (
    index: number,
    recipient: WorkspaceOptions
  ) => {
    let results;
    let label;
    // if business ID isn't in the cloned workspace, add label to entry
    if (recipient.bid in builtWorkspacesClone) {
      results = {
        ...builtWorkspacesClone,
        [recipient.bid]: {
          ...builtWorkspacesClone[recipient.bid],
          options: [
            ...builtWorkspacesClone[recipient.bid].options,
            { bid: recipient.bid, value: recipient.value },
          ],
        },
      };
    } else {
      // look up label in case entry has been filtered out of workspace
      if (builtWorkspaces[recipient.bid]) {
        label = builtWorkspaces[recipient.bid].label;
      } else {
        // builtWorkspaces[recipient.bid] of workspace bid:0 could be undefined for current users on the platform. set their label to 'recent users'
        label = 'Recent users';
      }

      results = {
        ...builtWorkspacesClone,
        [recipient.bid]: {
          label,
          options: [{ bid: recipient.bid, value: recipient.value }],
        },
      };

      // If they were created, we don't add them to the dropdown list
      if (!recipient.bid) {
        results = builtWorkspaces;
      }
    }

    // Readd removed recipients back into workspace clone
    setSearchableBuiltWorkspacesClone(results);
    setBuiltWorkspacesClone(results);

    const array = [...JSON.parse(currentRecipientsValue)];

    // remove recipient from recipients array
    if (index !== -1) {
      array.splice(index, 1);
      return form.setValue('recipients', JSON.stringify([...array]));
    }
  };

  // Filter workspaces based on provided condition
  const filterWorkspace = (
    filterCondition: (arg0: WorkspaceOptions) => void
  ) => {
    let results: Workspace = {};
    Object.values(builtWorkspacesClone).forEach((workspace) => {
      const options = workspace.options.filter((option: WorkspaceOptions) => {
        return filterCondition(option);
      });

      // If workspace doesn't contain value don't include it in the results hash
      if (options.length > 0) {
        // Rebuild workspace shape
        results = {
          ...results,
          [workspace.options[0].bid]: {
            label: workspace.label,
            options,
          },
        };
      }
    });

    // reset cursor index no initial state when user is filtering
    setCursorIndex({ workspaceIndex: 0, optionsIndex: 0 });

    return results;
  };

  // Handles selecting a workspace in the dropdown
  const handleDropdownClick = (value: string, id: number) => {
    addRecipient(value, id);
    setInputFocussed(false);
  };

  useHandleKeystrokes({
    addRecipient,
    builtWorkspacesClone,
    currentEmailValue,
    currentPhoneValue,
    currentRecipientsValue,
    filterWorkspace,
    inputFocussed,
    isCreatable,
    isValidPhoneNumber,
    setInputFocussed,
    setSearchableBuiltWorkspacesClone,
    searchableBuiltWorkspacesClone,
    setShowInputValidationWarning,
    showInputValidationWarning,
    toggle,
    setCursorIndex,
    cursorIndex,
    numberOfWorkspaces,
    numberOfUsersInWorkspace,
    dropdownRef,
    numberOfUsersInPreviousWorkspace,
    inputRef,
  });

  return (
    <>
      <FormProvider {...form}>
        <Box as="form" onSubmit={onSubmit}>
          <Text style={styles.body}>
            Enter {toggle === Toggle.email ? 'an email' : 'a phone number'} to
            send an invite and start a conversation. Perfect for chatting with
            coworkers.
          </Text>
          <Box
            h="187px"
            maxW="444px"
            w="100%"
            borderRadius=" 6px"
            pl="14px"
            pr="14px"
            pt="19px"
            background="rgba(247, 247, 247, 0.4)"
          >
            <Box pb="21px">
              <ToggleButton handleToggle={handleToggle} isMobile={isMobile} />
            </Box>
            <StackedInputField name="recipients" type="hidden" />
            <Box position="relative" ref={inputRef}>
              {toggle === Toggle.email && (
                <StackedInputField
                  id="email"
                  name="email"
                  type="search"
                  label="Email Address"
                  placeholder="user@example.com"
                  autoCorrect="off"
                  spellCheck={false}
                  autoComplete="off"
                  onFocus={() => {
                    if (!inputFocussed) {
                      setInputFocussed(true);
                    }
                  }}
                  size={isMobile ? 'md' : 'lg'}
                />
              )}

              {toggle === Toggle.phone && (
                <PhoneInputField
                  name="phone"
                  form={form}
                  responseError={error}
                  handleFocus={() => {
                    if (!inputFocussed) {
                      setInputFocussed(true);
                    }
                  }}
                  isValid={(val: boolean) => setIsValidPhoneNumber(val)}
                />
              )}

              {inputFocussed && !showInputValidationWarning && (
                <Dropdown
                  handleClick={handleDropdownClick}
                  builtWorkspaces={searchableBuiltWorkspacesClone}
                  toggle={toggle}
                  currentEmailValue={currentEmailValue}
                  currentPhoneValue={currentPhoneValue}
                  isCreatable={isCreatable}
                  isMobile={isMobile}
                  cursorIndex={cursorIndex}
                  dropdownRef={dropdownRef}
                />
              )}
              {showInputValidationWarning && (
                <Flex alignSelf="flex-start" width="fit-content" mt="5">
                  <Alert status="error" borderRadius="5px" zIndex="1">
                    <AlertIcon />
                    <AlertDescription>
                      Please enter a valid{' '}
                      {toggle === Toggle.email ? 'email' : 'phone number'}
                    </AlertDescription>
                  </Alert>
                </Flex>
              )}
            </Box>
          </Box>
          {currentRecipientsValue?.length > 2 && (
            <Box
              minH="47px"
              maxW="444px"
              w="100%"
              mt="16px"
              background="#FCFCFC"
              border="2px solid #F9F9F9"
              borderRadius=" 6px"
              pl="14px"
              pt="11px"
              pr="14px"
              pb="5px"
            >
              {JSON.parse(currentRecipientsValue).map(
                (item: WorkspaceOptions, i: number) => (
                  <Recipient
                    recipient={item}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.value}+${i}`}
                    handleClick={handleRemoveRecipient}
                    index={i}
                  />
                )
              )}
            </Box>
          )}
          <Flex maxWidth="444px" width="100%" justifyContent="flex-end">
            <SpinnerButton
              disabled={currentRecipientsValue.length <= 2 || inputFocussed}
              bgColor={
                currentRecipientsValue.length <= 2
                  ? '#d8d8d8'
                  : 'hsla(216, 92%, 59%, 0.85)'
              }
              role="button"
              mt="16px"
              mb="5"
              borderRadius="20px"
              w="225px"
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              Start chat
            </SpinnerButton>
          </Flex>
        </Box>
      </FormProvider>
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    paddingBottom: '30px',
    color: '#111D4C',
  },
});
