import { useEffect } from 'react';
import { findOrAddWorkspaces } from 'dashboard/services/workspaces';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { onFetchTeam } from 'store/thunks/conversations/onFetchTeam';

const useFindOrAddWorkspaces = () => {
  const appDispatch = useAppDispatch();

  const {
    user: { currentUser },
  } = useSelector((state: RootState) => ({
    user: state.user,
  }));

  useEffect(() => {
    const handleFetchTeam = async () => {
      const savedWorkspacesList = await appDispatch(onFetchTeam(null));
      if (currentUser.id) {
        await findOrAddWorkspaces(
          unwrapResult(savedWorkspacesList),
          currentUser.id
        );
      }
    };
    handleFetchTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFindOrAddWorkspaces;
