import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import routes from './routes';
import AuthenticationController from './AuthenticationController';
import RestrictedRoute, { RestrictedRouteProps } from './RestrictedRoute';

export type PostLoginReturnState = {
  returnTo?: string;
};

const ProtectedRoute: React.FC<RestrictedRouteProps> = ({
  // Default this prop to true, overriding the default on RestrictedRoute.
  // Any logged in user will have access to this route by default.
  canAccess = true,
  ...props
}) => {
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
  }));

  const history = useHistory();
  const location = useLocation();

  const redirectToLogin = useCallback(
    (rememberLocation: boolean) => {
      const state: PostLoginReturnState = {
        // Return to this path after successfully logging in. If the user manually logged out, we don't want them
        // to return to the old location incase it is a new user the is logging in after them.
        returnTo: rememberLocation
          ? location.pathname + location.search
          : undefined,
      };

      history.replace(routes.login, state);
    },
    [history, location.pathname, location.search]
  );

  const { loading, isLoggingOut } = AuthenticationController;

  useEffect(() => {
    const isUserSet = !!currentUser.email;

    if (!isUserSet && !loading) {
      redirectToLogin(!isLoggingOut);
    }
  }, [redirectToLogin, loading, isLoggingOut, currentUser]);

  // Delay rendering the content until we are sure that the user is authenticated.
  // This avoids flashing the sensitive content and then redirecting them away
  return <RestrictedRoute canAccess={canAccess} {...props} />;
};

export default ProtectedRoute;
