import { maxBy, minBy } from 'lodash';
import * as turf from '@turf/turf';
import { Marker } from './types';

export const getOriginMarkerSize = (count: number) => {
  switch (true) {
    case count <= 3:
      return 40;
    case count > 3 && count < 8:
      return 60;
    case count >= 8:
      return 80;
    default:
      return 40;
  }
};

export const getColorFromThreat = (threat: number) => {
  switch (threat) {
    case 1:
      return '#64AC6F';
    case 2:
      return '#DF8D48';
    case 3:
      return '#D34B46';
    default:
      return '#64AC6F';
  }
};

export const getBBox = (markers: number[][]) => {
  const line = turf.lineString(
    markers.length === 1 ? [...markers, ...markers] : [...markers]
  );
  const bbox = turf.bbox(line);
  return turf.bboxPolygon(bbox);
};

const getMinOrMax = (
  markers: Marker[],
  minOrMax: 'max' | 'min',
  latOrLng: 'latitude' | 'longitude'
) => {
  if (minOrMax === 'max') {
    const _maxBy = maxBy(markers, ({ latitude, longitude }) =>
      latOrLng === 'longitude' ? longitude : latitude
    );

    return _maxBy
      ? _maxBy[latOrLng === 'longitude' ? 'longitude' : 'latitude']
      : null;
  }

  const _minBy = minBy(markers, ({ latitude, longitude }) =>
    latOrLng === 'longitude' ? longitude : latitude
  );

  return _minBy
    ? _minBy[latOrLng === 'longitude' ? 'longitude' : 'latitude']
    : null;
};

export const getBounds = (markers: Marker[]) => {
  const maxLat = getMinOrMax(markers, 'max', 'latitude');
  const minLat = getMinOrMax(markers, 'min', 'latitude');
  const maxLng = getMinOrMax(markers, 'max', 'longitude');
  const minLng = getMinOrMax(markers, 'min', 'longitude');

  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};
