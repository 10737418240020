import React from 'react';
import LoadingBox from 'shared/components/LoadingBox';
import CenterLayout from './CenterLayout';
import CodeError from './CodeError';

interface CodeSuccessProps {
  invalid?: boolean;
}
const CodeSuccess: React.FC<CodeSuccessProps> = ({ invalid }) => {
  if (invalid) {
    return <CodeError />;
  }
  return (
    <CenterLayout>
      <LoadingBox text="Logging In" />
    </CenterLayout>
  );
};

export default CodeSuccess;
