import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { StackedInputField } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import { GoogleButton } from 'shared/components/sso';
import colors from 'theme/foundations/colors';
import useEmailLoginForm from './useEmailLoginForm';

export default function EmailLogin({
  requestGoogleLogin,
  isMobile,
}: {
  requestGoogleLogin: () => void;
  isMobile: boolean;
}) {
  const { onSubmit, form } = useEmailLoginForm();
  return (
    <FormProvider {...form}>
      <Box as="form" onSubmit={onSubmit}>
        <StackedInputField
          id="email"
          name="email"
          type="email"
          label="Email Address"
          size={isMobile ? 'md' : 'lg'}
        />
        <SpinnerButton
          type="submit"
          size={isMobile ? 'md' : 'lg'}
          isLoading={form.formState.isSubmitting}
          role="button"
          backgroundColor="hsla(216, 92%, 59%, 0.85)" // TODO: for guest login
          mt={isMobile ? 4 : 8}
          w="100%"
        >
          Send Magic link
        </SpinnerButton>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mt="20px"
          mb="20px"
        >
          <Divider
            color={colors.gray[200]}
            maxWidth="194px"
            width="100%"
            mr="9px"
          />
          <Text style={styles.or} textAlign="center">
            or
          </Text>
          <Divider
            color={colors.gray[200]}
            maxWidth="194px"
            width="100%"
            ml="9px"
          />
        </Flex>
        <GoogleButton onClick={requestGoogleLogin} isMobile={isMobile} />
        {/* This empty div is intentional. Otherwise, when toggling between login options the boxshadow on the GoogleButton remains after the component has unmounted  */}
        <div />
      </Box>
    </FormProvider>
  );
}
const styles = StyleSheet.create({
  or: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray[200],
  },
});
