import { ProcessedConversation } from 'dashboard/models/Conversation';
import {
  EncryptedMessageDocument,
  ProcessedMessage,
} from 'dashboard/models/Message';

import { MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { setShouldLoadMoreMessages } from 'store/reducers/conversations';
import {
  removeMessageLoader,
  setProcessedMessages,
} from 'store/reducers/messages';

import messageListener from '../utils/messageListener';

interface useMessageSubscriberProps {
  selectedConversation: ProcessedConversation;
  messageListRef: MutableRefObject<HTMLDivElement>;
  shouldLoadMoreMessages: boolean;
  messageLoaders: EncryptedMessageDocument[];
  processedMessages: ProcessedMessage[];
  currentMessageThreadLimit: number;
  dispatch: Dispatch;
  messageListViewportHeight: number;
}

const useMessageSubscriber = ({
  selectedConversation,
  messageListRef,
  processedMessages,
  messageLoaders,
  currentMessageThreadLimit,
  shouldLoadMoreMessages,
  dispatch,
  messageListViewportHeight,
}: useMessageSubscriberProps) => {
  const {
    application: { currentView },
    user: { accessToken, currentUser },
  } = useSelector((state: RootState) => ({
    application: state.application,
    user: state.user,
  }));

  useEffect(() => {
    const handleShouldLoadMoreMessages = (val: boolean) =>
      dispatch(setShouldLoadMoreMessages(val));
    const handleRemoveMessageLoaders = (val: string[]) =>
      dispatch(removeMessageLoader(val));
    const handleSetProcessedMessages = (val: ProcessedMessage[]) =>
      dispatch(setProcessedMessages(val));

    const selectedConversationId = selectedConversation.id;

    if (selectedConversation && selectedConversationId) {
      const unsubscribeMessageListener = messageListener({
        shouldLoadMoreMessages,
        handleRemoveMessageLoaders,
        handleSetProcessedMessages,
        handleShouldLoadMoreMessages,
        selectedConversation,
        currentUser,
        currentMessageThreadLimit,
        processedMessages,
        messageListRef,
        messageLoaders,
        dispatch,
      });
      return () => {
        unsubscribeMessageListener();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    selectedConversation,
    currentUser,
    currentMessageThreadLimit,
    currentView.mobile,
    messageListViewportHeight,
    messageLoaders,
    processedMessages,
    messageListRef,
    setShouldLoadMoreMessages,
    shouldLoadMoreMessages,
  ]);
  return null;
};

export default useMessageSubscriber;
