import React, { useState } from 'react';
import { Heading, Text, ScaleFade, useToast, Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { onCreateConversation } from 'store/thunks/conversations/onCreateConversation';
import { onSelectConversation } from 'store/thunks/conversations/onSelectConversation';
import SpinnerButton from 'shared/components/SpinnerButton';
import { generateGuestInvitationMessage } from 'dashboard/services/chat/generateGuestInviteMessage';
import { copyTextToClipboard } from 'dashboard/utils/copyTextToClipboard';

import { User } from 'dashboard/models/User';
import InviteGuestModal from './InviteGuestModal';

import './InviteGuest.scss';

interface InviteGuestProps {
  currentUser: User;
}

const InviteGuest: React.FC<InviteGuestProps> = ({ currentUser }) => {
  const toast = useToast();
  const [isCopying, setCopying] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [newConversationId, setNewConversationId] = useState('');

  const dispatch = useDispatch();

  const onGenerateGuestConversation = async () =>
    dispatch(
      onCreateConversation({
        recipientEmailList: [currentUser.email],
        workspaceId: '0',
        currentUser,
        includeWhiteListedGuestRecipient: true,
      })
    );

  const onCopyInviteLink = async () => {
    setCopying(true);

    const message = generateGuestInvitationMessage(inviteLink);

    copyTextToClipboard(message);

    toast({
      position: 'top-right',
      render: () => (
        <Box
          color="white"
          p={3}
          bg="#111D4C"
          height="64px"
          maxW="395"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Paste the invite to send the link by text or email.
        </Box>
      ),
    });

    setCopying(false);
  };

  const onOpen = async () => {
    const { payload } = (await onGenerateGuestConversation()) as unknown as {
      payload: {
        link: string;
        newConversationId: string;
      };
    };

    setInviteLink(payload.link);
    setNewConversationId(payload.newConversationId);
    setModalVisible(true);
  };

  const onCloseModal = () => {
    setModalVisible(false);
    setNewConversationId('');
  };

  return (
    <>
      <InviteGuestModal
        isOpen={isModalVisible}
        onClose={onCloseModal}
        inviteLink={inviteLink}
        isCopying={isCopying}
        onCopyInviteLink={onCopyInviteLink}
        onNavigateToChat={() =>
          dispatch(onSelectConversation({ conversationId: newConversationId }))
        }
      />
      <ScaleFade in id="invite-guest-container">
        <Heading id="invite-guest-heading">Invite as Guest</Heading>
        <Text id="invite-guest-body">
          Click the button below to invite a guest user by QR code or link.
        </Text>
        <SpinnerButton
          type="button"
          onClick={onOpen}
          role="button"
          id="invite-guest-start-button"
        >
          Start a Guest Chat
        </SpinnerButton>
      </ScaleFade>
    </>
  );
};

export default InviteGuest;
