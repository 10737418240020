import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationController from 'auth/AuthenticationController';
import routes from 'routes';
import { UseFormMethods } from 'react-hook-form';
import { Status } from '../types';

export type GuestLoginFormProps = {
  status: Status;
  setStatus: (status: Status) => void;
  form: UseFormMethods;
};

export default function useGuestLoginForm({
  status,
  setStatus,
  form,
}: GuestLoginFormProps) {
  const submitForm = useCallback(
    async ({ alias }: { alias: string }) => {
      setStatus(Status.Checking);
      try {
        await AuthenticationController.loginGuest({
          alias,
          // important: any values that are saved in firestore must be lowercased
          email: `guest-${Date.now()}`,
          setStatus,
        });
      } catch (e) {
        setStatus(Status.Invalid);
      }
    },
    [setStatus]
  );

  const history = useHistory();

  if (status === Status.Invalid) {
    form.setError('alias', {
      type: 'manual',
      message: 'Alias has already been taken',
    });
    setStatus(Status.Initial);
  }

  if (status === Status.Complete) {
    history.push({ pathname: routes.home });
  }

  return {
    submitForm,
  };
}
