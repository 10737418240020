import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Same as useEffect but does a deep comparison
 */
export default function useDeepEffect(
  func: EffectCallback,
  dependencies: DependencyList
) {
  const dependenciesRef = useRef(dependencies);

  useEffect(() => {
    if (!isEqual(dependenciesRef.current, dependencies)) {
      // Execute the function
      func();

      // Update the dependencies
      dependenciesRef.current = dependencies;
    }
  }, [func, dependencies]);
}
