import {
  ConversationDocument,
  ProcessedConversation,
} from 'dashboard/models/Conversation';
import { add, isAfter } from 'date-fns';

/**
 * A utility function used in a filter operation that checks if the conversation has been expired >= 1 day.
 * If the current time has moved beyond the conversation's `expiresAt` value, the conversation is disabled from user access.
 *
 * @param {Map} conversation - the conversation object for either a one-to-one or multi-user conversation
 * @returns {Boolean} whether the conversation meets the filter requirements
 */
export const isConversationDisabled = (
  conversation: ConversationDocument | ProcessedConversation
) => {
  const conversationExpiresAtTime: Date = conversation.expiresAt.toDate();

  const currentTime = new Date();

  if (isAfter(currentTime, conversationExpiresAtTime)) {
    return true;
  }

  return false;
};

/**
 * A utility function used in a filter operation that checks if the conversation should be deleted.
 * If the current time has moved beyond the conversation's `expiresAt` value plus 24 hours, the conversation is deleted.
 *
 * @param {Map} conversation - the conversation object for either a one-to-one or multi-user conversation
 * @returns {Boolean} whether the conversation meets the deletion requirements
 */
export const isReadyToDelete = (conversation: ConversationDocument) => {
  const conversationExpiresAtTime: Date = conversation.expiresAt.toDate();
  const currentTime = new Date();

  const expirationAtPlusOneDay = add(conversationExpiresAtTime, {
    hours: 24,
  });

  if (isAfter(currentTime, expirationAtPlusOneDay)) {
    return true;
  }

  return false;
};
