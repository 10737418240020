import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationController from 'auth/AuthenticationController';
import { useDispatch } from 'react-redux';
import { Status } from '../types';

export function useLoginWithInvitedGuestLink() {
  const location = useLocation();
  const history = useHistory();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));
  const [status, setStatus] = useState<Status>(Status.Initial);
  const dispatch = useDispatch();
  const conversationId = qs.current.get('conversationId') ?? '';

  useEffect(() => {
    const run = async () => {
      if (status !== Status.Initial) {
        return;
      }

      setStatus(Status.Checking);
      try {
        await AuthenticationController.verifyGuestAccount(
          conversationId,
          setStatus
        );
      } catch {
        setStatus(Status.Invalid);
      }
    };
    run();
  }, [history, status, dispatch, conversationId]);
  return {
    conversationId,
    status,
  };
}
