import { MessageDocument, MessageState } from 'dashboard/models/Message';
import { revokeKeyAccess } from '../XQSDK';
import { AvailableCollections, FIREBASE_FIRESTORE } from '../firebase';

/**
 * A function utilized to delete a message via ID of a given `Conversation`
 * @param conversationId - the ID of the conversation
 * @param messageId - the ID of the message
 * @param fileReferenceUrl
 * @returns void
 */
export const deleteMessage = async (
  conversationId: string,
  messageId: string,
  fileReferenceUrl?: string
) => {
  const messageDocumentRef = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  )
    .doc(conversationId)
    .collection(AvailableCollections.MESSAGES)
    .doc(messageId);

  const messageQuerySnapshot = await messageDocumentRef.get();

  const messageDocument = messageQuerySnapshot.data() as MessageDocument;
  const messagelocatorTokenToRevoke = encodeURIComponent(
    messageDocument.locatorToken
  );
  const keysToRevoke = [messagelocatorTokenToRevoke];

  // If there are no keys to process, skip the rest.
  if (keysToRevoke.length === 0) {
    return '';
  }

  if (fileReferenceUrl) {
    window.URL.revokeObjectURL(fileReferenceUrl);
  }

  const resetMessageDataFields = {
    payload: '',
    state: MessageState.DELETED,
  };

  return messageDocumentRef.set({ ...resetMessageDataFields }, { merge: true });
};

/**
 * A function utilized to delete a collection of messages via ID of a given `Conversation`
 * @param conversationId - the ID of the conversation
 * @param messageIds - an array of IDs representing each message to delete
 * @returns void
 */
export const deleteBulkMessages = async (
  conversationId: string,
  messageIds: string[]
) => {
  const batch = FIREBASE_FIRESTORE.batch();

  const resetMessageDataFields = {
    payload: '',
    state: MessageState.DELETED,
  };

  const keysToRevoke: string[] = [];

  await Promise.all(
    messageIds.map(async (messageId) => {
      const messageDocumentRef = FIREBASE_FIRESTORE.collection(
        AvailableCollections.CONVERSATIONS
      )
        .doc(conversationId)
        .collection(AvailableCollections.MESSAGES)
        .doc(messageId);

      const messageQuerySnapshot = await messageDocumentRef.get();
      const messageDocument = messageQuerySnapshot.data() as MessageDocument;

      batch.set(
        messageDocumentRef,
        { ...resetMessageDataFields },
        { merge: true }
      );

      keysToRevoke.push(messageDocument.locatorToken);
    })
  );

  await batch.commit();

  // If there are no keys to process, skip the rest.
  if (keysToRevoke.length === 0) {
    return '';
  }
  // @ts-ignore // sdk accepts string[] and is typed wrong
  return revokeKeyAccess(keysToRevoke);
};
