import React, { useRef, useState } from 'react';

import { User } from 'dashboard/models/User';

import SettingsView, {
  ExpiryOption,
  expiryOptions,
} from 'dashboard/features/Sidebar/components/ConversationSettings/SettingsView';

import BackButton from 'dashboard/components/Backbutton';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';
import { MobileViewOptions, setMobileView } from 'store/reducers/application';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { FileResult } from 'store/reducers/messages';
import { onUpdateConversation } from 'store/thunks/conversations/onUpdateConversation';
import { setFirebaseTimestamp } from 'dashboard/services/firebase';
import { getConversationName } from '../..';

interface ConversationSettingsProps {
  conversation: ProcessedConversation;
  latestMessageId: string | null;
  currentUser: User;
  messageExpiryTime: number;
  avatar: string;
  name: string;
  onNavigateBack: () => void;
  recipients: User[];
}

export const getExpiryOption = (expiryTime: number) =>
  expiryOptions.find(({ value }) => value === expiryTime) as ExpiryOption;

const ConversationSettings: React.FC<ConversationSettingsProps> = ({
  conversation,
  latestMessageId,
  currentUser,
  messageExpiryTime,
  onNavigateBack,
  recipients,
  name,
}) => {
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const isLargerThan980 = useIsLargerThan980();

  const [selectedExpiryOption, setSelectedExpiryOption] =
    useState<ExpiryOption>(getExpiryOption(messageExpiryTime));

  const [conversationName, setConversationName] = useState(name);
  const [conversationAvatar, setConversationAvatar] = useState(
    {} as FileResult
  );

  const openFileDialog = () => {
    inputRef.current?.click();
  };

  const onUpdateConversationAvatar = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const eventTarget = e.target as HTMLInputElement;
    const fileList = eventTarget.files as FileList;
    const targetFile = fileList[0];
    const fileReader = new FileReader();

    const fileReaderListener = (listener: ProgressEvent<FileReader>) => {
      let checkIfShowable = false;
      const listenerTarget = listener.target as FileReader;
      if (
        targetFile.type === 'image/png' ||
        targetFile.type === 'image/jpg' ||
        targetFile.type === 'image/gif' ||
        targetFile.type === 'image/jpeg'
      ) {
        checkIfShowable = true;
      } else {
        checkIfShowable = false;
      }
      const fileResult = {
        name: targetFile.name,
        file: targetFile,
        canView: checkIfShowable,
        preview: listenerTarget.result as string,
      };
      setConversationAvatar(fileResult);

      return dispatch(
        onUpdateConversation({
          conversationId: conversation.id,
          avatar: fileResult,
        })
      );
    };

    fileReader.addEventListener('load', fileReaderListener);
    fileReader.readAsDataURL(targetFile);
    fileReader.removeEventListener('loadend', fileReaderListener);
  };

  const handleOnSelect = async (value: number) => {
    setSelectedExpiryOption(getExpiryOption(value));

    return dispatch(
      onUpdateConversation({
        conversationId: conversation.id,
        messageExpiryTime: {
          value,
          messageId: latestMessageId,
          userId: currentUser.id,
          date: setFirebaseTimestamp(),
        },
      })
    );
  };

  const onBackPress = () => {
    if (isLargerThan980) {
      return onNavigateBack();
    }

    dispatch(setMobileView(MobileViewOptions.THREAD));
  };

  const otherRecipientUsers = recipients.filter(
    (user: { id: string; name: string; email: string }) =>
      user.id !== currentUser.id
  );

  const handleOnUpdateName = () => {
    const trimmedConversationName = conversationName.trim();
    const previousConversationName = getConversationName(otherRecipientUsers);
    if (
      recipients.length > 2 &&
      trimmedConversationName !== previousConversationName &&
      conversation.name !== trimmedConversationName
    ) {
      return dispatch(
        onUpdateConversation({
          conversationId: conversation.id,
          conversationName: {
            value: trimmedConversationName,
            messageId: latestMessageId,
            userId: currentUser.id,
            date: setFirebaseTimestamp(),
          },
        })
      );
    }
  };

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex alignItems="center" width="100%">
        <Box alignSelf="flex-start">
          <BackButton onClick={onBackPress} />
        </Box>
        <Text
          width="100%"
          textAlign="center"
          fontFamily="proxima-nova"
          fontWeight={400}
          fontSize="16px"
          lineHeight="16px"
          letterSpacing="0.02em"
          marginRight="40px"
        >
          Conversation Settings
        </Text>
      </Flex>

      <SettingsView
        onUpdateName={handleOnUpdateName}
        recipients={recipients}
        conversationAvatar={conversationAvatar.preview || conversation.avatar}
        conversationName={conversationName}
        onSetConversationAvatar={openFileDialog}
        onSetConversationName={(e) => setConversationName(e.target.value)}
        expiry={selectedExpiryOption}
        onSelect={handleOnSelect}
      />
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onUpdateConversationAvatar(e)
        }
      />
    </Flex>
  );
};

export default ConversationSettings;
