import * as React from 'react';
import { Checkbox, ScaleFade, Textarea } from '@chakra-ui/react';

import ResizeTextarea from 'react-textarea-autosize';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

interface InputSectionProps {
  allowXQToContact: boolean;
  feedbackInput: string;
  onSetFeedbackInput: (text: string) => void;
  onSetAllowXQToContact: () => void;
}

/**
 * A screen utilized to input feedback comments
 * @param feedbackInput - a string value representing the feedback input
 * @param onSetFeedbackInput - a function utilized to update the feedback input
 */
const InputSection: React.FC<InputSectionProps> = ({
  allowXQToContact,
  feedbackInput,
  onSetFeedbackInput,
  onSetAllowXQToContact,
}) => {
  return (
    <ScaleFade in>
      <div style={styles.inputSectionContainer}>
        <span style={styles.sectionTitle}>
          What did you like? What can we do to improve?
        </span>
        <Textarea
          id="feedback-input"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={ResizeTextarea as any}
          inputMode="text"
          maxRows={3}
          minRows={1}
          onChange={(event) => onSetFeedbackInput(event.target.value)}
          overflowY="auto"
          resize="none"
          style={{
            border: '1px solid #C4C4C4',
            width: '90vw',
            maxWidth: '40em',
            marginTop: 7,
          }}
          value={feedbackInput}
          variant="filled"
        />
        <Checkbox
          aria-label="allow XQ to contact you via email"
          isChecked={allowXQToContact}
          onChange={onSetAllowXQToContact}
          style={{
            ...styles.checkbox,
          }}
        >
          I am open to being contacted by XQ about my Secure Chat feedback
        </Checkbox>
      </div>
    </ScaleFade>
  );
};

const styles = StyleSheet.create({
  inputSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    msOverflowY: 'auto',
  },
  sectionTitle: {
    color: '#202020',
    fontSize: 18,
    textAlign: 'center',
  },
  checkbox: {
    width: '90vw',
    maxWidth: '40em',
    marginTop: 25,
    display: 'flex',
  },
});

export default InputSection;
