import * as React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import instant from 'assets/svg/instant-decrypt.svg';

interface CompleteScreenProps {
  onStartConversation: () => void;
}

/**
 * The final screen of the Feedback process
 * @param onStartConversation - a function which triggers the rendering
 * of the `ComposeConversation` screen
 */
const CompleteScreen: React.FC<CompleteScreenProps> = ({
  onStartConversation,
}) => (
  <Flex flexDirection="column" alignItems="center" h="80vh" w="100%">
    <div style={styles.mainContainer}>
      <div style={styles.title}>We appreciate your feedback.</div>
      <img style={styles.icon} src={instant} alt="" />
      <div style={styles.body}>
        We’ll continue making more of what you want to see! In the meantime,
        we’ll keep your data secure, always.
      </div>
      <Button
        borderRadius="30px"
        border="solid 2px #007aff"
        padding="13px 11.9px 13px 8.6px"
        variant="outline"
        maxW="20rem"
        w="50%"
        h="55px"
        marginTop="1rem"
        onClick={onStartConversation}
      >
        Return to dashboard
      </Button>
    </div>
  </Flex>
);

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    padding: '1rem',
  },
  title: {
    color: '#202020',
    fontSize: 32,
    letterSpacing: '0.03em',
    textAlign: 'center',
  },
  icon: {
    height: '69px',
    objectFit: 'contain' as const,
    width: '80%',
    margin: '1.5rem 0',
  },
  body: {
    color: '#000000',
    fontSize: '18px',
    textAlign: 'center' as const,
    width: '80%',
  },
});

export default CompleteScreen;
