/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  baseConversationDocument,
  ConversationDocument,
  InvitesField,
  isHiddenField,
  NotificationsField,
  setExpiresAtTimestamp,
} from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { findOrCreateUser, setSelectedSettings } from 'dashboard/services/auth';
import {
  checkForExistingConversationWithRecipients,
  setExistingConversation,
} from 'dashboard/services/chat';
import {
  AvailableCollections,
  createDocumentId,
  createFirestoreDocument,
  setFirebaseTimestamp,
} from 'dashboard/services/firebase';

import { onSelectConversation } from './onSelectConversation';

const createWhiteListedGuestRecipientId = (conversationId: string) => {
  return `guest-${conversationId.toLowerCase()}`;
};

/**
 * A thunk utilized to create a `Conversation` document.
 * @param recipientEmailList - string[]
 * @param workspaceId - string
 * @param currentUser - User
 * @param copyLink - boolean
 */
export const onCreateConversation = createAsyncThunk<
  { link: string; newConversationId: string } | void,
  {
    recipientEmailList: string[];
    workspaceId: string;
    currentUser: User;
    includeWhiteListedGuestRecipient?: boolean;
  }
>(
  'conversations/create',
  async (
    {
      recipientEmailList,
      workspaceId,
      currentUser,
      includeWhiteListedGuestRecipient = false,
    },
    { dispatch }
  ) => {
    // we add this safeguard to ensure conversations have at least 1 other recipient
    if (!includeWhiteListedGuestRecipient && recipientEmailList.length < 2) {
      return;
    }

    const newConversationId = await createDocumentId(
      AvailableCollections.CONVERSATIONS
    );

    const formattedRecipientEmailList = createFormattedRecipientEmailList(
      recipientEmailList,
      newConversationId,
      includeWhiteListedGuestRecipient
    );

    const recipientUserDocuments = await findOrCreateRecipientUserDocument(
      formattedRecipientEmailList
    );

    const conversationDocument = await findOrCreateConversationDocument(
      recipientUserDocuments,
      currentUser,
      newConversationId,
      workspaceId
    );

    if (includeWhiteListedGuestRecipient) {
      // Return link for shareable QR code. Return before setSelectedConversationSettings is called so no navigation takes place
      return {
        link: inviteGuestHostLink(newConversationId),
        newConversationId,
      };
    }

    // set user selected conversation to new conversation
    setSelectedSettings(currentUser.id, conversationDocument.id, workspaceId);

    dispatch(onSelectConversation({ conversationId: conversationDocument.id }));
  }
);

const inviteGuestHostLink = (newConversationId: string) =>
  `${process.env.REACT_APP_BASE_HOST}/login/guest?conversationId=${newConversationId}`;

/**
 * A utility function utilized to create an array of lowercase formatted email strings.
 * This function will also add a whitelisted guest recipient if `addWhiteListedGuestRecipient` is enabled.
 *
 * @param recipientEmailList - an array of strings representing the recipient user's email
 * @param conversationId - the string id value of the conversation id
 * @param addWhiteListedGuestRecipient - a boolean value which when enabled adds a guest user
 * to the recipient email list via their generated recipient id
 * @returns an array of formatted strings which represent the conversation's recipient email list
 */
const createFormattedRecipientEmailList = (
  recipientEmailList: string[],
  conversationId: string,
  addWhiteListedGuestRecipient: boolean
) => {
  const formattedRecipientEmailList = recipientEmailList.map((email) =>
    email.toLowerCase()
  );
  let whiteListedGuestRecipient;

  if (addWhiteListedGuestRecipient) {
    whiteListedGuestRecipient =
      createWhiteListedGuestRecipientId(conversationId);
    formattedRecipientEmailList.push(whiteListedGuestRecipient);
  }

  return formattedRecipientEmailList;
};

/**
 * A function utilized to either find a given `User` document, or create a new `User` document.
 * The function loops through each recipient email string value and uses it to fetch or
 * create the `User` document.
 * @param recipientEmailList - an array of strings representing the recipient user's email
 * @returns an array of `User` documents for both existing and new recipient users of the conversation
 */
const findOrCreateRecipientUserDocument = async (
  recipientEmailList: string[]
) => {
  // For each unregistered user, create a user document and add them to Firestore
  const recipientUserDocumentList = await Promise.all(
    recipientEmailList.map(async (recipientEmail) => {
      const recipientUserDocument = await findOrCreateUser({
        email: recipientEmail,
        name: '',
        isAliasUser: recipientEmail.includes('guest-'),
      });

      return recipientUserDocument;
    })
  );

  return recipientUserDocumentList;
};

const sortRecipientIds = (recipientIds: string[]) =>
  recipientIds.slice(0).sort();

/**
 *
 * @param recipientUserDocuments - an array of `User` documents
 * @param currentUser - the current `User`
 * @param workspaceId - the workspace id of the conversation
 * @returns a map index of all dynamic conversation field key/values
 */
const createConversationConfiguration = (
  recipientUserDocuments: User[],
  currentUser: User,
  workspaceId: string
) => {
  // we use this map index to keep track of users via id we are inviting to the conversation
  const invites: InvitesField = {};

  // we use this map index to keep track of users via id who have partial/full conversation hidden from their conversation list
  const isHidden: isHiddenField = {};

  // we use this map index to keep track of users via id who either typing or not
  const isTyping: { [userId: string]: boolean } = {};

  // we use this map index to keep track of users via id that have message notifications since their last view
  const notifications: NotificationsField = {};

  const recipientIds = recipientUserDocuments.map(
    (userDocument) => userDocument.id
  );

  for (const userDocument of recipientUserDocuments) {
    const isConversationAuthor = userDocument.id === currentUser.id;
    const { isAliasUser } = userDocument.settings;

    // we hide the conversation from view if the recipient is not the author OR a guest
    const isConversationInitiallyHidden = !(
      isConversationAuthor || isAliasUser
    );

    invites[userDocument.id] = !!userDocument.workspaces[workspaceId];
    isTyping[userDocument.id] = false;
    isHidden[userDocument.id] = {
      now: isConversationInitiallyHidden,
      date: null,
    };
    notifications[userDocument.id] = 0;
  }

  return {
    invites,
    isHidden,
    isTyping,
    notifications,
    recipients: recipientIds,
    sortedRecipients: sortRecipientIds(recipientIds),
  };
};

/**
 * A function utilized to create a document within the `Conversation`s collection
 * @param recipientUserDocuments - an array of `User` documents
 * @param currentUser - the current `User`
 * @param conversationId - the id of the conversation
 * @param workspaceId - the workspace id of the conversation
 */
const createConversationDocument = (
  recipientUserDocuments: User[],
  currentUser: User,
  conversationId: string,
  workspaceId: string
) => {
  const conversationConfiguration = createConversationConfiguration(
    recipientUserDocuments,
    currentUser,
    workspaceId
  );

  const newConversationDocument: ConversationDocument = {
    ...baseConversationDocument,
    ...conversationConfiguration,
    createdAt: setFirebaseTimestamp(),
    id: conversationId,
    messageExpiryTimeList: [
      { value: 24, messageId: null, date: setFirebaseTimestamp() },
    ],
    expiresAt: setExpiresAtTimestamp(24),
    workspaceId,
  };

  createFirestoreDocument(
    AvailableCollections.CONVERSATIONS,
    newConversationDocument.id,
    newConversationDocument
  );

  return newConversationDocument;
};

/**
 * A function utilized to either find a given `Conversation` document, or create a new `Conversation` document.
 * @param recipientUserDocuments - an array of `User` documents
 * @param currentUser - the current `User`
 * @param newConversationId - the conversation id of, if applicable, a new conversation
 * @param workspaceId - the workspace id of the conversation
 * @returns a `Conversation` document
 */
const findOrCreateConversationDocument = async (
  recipientUserDocuments: User[],
  currentUser: User,
  newConversationId: string,
  workspaceId: string
) => {
  const recipientIds = recipientUserDocuments.map(
    (userDocument) => userDocument.id
  );
  const sortedRecipientIds = sortRecipientIds(recipientIds);

  const queryForExistingConversation =
    await checkForExistingConversationWithRecipients(
      sortedRecipientIds,
      workspaceId
    );

  const existingConversation = queryForExistingConversation[0] ?? null;

  // if query finds existing conversation
  if (existingConversation) {
    const userPreviouslyDeletedConversation =
      existingConversation.isHidden[currentUser.id]?.now;

    if (userPreviouslyDeletedConversation) {
      await setExistingConversation(existingConversation, currentUser);
    }

    return existingConversation;
  }

  return createConversationDocument(
    recipientUserDocuments,
    currentUser,
    newConversationId,
    workspaceId
  );
};
