import React from 'react';
import { Box, Fade } from '@chakra-ui/react';
import { ReactComponent as Notification } from 'assets/svg/new-message-below.svg';

interface NotificationButtonProps {
  isVisible: boolean;
  onClick: () => void;
}

/**
 * This component is used to display a clickable button within the MessageList component that scrolls the user down to the bottom of the thread.
 */

const NotificationButton: React.FC<NotificationButtonProps> = ({
  isVisible,
  onClick,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box
      position="absolute"
      bottom="60px"
      transform="translate(-50%, -50%)"
      left="50%"
    >
      <Fade in={isVisible} style={styles.mainContainer}>
        <Notification onClick={onClick} className="clickable" />
      </Fade>
    </Box>
  );
};

const styles = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default NotificationButton;
