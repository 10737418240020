/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import ReactGA from 'react-ga';

import { ProcessedConversation } from 'dashboard/models/Conversation';

import { ProcessedMessage } from 'dashboard/models/Message';

import { usePage } from 'shared/contexts/CurrentPageContext';

import { DeviceType } from 'dashboard/models/Device';

import MessageInput from 'dashboard/features/Thread/components/Input';
import getMessageInputPlaceholder from 'dashboard/features/Thread/utils/getMessageInputPlaceholder';
import LoadingConversation from 'dashboard/features/Thread/components/LoadingConversation';
import MessageList from 'dashboard/features/Thread/components/MessageList';

import { Box, Fade, Flex, Spinner } from '@chakra-ui/react';
import { onSendMessage } from 'store/thunks/messages/onSendMessage';
import {
  removeFileAttachment,
  setCurrentMessageThreadLimit,
  setFileAttachments,
  setMessageInput,
  setToolTipVisible,
} from 'store/reducers/messages';
import useOnScrollNotification from './hooks/useOnScrollNotification';
import scrollToBottom from './utils/scrollToBottom';
import useMessageSubscriber from './hooks/useMessageSubscriber';
import NotificationButton from './components/NotificationButton';
import useConversationSettingsSubscriber from './hooks/useConversationSettings';
import getInitialMessageLimit from './utils/getInitialMessageLimit';

export interface FileResult {
  name: string;
  file: File;
  canView: boolean;
  preview: string;
}

interface ThreadProps {
  onSelectMessage: (message: ProcessedMessage) => void;
}

const Thread: React.FC<ThreadProps> = ({ onSelectMessage }) => {
  const inputMessageRef = useRef(null) as any;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null) as any;

  const {
    application: { currentDeviceType, messageListViewportHeight },
    conversations: {
      eventLogs,
      isLocatorTokenMode,
      selectedConversation,
      shouldLoadMoreMessages,
    },
    user: { currentUser },
    messages: {
      processedMessages,
      isLoading,
      messageInput,
      messageLoaders,
      // isSendingMessage,
      isToolTipVisible,
      currentMessageThreadLimit,
      fileUploadBytesTransferred,
      fileUploadTotalBytes,
      fileAttachments,
      newMessageViewed,
      shouldDisplayScrollToBottom,
    },
  } = useSelector((state: RootState) => ({
    application: state.application,
    conversations: state.conversations,
    messages: state.messages,
    user: state.user,
  }));

  const dispatch = useDispatch();

  const page = usePage();
  if (!page.isCurrentPage()) {
    ReactGA.ga('clientTracker.send', {
      hitType: 'pageview',
      page: page.mkCurrentPage(),
    });
  }

  useOnScrollNotification({
    dispatch,
    newMessageViewed,
    messageListRef,
    messageLoaders,
    processedMessages,
    messageListViewportHeight,
    shouldDisplayScrollToBottom,
  });

  useConversationSettingsSubscriber({
    dispatch,
    selectedConversation,
  });

  useMessageSubscriber({
    dispatch,
    selectedConversation,
    currentMessageThreadLimit,
    messageListRef,
    shouldLoadMoreMessages,
    messageLoaders,
    processedMessages,
    messageListViewportHeight,
  });

  /**
   * A function utilized to send a chat message and store the message and its content into the DB
   * @param pendingMessageInput - string; the message to be sent.
   * @returns
   */

  const handleOnSendMessage = (message: string) => {
    dispatch(onSendMessage({ message, inputMessageRef, handleScrollToBottom }));

    if (inputFileRef.current) inputFileRef.current.value = '';
  };
  const handleSetMessageInput = (message: string) =>
    dispatch(setMessageInput(message));

  const onAddFileAttachment = () => {
    // `current` points to the mounted file input elements
    inputFileRef.current?.click();
  };

  const onChangeFileAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = e.target as HTMLInputElement;
    const fileList = eventTarget.files as FileList;
    handleFileAttachment(fileList);
  };

  const handleFileAttachment = (fileList: FileList | File[]) => {
    const fileListLength = fileList.length || 0;

    const addedFileList = [] as FileResult[];

    for (const targetFile of fileList) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (listener) => {
        const fileIsViewable = !!targetFile.type.includes('image');
        const listenerTarget = listener.target as FileReader;

        const fileResult: FileResult = {
          name: targetFile.name,
          file: targetFile,
          canView: fileIsViewable,
          preview: listenerTarget.result as string,
        };
        addedFileList.push(fileResult);
        if (
          addedFileList.length === fileListLength &&
          fileAttachments.length === 0
        ) {
          dispatch(setFileAttachments(addedFileList));
        } else if (fileAttachments.length !== 0) {
          const newArr = [...fileAttachments, ...addedFileList];
          dispatch(setFileAttachments(newArr));
        }
      });
      fileReader.readAsDataURL(targetFile);
    }
  };

  /**
   * A funtion utilized to remove a pending file attachment from the file attachment's list
   * @param file - a pending file to be sent
   * @returns void
   */
  const onRemoveFileAttachment = (fileAttachment: FileResult) =>
    dispatch(removeFileAttachment(fileAttachment.file));

  /**
   * A message submission event trigged by the user pressing the "enter" key.
   * @param event - `KeyboardEvent`
   * @returns void
   */
  const onKeyDownSubmit = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      validateOnSendMessage();
    }
  };

  /**
   * Wrapper for onSendMessage that validates with a tooltip
   * Prevents the user from submitting empty strings and triggers an alert via tooltip to input a message
   * @param event - `KeyboardEvent`
   * @returns void
   */
  const validateOnSendMessage = () => {
    if (messageInput.length || fileAttachments.length) {
      return handleOnSendMessage(messageInput);
    }

    dispatch(setToolTipVisible(true));
    setTimeout(() => {
      dispatch(setToolTipVisible(false));
    }, 1000);
  };

  const renderMessageInput = () => {
    const onDrop = (acceptedFiles: File[]) => {
      const fileList = acceptedFiles;
      handleFileAttachment(fileList);
    };

    return (
      <MessageInput
        fileAttachments={fileAttachments}
        fileUploadProgress={
          (fileUploadBytesTransferred / fileUploadTotalBytes) * 100
        }
        inputFileRef={inputFileRef}
        inputMessageRef={inputMessageRef}
        inputPlaceholder={getMessageInputPlaceholder(
          selectedConversation as ProcessedConversation,
          currentUser
        )}
        // isDisabled={isSendingMessage}
        isMobile={currentDeviceType === DeviceType.MOBILE}
        isToolTipVisible={isToolTipVisible}
        messageInput={messageInput}
        onAddFileAttachment={onAddFileAttachment}
        onChangeFileAttachment={onChangeFileAttachment}
        onDrop={onDrop}
        onKeyDownSubmit={onKeyDownSubmit}
        onRemoveFileAttachment={onRemoveFileAttachment}
        onSendMessage={validateOnSendMessage}
        setMessageInput={handleSetMessageInput}
        newMessageViewed={newMessageViewed}
      />
    );
  };

  /**
   * Function that invokes scrollToBottom
   * @returns void
   *  */
  const handleScrollToBottom = () =>
    scrollToBottom({
      dispatch,
      messageListRef,
    });

  /**
   * A function utilized to select a given message's locator token, which activates a granular
   * activity view of that message. This activity view is a list of event logs for that given key,
   * such as message creation and reads.
   *
   * When selected, a view will apepar in the `SecurityPanel` component, which is a child component
   * within the`Sidebar` feature.
   *
   * @param locatorToken - string; utilized to find related event logs of the given locator token
   * @returns void
   */
  const onSelectMessageViaLocatorToken = (message: ProcessedMessage) => {
    onSelectMessage(message);
  };

  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  useEffect(() => {
    if (fileAttachments.length && messageListRef.current) {
      messageListRef.current.style.bottom = '12rem';
      scrollToBottom({
        dispatch,
        messageListRef,
      });
    }

    if (!fileAttachments.length && messageListRef.current) {
      messageListRef.current.style.bottom = '5rem';
    }
  }, [dispatch, fileAttachments.length, messageListRef]);

  useEffect(() => {
    if (currentMessageThreadLimit < (getInitialMessageLimit(-15) as number)) {
      setIsLoadingMessages(true);
    }

    if (
      Math.abs(currentMessageThreadLimit) === processedMessages.length ||
      !shouldLoadMoreMessages
    ) {
      setIsLoadingMessages(false);
    }
  }, [
    currentMessageThreadLimit,
    messageLoaders.length,
    processedMessages.length,
    shouldLoadMoreMessages,
  ]);

  // This loader prevents a blank screen from appearing between the time the application has loaded and all the messages have been decrypted
  if (isLoading) {
    return <LoadingConversation />;
  }

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="space-between"
      alignItems="flex-end"
      backgroundColor="#fbfbfb"
      flexDirection="column"
      overflowY="hidden"
    >
      {isLoadingMessages && (
        <Flex alignSelf="center" mt={2}>
          <Fade in>
            <Spinner size="lg" color="#0082ff" />
          </Fade>
        </Flex>
      )}
      <MessageList
        isLocatorTokenModeActive={isLocatorTokenMode}
        messageListRef={messageListRef}
        currentUser={currentUser}
        eventLogs={eventLogs}
        shouldDisplayScrollToBottom={shouldDisplayScrollToBottom}
        shouldLoadMoreMessages={shouldLoadMoreMessages}
        isMobile={currentDeviceType === DeviceType.MOBILE}
        messageLoaders={messageLoaders}
        messageExpiryTimeList={selectedConversation.messageExpiryTimeList}
        conversationNameList={selectedConversation.conversationNameList}
        onSetCurrentLimit={() =>
          dispatch(setCurrentMessageThreadLimit(currentMessageThreadLimit - 10))
        }
        onSelectMessageViaLocatorToken={onSelectMessageViaLocatorToken}
        processedMessages={processedMessages}
        dismissScrollToBottom={handleScrollToBottom}
        handleScrollToBottom={handleScrollToBottom}
        conversation={selectedConversation}
      />
      <Box width="100%" position="relative">
        <NotificationButton
          isVisible={shouldDisplayScrollToBottom}
          onClick={handleScrollToBottom}
        />
        {renderMessageInput()}
      </Box>
    </Flex>
  );
};

export default Thread;
