import { useContext } from 'react';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { AuthenticationController } from 'auth/AuthenticationController';
import routes from 'auth/routes';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import MagicLinkQueryStoreContext from 'auth/MagicLinkQueryStoreContext';

export type emailLoginFormValues = {
  email: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Enter your email address')
    .email('Invalid email address'),
  // phone: yup.string().required('Enter your phone number'),
});

const defaultValues: emailLoginFormValues = {
  email: '',
};

// const validationSchema = Yup.object({
//   customerName: Yup.string('Enter a name').required('Name is required'),
//   customerEmailPhone: Yup.string('Enter your Email/Phone Number')
//     // .email("Enter a valid email")
//     .required('Email/Phone Number is required')
//     .test('test-name', 'Enter Valid Phone/Email', function (value) {
//       const emailRegex =
//         /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

//       const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
//       let isValidEmail = emailRegex.test(value);
//       let isValidPhone = phoneRegex.test(value);
//       if (!isValidEmail && !isValidPhone) {
//         return false;
//       }
//       return true;
//     }),
// });

export default function useEmailLoginForm(): FormHandler<emailLoginFormValues> {
  const history = useHistory();
  const { state } = useLocation<PostLoginReturnState | undefined>();
  const magicLinkState = useContext(MagicLinkQueryStoreContext);

  return useFormHandler<emailLoginFormValues>(
    async ({ email }) => {
      const body = {
        email,
        subject: 'Magic Link for Secure Chat',
      };
      await AuthenticationController.requestLoginLink(body);

      // If we are tracking which location to return to after authentication, we need to elevate
      // that information to local storage so that it is accessible when the user
      // comes back after verifying their email address.
      if (state?.returnTo) {
        magicLinkState.update(state.returnTo);
      }

      history.push(routes.code, { email });
    },
    defaultValues,
    schema
  );
}
