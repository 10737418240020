import { Flex } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as TimeIcon } from 'assets/svg/time-icon.svg';

interface RadioCardProps {
  isChecked: boolean;
  label: string;
  onClick: () => void;
}

const RadioCard: React.FC<RadioCardProps> = ({ isChecked, label, onClick }) => {
  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      width="290px"
      height="35px"
      borderRadius="20px"
      border="solid 1.5px #3485f7"
      color={isChecked ? 'white' : '#3485f7'}
      backgroundColor={isChecked ? '#3485f7' : ''}
      marginBottom="10px"
      justifyContent="center"
      alignItems="center"
    >
      {isChecked && (
        <TimeIcon
          height="16px"
          width="16px"
          fill="white"
          style={{ marginRight: '5px' }}
        />
      )}
      {label}
    </Flex>
  );
};

export default RadioCard;
