import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import {
  CenteredLayout,
  CenteredLayoutFooter,
  CenteredLayoutHeading,
} from 'shared/components/CenteredLayout';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import { DeviceType } from 'dashboard/models/Device';
import AuthenticationController from 'auth/AuthenticationController';
import LoginForm from 'auth/login/LoginForm/LoginForm';

import { RootState } from 'store';
import { useSelector } from 'react-redux';
import colors from 'theme/foundations/colors';
import GuestLoginForm from '../GuestLoginForm/GuestLoginForm';

const LoginScene: React.FC = () => {
  const {
    application: { currentDeviceType },
  } = useSelector((state: RootState) => ({
    application: state.application,
  }));

  const isMobile = currentDeviceType === DeviceType.MOBILE;

  // Don't show the login form while we are deciding whether or not the user is logged in.
  // This avoid "flashing" the login form before being redirected away
  return AuthenticationController.loading ? null : (
    <CenteredLayout
      showLogo
      style={{ overflowY: 'hidden' }}
      logoBoxHeight={isMobile ? '58px' : undefined}
      logoHeight={isMobile ? '20px' : undefined}
    >
      <Box
        style={{
          ...styles.mainContainer,
          overflowY: isMobile ? 'visible' : 'auto',
          width: isMobile ? '98%' : '100%',
        }}
        pb={isMobile ? '0px' : '150px'}
      >
        <div
          style={{
            marginBottom: isMobile ? '47px' : '80px',
          }}
        >
          <CenteredLayoutHeading
            style={{
              ...styles.header,
              fontSize: isMobile ? '24px' : '45px',
              letterSpacing: isMobile ? '0.2em' : '0.12em',
              lineHeight: isMobile ? '32px' : '55px',
            }}
            mb={0}
          >
            Secure.Chat
          </CenteredLayoutHeading>
          <CenteredLayoutHeading
            style={{
              ...styles.subtitle,
              fontSize: isMobile ? '16px' : '28px',
              lineHeight: isMobile ? '27px' : '45px',
            }}
            mb={0}
          >
            The safest web chat.
          </CenteredLayoutHeading>
        </div>
        {isMobile && (
          <>
            <Box
              style={{
                ...styles.assetSection,
              }}
              pb="33px"
            >
              <GuestLoginForm isMobile />
            </Box>
            <Box paddingInlineStart="1.5rem" paddingInlineEnd="1.5rem">
              <Divider
                orientation="horizontal"
                borderWidth="1px"
                borderColor="#EBEBEB"
                w="100%"
                maxW="448px"
                margin="auto"
                mb="23px"
              />
            </Box>
          </>
        )}
        <Box
          style={{
            ...styles.centerContainer,
            flexDirection: isMobile ? 'column' : 'row',
          }}
          mb={isMobile ? '0px' : '67px'}
        >
          <div
            style={{
              ...styles.loginFormSection,
            }}
          >
            <LoginForm isMobile={isMobile} />
          </div>
          {!isMobile && (
            <>
              <Divider
                orientation="vertical"
                borderWidth="1px"
                borderColor="#EBEBEB"
                h="412px"
              />
              <Box
                style={{
                  ...styles.assetSection,
                }}
                pr="10px"
              >
                <GuestLoginForm />
              </Box>
            </>
          )}
        </Box>
        <CenteredLayoutFooter
          style={{
            ...styles.footer,
            fontSize: isMobile ? '12px' : '18px',
            lineHeight: isMobile ? '18px' : '36px',
            paddingTop: isMobile ? 0 : '32px',
          }}
        >
          ZERO TRUST DATA PROTECTION BY{' '}
          <a
            style={styles.footerLogo}
            target="_blank"
            rel="noopener noreferrer"
            href="https://xqmsg.co"
          >
            XQ Message
          </a>
        </CenteredLayoutFooter>
      </Box>
    </CenteredLayout>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    overscrollBehavior: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    textTransform: 'uppercase',
    fontFamily: 'proxima-nova',
    fontWeight: 400,

    color: colors.gray[700],
  },
  subtitle: {
    fontFamily: 'proxima-nova',
    fontWeight: 400,

    letterSpacing: '0.03em',

    color: colors.gray[700],
  },
  imageHeaderText: {
    fontSize: 18,
    fontWeight: 300,
    letterSpacing: 0.69,
    width: '100%',
    textAlign: 'center',
    marginBottom: 2,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignSelf: 'center',
    maxWidth: '1600px',
  },
  loginFormSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  assetSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  footer: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 100,

    textAlign: 'center',
    letterSpacing: '0.09em',

    color: colors.gray[700],

    textTransform: 'uppercase',
  },
  footerLogo: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
});

export default LoginScene;
