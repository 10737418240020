import React, { useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CenteredLayoutContent,
  CenteredLayoutContentInner,
  CenteredLayoutHeading,
} from 'shared/components/CenteredLayout';
import { StackedInputField } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import colors from 'theme/foundations/colors';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import { Status } from '../types';
import useGuestLoginForm from './useGuestLoginForm';

interface GuestLoginFormProps {
  isMobile?: boolean;
}

const GuestLoginForm: React.FC<GuestLoginFormProps> = ({
  isMobile = false,
}) => {
  const [status, setStatus] = useState<Status>(Status.Initial);
  const form = useForm();

  const { submitForm } = useGuestLoginForm({ status, setStatus, form });

  // const disabled =
  //   form.watch('alias') === '' || form.watch('alias') === undefined;
  return (
    <FormProvider {...form}>
      <CenteredLayoutContent
        as="form"
        onSubmit={form.handleSubmit((data: { alias: string }) =>
          submitForm(data)
        )}
        noValidate
        alignItems="unset"
        mt={0}
      >
        <CenteredLayoutHeading
          style={styles.formHeader}
          fontSize={isMobile ? '15px' : '24px'}
          mb={isMobile ? '30px' : '89px'}
        >
          Chat as Guest
        </CenteredLayoutHeading>
        <CenteredLayoutContentInner>
          <SimpleGrid spacing={isMobile ? 2 : 4}>
            <StackedInputField
              id="alias"
              name="alias"
              type="text"
              label="Enter Screen Name"
              size={isMobile ? 'md' : 'lg'}
            />
            <SpinnerButton
              type="submit"
              size={isMobile ? 'md' : 'lg'}
              isLoading={status === Status.Checking}
              mt={isMobile ? '8px' : 4}
              role="button"
              backgroundColor="hsla(216, 92%, 59%, 0.85)"
            >
              Start chat
            </SpinnerButton>
          </SimpleGrid>
        </CenteredLayoutContentInner>
      </CenteredLayoutContent>
    </FormProvider>
  );
};

const styles = StyleSheet.create({
  formHeader: {
    fontFamily: 'proxima-nova',
    fontWeight: 400,
    letterSpacing: '0.12em',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.gray[700],
  },
});

export default GuestLoginForm;
