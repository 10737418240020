import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react';

import { Box, Flex, Input, Spacer } from '@chakra-ui/react';

import DefaultAvatar from 'dashboard/components/DefaultAvatar';
import { formatEmail } from 'shared/utils/stringFormatting';
import { User } from 'dashboard/models/User';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import cameraIcon from 'assets/svg/icon-camera.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DeviceType } from 'dashboard/models/Device';
import RadioCard from './components/RadioCard';
import './SettingsView.scss';

interface SettingsViewProps {
  onUpdateName: () => void;
  conversationName: string;
  conversationAvatar: string;
  recipients: User[];
  expiry: ExpiryOption;
  onSelect: (value: number) => void;
  onSetConversationName: ChangeEventHandler<HTMLInputElement>;
  onSetConversationAvatar: MouseEventHandler<HTMLDivElement>;
}

export type ExpiryOption = {
  label: string;
  value: number;
};

export const expiryOptions = [
  { label: '1 hour', value: 1 },
  { label: '8 hours', value: 8 },
  { label: '24 hours', value: 24 },
  { label: '14 days', value: 14 * 24 },
  { label: '30 days', value: 30 * 24 },
  { label: '90 days', value: 90 * 24 },
];

const SettingsView: React.FC<SettingsViewProps> = ({
  recipients,
  conversationAvatar,
  conversationName,
  expiry,
  onSelect,
  onSetConversationName,
  onSetConversationAvatar,
  onUpdateName,
}) => {
  const {
    application: { currentDeviceType },
  } = useSelector((state: RootState) => ({
    application: state.application,
  }));

  const [showIcon, setShowIcon] = useState(false);

  return (
    <Box padding="0 20px">
      <Box style={styles.headerSectionContainer}>
        <Box position="relative">
          <DefaultAvatar
            src={conversationAvatar}
            style={{ width: 110, height: 110, zIndex: 1, borderRadius: 55 }}
            name={conversationName}
            isConversationAvatar
          />
          {recipients.length > 2 && (
            <Flex
              className="avatar-overlay"
              backgroundColor={showIcon ? 'rgba(0,0,0,.25)' : 'transparent'}
              role="button"
              onClick={onSetConversationAvatar}
              onKeyDown={() => null}
              tabIndex={0}
              onMouseEnter={() => setShowIcon(true)}
              onMouseLeave={() => setShowIcon(false)}
            >
              {showIcon && (
                <img
                  alt="camera-icon"
                  src={cameraIcon}
                  className="camera-icon"
                />
              )}
            </Flex>
          )}
        </Box>
      </Box>
      <Box style={styles.sectionHeader}>Chat Name</Box>
      {recipients.length > 2 ? (
        <Input
          onBlur={onUpdateName}
          value={conversationName}
          variant="filled"
          onChange={onSetConversationName}
          size="lg"
          style={{
            border: '.5px solid #0082FF',
            backgroundColor: 'white',
            color: '#000',
            fontFamily: 'proxima-nova',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '16px',
          }}
          maxLength={25}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              return onUpdateName();
            }
          }}
        />
      ) : (
        <Box
          style={{
            color: '#101b4f',
            fontWeight: 600,
            overflowWrap: 'break-word',
            fontSize:
              currentDeviceType === DeviceType.MOBILE && recipients.length > 1
                ? 13
                : 16,
          }}
        >
          {conversationName}
        </Box>
      )}
      <Spacer mt="10px" />
      <Box>
        <Box style={styles.sectionHeader}>Chat Members</Box>
        {recipients.map((recipient) => (
          <div style={styles.itemContainer} key={recipient.id}>
            <DefaultAvatar
              src={recipient.avatar}
              name={recipient.name || formatEmail(recipient.email)}
              style={styles.avatar}
            />
            <div style={styles.itemText}>
              {recipient.name || recipient.email}
            </div>
          </div>
        ))}
      </Box>

      <Spacer mt="20px" />

      <Box>
        <Box style={styles.sectionHeader}>Message Expiry Time</Box>
        {expiryOptions.map(({ label, value }) => {
          return (
            <RadioCard
              key={value}
              isChecked={value === expiry.value}
              label={label}
              onClick={() => onSelect(value)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  headerSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 0',
    flexDirection: 'column',
    minHeight: 150,
  },
  email: {
    color: '#000',
    fontFamily: 'proxima-nova',
    fontSize: '18px',
    fontWeight: 600,
    height: '25px',
    marginTop: '10px',
  },
  sectionHeader: {
    fontFamily: 'proxima-nova',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.01em',
    color: '#a6a6a6',
    marginBottom: 10,
  },
  itemContainer: {
    alignItems: 'center',
    borderRadius: 5,
    display: 'flex',
    margin: '0 0 10px 0',
    padding: 5,
  },
  avatar: { marginRight: 15, height: '35px', width: '35px' },
  itemText: {
    color: '#000',
    fontSize: 15,
    fontWeight: 500,
  },
});
export default SettingsView;
