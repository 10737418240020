import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Logo from 'shared/components/Logo';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';

interface CenteredLayoutProps extends FlexProps {
  children: React.ReactNode;
  showLogo?: boolean;
  logoBoxHeight?: string;
  logoHeight?: string;
}

/**
 * A full viewport height flex layout that  intends its content to be centered.
 * An optional logo can be rendered.
 */
export const CenteredLayout: React.FC<CenteredLayoutProps> = ({
  showLogo,
  children,
  logoBoxHeight = '95px',
  logoHeight = '35px',
  ...props
}) => {
  const isLargerThan980 = useIsLargerThan980();

  return (
    <Flex
      direction="column"
      width="100%"
      overflowY={isLargerThan980 ? 'scroll' : 'unset'}
      height={isLargerThan980 ? '100vh' : 'unset'}
      {...props}
    >
      {showLogo && (
        <Flex
          flex="none"
          alignItems="center"
          width="100%"
          height={logoBoxHeight}
          pl={6}
          mb="auto"
        >
          <Logo height={logoHeight} />
        </Flex>
      )}
      <Flex
        direction="column"
        alignItems="center"
        my="auto"
        width="100%"
        height="100%"
      >
        {children}
      </Flex>
      {showLogo && <Box flex="none" height={logoBoxHeight} mt="auto" />}
    </Flex>
  );
};

export default CenteredLayout;
