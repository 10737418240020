import { ReactComponent as TimeIcon } from 'assets/svg/time-icon.svg';
import { Flex, Text } from '@chakra-ui/react';
import { getExpiryOption } from 'dashboard/features/Sidebar/components/ConversationSettings';
import React from 'react';

interface MessageExpiryProps {
  value: number;
  text: string;
}

const MessageExpiry: React.FC<MessageExpiryProps> = ({ value, text }) => {
  return (
    <Flex alignSelf="center">
      <div>
        <Flex justifyContent="center" p={3}>
          <TimeIcon
            height="19px"
            width="19px"
            fill="#a6a6a6"
            style={{
              marginRight: '8px',
            }}
          />
          <Text
            fontSize="16px"
            lineHeight="16px"
            letterSpacing="0.02em"
            fontFamily="proxima-nova"
            color="#a6a6a6"
          >{`${text} ${getExpiryOption(value).label}.`}</Text>
        </Flex>
      </div>
    </Flex>
  );
};

export default MessageExpiry;
