import React from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

interface SearchBarProps {
  searchInput: string;
  setSearchInput: (input: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchInput,
  setSearchInput,
}) => (
  <Box w="100%" backgroundColor="reg.100" h={50}>
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        style={{ backgroundColor: 'transparent', paddingTop: 5 }}
      >
        <SearchIcon color="#007aff" boxSize={5} />
      </InputLeftElement>

      <Input
        placeholder="Search"
        style={styles.input}
        variant="filled"
        value={searchInput}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setSearchInput(e.currentTarget.value)
        }
      />
    </InputGroup>
  </Box>
);

const styles = {
  input: {
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow: '4px 4px 15px 6px rgba(235, 237, 238, 0.25)',
    fontSize: 18,
    height: '3em',
    paddingLeft: 50,
  },
};

export default SearchBar;
