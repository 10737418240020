import React from 'react';
import routes from 'routes';

import { Flex } from '@chakra-ui/react';
import ProtectedRoute from 'auth/ProtectedRoute';
import FeedbackForm from 'dashboard/features/FeedbackForm';

import Home from '../index';

const DashboardRoutes: React.FC = () => (
  <Flex>
    <ProtectedRoute path={routes.home}>
      <Home />
    </ProtectedRoute>
    <ProtectedRoute path={routes.feedback}>
      <FeedbackForm />
    </ProtectedRoute>
  </Flex>
);

export default DashboardRoutes;
