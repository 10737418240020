import { EditorState } from 'draft-js';
import { getSelectedBlocksList } from './getSelectedBlockList';

type InlineStyles =
  | 'BOLD'
  | 'ITALIC'
  | 'UNDERLINE'
  | 'STRIKETHROUGH'
  | 'CODE'
  | 'SUPERSCRIPT'
  | 'SUBSCRIPT';

const inlineStylesList = [
  'BOLD',
  'ITALIC',
  'UNDERLINE',
  'STRIKETHROUGH',
  'CODE',
  'SUPERSCRIPT',
  'SUBSCRIPT',
];

export const getSelectionInlineStyle = (editorState: EditorState) => {
  const currentSelection = editorState.getSelection();
  if (currentSelection.isCollapsed()) {
    const inlineStyles: Record<string, unknown> = {};
    const styleList = editorState.getCurrentInlineStyle().toList().toJS();
    if (styleList) {
      inlineStylesList.forEach((style) => {
        inlineStyles[style] = styleList.indexOf(style) >= 0;
      });
      return inlineStyles;
    }
  }
  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();
  const selectedBlocks = getSelectedBlocksList(editorState);
  if (selectedBlocks.size > 0) {
    const inlineStyles = {
      BOLD: true,
      ITALIC: true,
      UNDERLINE: true,
      STRIKETHROUGH: true,
      CODE: true,
      SUPERSCRIPT: true,
      SUBSCRIPT: true,
    };
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      let blockStart = i === 0 ? start : 0;
      let blockEnd =
        i === selectedBlocks.size - 1
          ? end
          : selectedBlocks.get(i).getText().length;
      if (blockStart === blockEnd && blockStart === 0) {
        blockStart = 1;
        blockEnd = 2;
      } else if (blockStart === blockEnd) {
        blockStart -= 1;
      }
      for (let j = blockStart; j < blockEnd; j += 1) {
        const inlineStylesAtOffset = selectedBlocks.get(i).getInlineStyleAt(j);
        inlineStylesList.forEach((style) => {
          inlineStyles[style as InlineStyles] =
            inlineStyles[style as InlineStyles] &&
            inlineStylesAtOffset.get(style) === style;
        });
      }
    }
    return inlineStyles;
  }
  return {};
};
