import { FormEvent, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DeepPartial,
  UnpackNestedValue,
  useForm,
  UseFormMethods,
} from 'react-hook-form';

import * as yup from 'yup';
import { ErrorResponse, formatErrorResponse } from 'shared/utils/errors';
import useDeepEffect from './useDeepEffect';

export type FormHandler<T> = {
  form: UseFormMethods<T>;
  error?: ErrorResponse;
  onSubmit: (e?: FormEvent) => Promise<void>;
};

export function useFormHandler<T = Record<string, unknown>>(
  handleSubmit: (values: UnpackNestedValue<T>) => void,
  defaultValues: UnpackNestedValue<DeepPartial<T>>,
  schema?: yup.AnyObjectSchema,
  dontClearErrorsOnSuccess = false
): FormHandler<T> {
  const form = useForm<T>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });
  const [error, setError] = useState<ErrorResponse | undefined>();

  const onSubmit = form.handleSubmit(async (values: UnpackNestedValue<T>) => {
    try {
      await handleSubmit(values);

      // Bypassing the error clearing step is useful for whenever the user gets navigated away
      // from the screen in the submit handler. By not bother to clear the errors, we avoid a console error
      // from popping up and warning about a memory leak.
      if (!dontClearErrorsOnSuccess) {
        form.clearErrors();
        // eslint-disable-next-line no-throw-literal
        setError(undefined);
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(formatErrorResponse(e));
      }
    }
  });

  useDeepEffect(() => {
    // Reset the form anytime the default values change
    form.reset(defaultValues);
  }, [defaultValues]);

  return {
    onSubmit,
    form,
    error,
  };
}
