import { Flex, Text } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import React, { useState } from 'react';
import colors from 'theme/foundations/colors';

const ToggleButton = ({
  handleToggle,
  isMobile,
}: {
  handleToggle: (arg0: boolean) => void;
  isMobile: boolean;
}) => {
  const [toggle, setToggle] = useState(false);

  const handleToggleOn = () => {
    handleToggle(true);
    setToggle(true);
  };

  const handleToggleOff = () => {
    handleToggle(false);
    setToggle(false);
  };
  return (
    <Flex
      style={styles.wrapper}
      height={isMobile ? '39px' : '48px'}
      mb={isMobile ? '10px' : '0px'}
    >
      <Flex
        style={styles.content}
        w="calc(50% - 12px)"
        backgroundColor="white"
        transform={toggle ? `translateX(calc(100% + 16px))` : 'translateX(8px)'}
        position="absolute"
        height={isMobile ? '29px' : '36px'}
      />
      <Flex
        style={styles.content}
        w="100%"
        zIndex="1"
        height={isMobile ? '29px' : '36px'}
        onClick={handleToggleOff}
      >
        <Text
          color={toggle ? 'rgba(56, 57, 62, 0.5)' : colors.gray[700]}
          style={styles.text}
          fontSize={isMobile ? '12px' : '14px'}
        >
          Email
        </Text>
      </Flex>
      <Flex
        style={styles.content}
        w="100%"
        zIndex="1"
        height={isMobile ? '29px' : '36px'}
        onClick={handleToggleOn}
      >
        <Text
          color={toggle ? colors.gray[700] : 'rgba(56, 57, 62, 0.5)'}
          style={styles.text}
          fontSize={isMobile ? '12px' : '14px'}
        >
          Phone Number
        </Text>
      </Flex>
    </Flex>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
    width: '100%',
    maxWidth: '447px',
    transition: 'border-color 300ms linear',

    borderRadius: '30px',
    cursor: 'pointer',
    position: 'relative',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    transition: 'all 300ms',
  },
  text: {
    fontFamily: 'proxima-nova',
    fontWeight: 700,
  },
});

export default ToggleButton;
