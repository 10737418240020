import { getColor } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const baseStyle = {
  color: 'blue.500',
  textDecoration: 'underline',
  transition: 'none',
  _hover: {
    color: 'orange.500',
  },
};

function variantSidebar() {
  return {
    display: 'flex',
    alignItems: 'center',
    // height: '56px',
    pl: 4,
    pr: 4,
    py: 2,
    color: 'gray.500',
    lineHeight: 1.5,
    outline: 'none',
    transition: 'none',
    textDecoration: 'none',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      color: 'primary.500',
      textDecoration: 'none',
    },
    '&.active': {
      color: 'primary.500',
      fontWeight: 'semibold',
    },
  };
}

// This variant represent the same as the sidebar one above but when the route matches exactly
// and not only for a child sub route match. I did it as a separate variant instead of a
// prop to get around React complaining that the dom element was not recognized.
function variantSidebarExact() {
  // props: Dict
  const base = variantSidebar();
  // const { theme } = props;
  // const primaryColor = getColor(theme, 'primary.500');

  return {
    ...base,
    '&.active': {
      // ...base['&.active'],
      // boxShadow: `inset 3px 0 0 0 ${primaryColor}`,
    },
  };
}

function variantSubNavigation(props: Dict) {
  const { theme } = props;
  const primaryColor = getColor(theme, 'primary.500');

  return {
    display: 'block',
    pl: 10,
    mb: 3,
    color: 'gray.900',
    lineHeight: 'shorter',
    fontSize: 'sm',
    outline: 'none',
    transition: 'none',
    textDecoration: 'none',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      color: 'primary.500',
      textDecoration: 'none',
    },
    '&.active': {
      color: 'primary.500',
      boxShadow: `inset 3px 0 0 0 ${primaryColor}`,
      fontWeight: 'semibold',
    },
  };
}

function variantNavlink(props: Dict) {
  const { colorScheme: c, size } = props;

  return {
    color: 'gray.500',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: size ?? 'md',
    _hover: {
      color: `${c}.500`,
      textDecoration: 'none',
    },
    '&.active': {
      color: `${c}.500`,
    },
  };
}

const variants = {
  sidebar: variantSidebar,
  sidebarExact: variantSidebarExact,
  subNavigation: variantSubNavigation,
  navlink: variantNavlink,
};

export default {
  baseStyle,
  variants,
};
