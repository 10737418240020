import React from 'react';
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  // ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import SpinnerButton from 'shared/components/SpinnerButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/svg/arrow-right.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../assets/svg/copy-link.svg';

interface InviteGuestModalProps {
  inviteLink: string;
  isCopying: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCopyInviteLink: () => void;
  onNavigateToChat: () => void;
}

const InviteGuestModal: React.FC<InviteGuestModalProps> = ({
  inviteLink,
  isCopying,
  isOpen,
  // onClose,
  onCopyInviteLink,
  onNavigateToChat,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={() => null}>
      <ModalOverlay />
      <ModalContent
        h="621px"
        maxW="809px"
        w="100%"
        borderRadius="10px"
        background="#FBFBFB"
      >
        {/* <ModalCloseButton color="#979797" /> */}
        <ModalBody>
          <Flex
            h="100%"
            w="100%"
            maxW="400px"
            margin="auto"
            backgroundColor="white"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="8.65px"
          >
            <Text
              fontFamily="proxima-nova"
              mb="35px"
              fontWeight="400"
              fontSize="21.6448px"
              lineHeight="25px"
              color="#202020"
              alignSelf="center"
            >
              Invite a Guest
            </Text>
            <Text
              fontFamily="proxima-nova"
              fontWeight="400"
              fontSize="18.0373px"
              lineHeight="22px"
              textAlign="center"
              color="#202020"
              w="100%"
              maxW="262px"
              mb={10}
            >
              Share the QR code or copy the link to invite{' '}
              <span style={{ fontWeight: 'bold' }}>one</span> guest to chat.
            </Text>
            <QRCodeSVG
              value={inviteLink}
              style={{ width: '170px', height: '170px' }}
            />
            <SpinnerButton
              type="button"
              onClick={onCopyInviteLink}
              border="1.5px solid #3485F7"
              backgroundColor="white"
              color="#3485F7"
              _hover={{ backgroundColor: '#E0EBFD' }}
              _active={{ backgroundColor: '#E0EBFD' }}
              _focus={{ outline: 0 }}
              fontWeight="600"
              fontSize="16px"
              borderRadius="20px"
              w="250px"
              maxW="346px"
              disabled={isCopying}
              isLoading={isCopying}
              mt={10}
            >
              <Icon as={CopyLinkIcon} width="21px" height="15px" mr="10px" />
              Copy Link
            </SpinnerButton>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            w="100%"
            maxW="326px"
            h="47px"
            background="#3485F7"
            onClick={onNavigateToChat}
          >
            Go to Chat
            <ArrowRightIcon style={{ marginLeft: '12px' }} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteGuestModal;
