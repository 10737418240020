import React from 'react';
import { format, formatDistanceToNowStrict } from 'date-fns';

import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';

import { Box, Flex, Progress } from '@chakra-ui/react';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';

import { ReactComponent as TimeIcon } from 'assets/svg/time-icon.svg';
import ConversationMenu from 'dashboard/components/ConversationMenu';

import { useGetRecipientsString } from 'dashboard/features/Sidebar/hooks/useGetRecipientsString';
import NotificationCount from '../NotificationCount';
import EventLogStatus from '../../EventLogStatus';
import { getExpiryOption } from '../../ConversationSettings';

interface ConversationProps {
  conversation: ProcessedConversation;
  messageExpiryTimeValue: number;
  currentUser: User;
  index: number;
  isDisabled?: boolean;
  isOptionsMenuVisible: boolean;
  isMobile?: boolean;
  isSecurityMode?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const timeFromNow = (time: Date) => {
  const fromNow = formatDistanceToNowStrict(time, {
    addSuffix: true,
  });

  return fromNow;
};

const timeRemainingToProgressValue = (time: Date, expiryValue: number) => {
  const rightNow = new Date();

  const timePassed = new Date(time).valueOf() - rightNow.valueOf();
  const ms = 1000 * 60 * 60 * expiryValue;

  const progressPercentage = timePassed / ms;

  return progressPercentage * 100;
};

const updateProgressBarColor = (percentage: number) => {
  if (percentage >= 50) {
    return 'green';
  }

  if (percentage >= 25) {
    return 'yellow';
  }

  return 'red';
};

const getConversationName = (
  conversation: ProcessedConversation,
  otherRecipientUsers: User[]
) => {
  if (conversation.name) {
    return conversation.name;
  }

  const isMultiUserConversation = otherRecipientUsers.length > 1;

  if (isMultiUserConversation) {
    return (
      otherRecipientUsers
        .map((user: { name: string; email: string }) =>
          user.name ? user.name : user.email
        )
        .join(', ')
        .substring(0, 30) + '...'
    );
  }

  // default to name, else use email
  let name = otherRecipientUsers[0].name || otherRecipientUsers[0].email;

  if (name.length > 30) {
    name = name.substring(0, 30) + '...';
  }

  return name;
};

const Conversation: React.FC<ConversationProps> = ({
  conversation,
  messageExpiryTimeValue,
  currentUser,
  index,
  isDisabled = false,
  isOptionsMenuVisible,
  isMobile = false,
  isSecurityMode = false,
  isSelected = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const lastUpdateTimestamp = conversation.updatedAt
    ? format(conversation.updatedAt.toDate(), 'hh:mm a')
    : null;

  /**
   * The value of the time remaining for the conversation / 100
   */
  const progressBarPercentageValue = timeRemainingToProgressValue(
    conversation.expiresAt.toDate(),
    conversation.longestMessageExpiryValue || messageExpiryTimeValue
  );

  /**
   * The color of the progress bar based on its value
   */
  const progressBarColor = updateProgressBarColor(progressBarPercentageValue);

  const timeUntilExpiry = timeFromNow(conversation.expiresAt.toDate());

  const otherRecipientUsers = conversation.recipients.filter(
    (user: { id: string; name: string; email: string }) =>
      user.id !== currentUser.id
  );

  const recipients = useGetRecipientsString(conversation, currentUser);

  return (
    <Box
      key={index}
      overflow="hidden"
      mt="5"
      style={{
        opacity: isDisabled ? 0.2 : 1,
        width: '100%',
      }}
      position="relative"
      onMouseEnter={isDisabled ? () => null : onMouseEnter}
      onMouseLeave={isDisabled ? () => null : onMouseLeave}
    >
      {isOptionsMenuVisible && (
        <div style={styles.optionMenuContainer}>
          <ConversationMenu
            conversationId={conversation.id}
            onClick={onClick}
          />
        </div>
      )}
      <Box
        className="animated animatedFadeInUp fadeInUp"
        color="gray.500"
        fontWeight="semibold"
        letterSpacing="wide"
        fontSize="xs"
        overflow="hidden"
        textTransform="uppercase"
        onClick={isDisabled ? () => null : onClick}
        style={{
          ...styles.box,
          backgroundColor: isSelected ? '#B0D7FB' : '',
          userSelect: isDisabled ? 'none' : 'auto',
        }}
      >
        <div style={styles.conversationHeaderContainer}>
          <DefaultAvatar
            src={conversation.avatar || otherRecipientUsers[0]?.avatar}
            name={conversation.name || recipients}
            number={otherRecipientUsers.length - 1}
            isConversationAvatar
          />

          <div style={styles.threadHeader}>
            <div>
              <span
                style={{
                  ...styles.threadMultiUserTitle,
                  fontWeight: isSelected ? 600 : 500,
                }}
              >
                {getConversationName(conversation, otherRecipientUsers)}
              </span>
              <Flex alignItems="center">
                <TimeIcon height="12px" width="12px" fill="#5C5E66" />
                <Box marginLeft="4px" fontSize="11px" color="#5C5E66">
                  {getExpiryOption(messageExpiryTimeValue)?.label}
                </Box>
              </Flex>
            </div>
            {!isSecurityMode && (
              <div
                style={{
                  ...styles.latestMessageDateTime,
                  fontWeight: isSelected ? 600 : 500,
                }}
              >
                {lastUpdateTimestamp}
              </div>
            )}
          </div>
        </div>

        <div style={styles.progressBarHeaderContainer}>
          <div style={styles.expiryText}>
            {isSecurityMode
              ? conversation.eventStatusMessage
              : `Chat ${
                  timeUntilExpiry.includes('ago') ? 'expired' : 'expires'
                } ${timeUntilExpiry}`}
          </div>
          {conversation.notifications[currentUser.id] > 0 && (
            <NotificationCount
              notificationCount={conversation.notifications[currentUser.id]}
            />
          )}
          {isSecurityMode && (
            <div style={{ position: 'absolute', top: 30, right: 0 }}>
              <EventLogStatus eventLogStatus={conversation.eventLogStatus} />
            </div>
          )}
        </div>
        <Progress
          colorScheme={progressBarColor}
          size="xs"
          value={progressBarPercentageValue}
          style={isMobile ? { height: 2 } : {}}
        />
      </Box>
    </Box>
  );
};

const styles = {
  box: {
    borderRadius: 5,
    padding: '10px 5px 10px 5px',
  },
  conversationHeaderContainer: {
    display: 'flex',
    marginBottom: 15,
  },
  threadHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    marginBottom: 5,
    marginLeft: 12,
    justifyContent: 'space-between',
  },
  threadMultiUserTitle: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap' as const,
    color: '#000000',
  },
  latestMessageDateTime: {
    display: 'flex',
    width: '50%',
    justifyContent: 'flex-end',
    paddingRight: 5,
    color: '#94979b',
  },
  latestMessageText: {
    color: '#94979b',
    fontSize: 12,
    textTransform: 'none' as const,
  },
  singleAvatar: {
    height: '41px',
    width: '40px',
    boxShadow:
      '0 6px 8px 0 rgba(0, 0, 61, 0.2), inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    marginRight: 15,
  },
  progressBarHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
    width: '100%',
  },
  expiryText: { fontSize: 11 },
  optionMenuContainer: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #000000',
    borderRadius: '6px',
    display: 'flex',
    float: 'right' as const,
    height: '46px',
    justifyContent: 'center',
    position: 'absolute' as const,
    right: 3,
    top: 3,
    width: '44px',
    zIndex: 201,
  },
};
export default Conversation;
