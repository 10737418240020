import React from 'react';
import { Box } from '@chakra-ui/react';
import SpinnerButton from 'shared/components/SpinnerButton';
import { FormProvider } from 'react-hook-form';
import CodeValidatorInputField from './CodeValidatorInputField';
import useCodeValidationForm from './useCodeValidatorLoginForm';

export default function CodeValidatorLogin({
  isMobile,
}: {
  isMobile: boolean;
}) {
  const { onSubmit, form, error } = useCodeValidationForm();
  const { isSubmitting } = form.formState;
  return (
    <FormProvider {...form}>
      <Box as="form" onSubmit={onSubmit}>
        <CodeValidatorInputField
          isMobile={isMobile}
          name="code"
          form={form}
          onSubmit={onSubmit}
          responseError={error}
        />
        <SpinnerButton
          type="submit"
          size={isMobile ? 'md' : 'lg'}
          isLoading={isSubmitting}
          //   mt={isMobile ? '8px' : 4}
          mt={4}
          w="100%"
          role="button"
          backgroundColor="hsla(216, 92%, 59%, 0.85)"
        >
          Sign In
        </SpinnerButton>
      </Box>
    </FormProvider>
  );
}
