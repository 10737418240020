import React from 'react';

import { User } from 'dashboard/models/User';

import { Button, Flex, ScaleFade } from '@chakra-ui/react';
import LoadingBox from 'shared/components/LoadingBox';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import instant from 'assets/svg/instant-decrypt.svg';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';

interface DesktopEmptyStateProps {
  currentUser: User;
  onActivateComposeMode: () => void;
}

const DesktopEmptyState: React.FC<DesktopEmptyStateProps> = ({
  currentUser,
  onActivateComposeMode,
}) => {
  if (!currentUser) {
    return (
      <div style={styles.loadingContainer}>
        <LoadingBox text="Loading application" />
      </div>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLargerThan980 = useIsLargerThan980();
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={isLargerThan980 ? '100vh' : 'unset'}
      w="100%"
      background="#fbfbfb"
    >
      <ScaleFade in style={styles.mainContainer}>
        <div style={styles.title}>You have no active conversations.</div>
        <img style={styles.icon} src={instant} alt="" />
        <div style={styles.body}>
          With XQ Secure chat, every message is secure. You don’t have any
          conversations or your active conversations have expired.
        </div>
        <Button
          borderRadius="30px"
          border="solid 2px #007aff"
          padding="13px 11.9px 13px 8.6px"
          variant="outline"
          w="50%"
          h="55px"
          onClick={onActivateComposeMode}
        >
          Start a conversation
        </Button>
      </ScaleFade>
    </Flex>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
  },
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 60,
    zIndex: 0,
  },
  subContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    color: '#000000',
    fontSize: '22px',
    fontWeight: 'bold' as const,
    height: '30px',
    marginBottom: 40,
    textAlign: 'center' as const,
    width: '80%',
  },
  icon: {
    height: '69px',
    marginBottom: 40,
    objectFit: 'contain' as const,
    width: '80%',
  },
  body: {
    color: '#000000',
    fontSize: '18px',
    marginBottom: 40,
    textAlign: 'center' as const,
    width: '80%',
  },
});

export default DesktopEmptyState;
