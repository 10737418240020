import { setIsLoading, setNewMessageViewed } from 'store/reducers/messages';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import {
  EncryptedMessageDocument,
  MessageDocument,
  MessageState,
  ProcessedMessage,
} from 'dashboard/models/Message';
import { User } from 'dashboard/models/User';
import {
  processMessage,
  streamConversationMessages,
} from 'dashboard/services/chat';
import { MutableRefObject } from 'react';
import { Dispatch } from 'redux';
import filterMessageDocuments from './filterMessageDocs';

interface messageListenerProps {
  shouldLoadMoreMessages: boolean;
  processedMessages: ProcessedMessage[];
  currentMessageThreadLimit: number;
  messageLoaders: EncryptedMessageDocument[];
  currentUser: User;
  messageListRef: MutableRefObject<HTMLDivElement>;
  selectedConversation: ProcessedConversation;
  handleShouldLoadMoreMessages: (val: boolean) => void;
  handleRemoveMessageLoaders: (val: string[]) => void;
  handleSetProcessedMessages: (val: ProcessedMessage[]) => void;
  dispatch: Dispatch;
}

/**
 * ### Message listener for current conversation
 * The message collection listener for the currently selected conversation
 *
 * 1. We fetch all message documents sorted via date (asc)
 * 2. Take the last X amount of messages determined by the `currentMessageThreadLimit` value (default: 10)
 * 3. Filter out already processed message documents as well as hidden messages
 * 4. Process/decrypt the remaining message documents
 * 5. Push each message sequentially, as ready.
 */
const messageListener = ({
  processedMessages,
  currentMessageThreadLimit,
  currentUser,
  handleShouldLoadMoreMessages,
  handleRemoveMessageLoaders,
  handleSetProcessedMessages,
  selectedConversation,
  shouldLoadMoreMessages,
  dispatch,
}: messageListenerProps) => {
  const unsubscribeMessageListener = streamConversationMessages(
    selectedConversation.id,
    'asc',
    null,
    {
      next: async (querySnapshot) => {
        const existingMessageIds = processedMessages.map(
          (message) => message.id
        );

        let messageNeedsToBeDeleted = false;

        // An array of all the message documents in the QuerySnapshot.
        const messageDocumentRefArray = querySnapshot.docs;

        // an array of message documents -- all data needed for processing
        const encryptedMessageDocuments = messageDocumentRefArray
          .filter((doc) => {
            // filter out message documents that were created before re-creating conversation
            const message = doc.data() as MessageDocument;

            if (selectedConversation.isHidden[currentUser.id].date === null) {
              return true;
            }

            return (
              message.date.toMillis() >=
              (
                selectedConversation.isHidden[currentUser.id].date as {
                  toMillis: () => number;
                }
              ).toMillis()
            );
          })
          .map((doc) => {
            const { id, state } = doc.data(); // as MessageDocument;

            //  Ensure all deleted messages are listened for and handled messages are listened for and handled
            if (state === MessageState.DELETED) {
              const findProcessedMessage = processedMessages.find(
                (message) => id === message.id
              );

              if (
                findProcessedMessage &&
                findProcessedMessage.text !== 'This message has been deleted'
              ) {
                messageNeedsToBeDeleted = true;
              }
            }
            return doc.data();
          }) as EncryptedMessageDocument[];

        const noMoreMessagesToLoad: boolean =
          filterMessageDocuments({
            docs: encryptedMessageDocuments,
            existingMessageIds,
            currentUser,
            selectedConversation,
          }).length === 0;

        // if no more messages to load, hide load more button
        if (noMoreMessagesToLoad) {
          handleShouldLoadMoreMessages(false);
        }

        const messagesNeedProcessing: boolean =
          messageNeedsToBeDeleted ||
          messageDocumentRefArray.length !== processedMessages.length;

        if (messagesNeedProcessing) {
          // fetch filtered message documents sliced by the `currentMessageThreadLimit` allowed for message display
          const filteredEncryptedMessageDocuments = filterMessageDocuments({
            docs: encryptedMessageDocuments.slice(currentMessageThreadLimit),
            selectedConversation,
            currentUser,
            existingMessageIds,
            processedMessages,
          });

          const messageTimestamps: number[] = [];

          // processedMessageDocuments: process + decrypt filtered message documents
          const processFilteredMessages = async () =>
            Promise.all(
              filteredEncryptedMessageDocuments.map(
                (encryptedMessageDocument) => {
                  const _processedMessage = processMessage(
                    encryptedMessageDocument as unknown as EncryptedMessageDocument
                  );

                  _processedMessage.then((_msg) => {
                    // if the message is from a recipient add timestamp to array
                    if (_msg.senderId !== currentUser.id) {
                      messageTimestamps.push(_msg.date.seconds);
                    }
                  });

                  return _processedMessage;
                }
              )
            );

          const processedFilteredMessages = await processFilteredMessages();

          // Check if one of the incoming messages are newer than the oldest message in the thread
          if (processedMessages.length > 0 && messageTimestamps.length > 0) {
            const hasNewMessage = messageTimestamps.some((_timeStamp) => {
              return (
                _timeStamp >=
                processedMessages[processedMessages.length - 1].date.seconds
              );
            });
            if (hasNewMessage) {
              dispatch(setNewMessageViewed(false));
            }
          }

          const buildProcessedFilteredMessageIDs =
            processedFilteredMessages.map((message) => message.id);
          if (processedFilteredMessages.length > 0) {
            handleRemoveMessageLoaders(buildProcessedFilteredMessageIDs);
            handleSetProcessedMessages(processedFilteredMessages);
          }
        }
        dispatch(setIsLoading(false));
        // if number of processed message is equal/more than the current limit, there may be more messages to load. existingMessageIds.length === messageDocuments.length means it is the user's first session and messages shouldn't require loading yet
        if (
          !shouldLoadMoreMessages &&
          processedMessages.length >= Math.abs(currentMessageThreadLimit) &&
          existingMessageIds.length !== encryptedMessageDocuments.length
        ) {
          handleShouldLoadMoreMessages(true);
        }
      },
    }
  );

  return unsubscribeMessageListener;
};

export default messageListener;
