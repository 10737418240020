import * as React from 'react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';

import downloadIcon from 'assets/svg/noun-download-2633546.svg';
import { useDispatch } from 'react-redux';
import { ProcessedMessage } from 'dashboard/models/Message';
import { onDeleteMessage } from 'store/thunks/messages/onDeleteMessage';
import './OptionMenu.scss';

interface OptionMenuProps {
  isSender: boolean;
  isVisible: boolean;
  message: ProcessedMessage;
  onCopyMessageImage?: () => void;
  onCopyMessageText?: () => void;
  onDownloadFile?: () => Promise<void>;
}

/**
 * A functional component which renders the Chakra popup `Menu` component used by users to interact with relevant messages.
 * @param isImage - a boolean value which represents whether or not the `message` contains an image
 * @param isSender - a boolean value which represents whether or not the `message` was sent by the current `user`
 * @param isVisible - a boolean value which represents the `OptionMenu` visibility
 * @param onCopyMessageText - a function utilized when the "copy" action menu item is clicked
 * @param onDownloadFile - a function utilized when the "download" action menu item is clicked
 * @param message - the `message` which the menu is overlayed upon
 * @returns React.FC
 */
const OptionMenu: React.FC<OptionMenuProps> = ({
  isSender,
  isVisible,
  onCopyMessageImage,
  onCopyMessageText,
  onDownloadFile,
  message,
}) => {
  const dispatch = useDispatch();
  if (!isVisible) {
    return null;
  }

  const isRecipientFileAttachmentMessage = onCopyMessageImage && !isSender;

  const renderDownloadOption = () => {
    return (
      <div
        role="button"
        onClick={onDownloadFile}
        onKeyDown={onDownloadFile}
        tabIndex={0}
        id="icon-container"
      >
        <img src={downloadIcon} alt="download" />
      </div>
    );
  };

  const renderOwnerOption = () => (
    <div
      id="icon-container"
      style={{
        width: onDownloadFile ? 25 : '100%',
      }}
    >
      <Menu>
        <MenuButton color="black">
          <IoEllipsisVerticalSharp size={22} color="black" />
        </MenuButton>
        <MenuList style={{ zIndex: 14001 }}>
          {onCopyMessageText && (
            <MenuItem onClick={onCopyMessageText} color="black">
              Copy
            </MenuItem>
          )}
          {isSender ? (
            <MenuItem
              onClick={() =>
                dispatch(
                  onDeleteMessage({
                    messageId: message.id,
                    fileReferenceUrl: message.fileAttachment.url,
                  })
                )
              }
              color="#ed4337"
            >
              Delete Message
            </MenuItem>
          ) : null}
        </MenuList>
      </Menu>
    </div>
  );

  return (
    <div
      className={`option-menu-container ${
        isSender ? 'is-sender' : 'is-recipient'
      }`}
      style={{
        width: onDownloadFile && isRecipientFileAttachmentMessage ? 50 : 80,
        padding: onDownloadFile ? '0 10px' : 0,
      }}
    >
      {onDownloadFile && renderDownloadOption()}
      {!isRecipientFileAttachmentMessage && renderOwnerOption()}
    </div>
  );
};

export default OptionMenu;
