import { FeedbackResponse } from 'dashboard/features/FeedbackForm';
import { User } from 'dashboard/models/User';
import {
  AvailableCollections,
  FirestoreTimestamp,
  FIREBASE_FIRESTORE,
  setFirebaseTimestamp,
} from '../firebase';

interface SetFeedbackProps {
  experience?: FeedbackResponse;
  comments?: string;
  user: User | null;
  feedbackDocumentId?: string;
}

interface Feedback {
  contact: { id: string; email: string; name: string } | null;
  createdAt: FirestoreTimestamp;
  comments?: string;
  experience?: FeedbackResponse;
}

export const createFeedbackId = () => {
  return FIREBASE_FIRESTORE.collection(AvailableCollections.FEEDBACK).doc().id;
};

/**
 * Creates a `Feedback` document which is submitted after completing the feedback form.
 * @param experience - a one-word designation of the user's experience. Poor, Fair, Good and Excellent.
 * @param comments - a string value which contains the user's detailed feedback
 * @param feedbackDocumentId - a string value representing an already existing doc id.
 * @param contact - a Map of optional values which include the user's contact details
 * @returns - the feedback document id
 */
export const setFeedback = async ({
  user,
  comments,
  experience,
  feedbackDocumentId,
}: SetFeedbackProps) => {
  const id = feedbackDocumentId ?? createFeedbackId();

  const feedback: Feedback = {
    contact: user ? { id: user.id, email: user.email, name: user.name } : null,
    createdAt: setFirebaseTimestamp(),
  };

  if (comments) feedback.comments = comments;

  if (experience) feedback.experience = experience;

  await FIREBASE_FIRESTORE.collection(AvailableCollections.FEEDBACK)
    .doc(id)
    .set(feedback, { merge: true });

  return id;
};
