import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type NavigationLinkProps = NavLinkProps & { isProtected?: boolean };

const NavigationLink: React.FC<NavigationLinkProps> = ({
  isProtected = false,
  ...props
}) => {
  // TODO(worstestes - 6.29.22) - REMOVE UNUSED COMPONENT, technically still using navigation link props type above.
  if (isProtected) {
    return null;
  }

  return <NavLink activeClassName="active" {...props} />;
};

export default NavigationLink;
