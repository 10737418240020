import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';

export type LinkFormValues = {
  text: string;
  url: string;
};

const schema = yup.object().shape({
  text: yup.string().required('Enter text'),
  url: yup.string().required('Enter link'),
});

export default function useLinkForm(
  onConfirm: (url: string, text: string) => void,
  _text?: string
): FormHandler<LinkFormValues> {
  const defaultValues: LinkFormValues = {
    text: _text ?? '',
    url: '',
  };

  return useFormHandler<LinkFormValues>(
    async ({ url, text }) => {
      return onConfirm(url, text);
    },
    defaultValues,
    schema,
    true
  );
}
