import { Workspace, WorkspaceOptions } from 'dashboard/models/Contact';
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
} from 'react';
import { CursorIndex, Toggle } from '../InviteNewUser/InviteNewUserForm';
import { validateEmail } from './validate';

enum Keycode {
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  ARROW_RIGHT = 39,
  ARROW_LEFT = 37,
  ENTER = 13,
  COMMA = 188,
  SPACE = 32,
  ESCAPE = 27,
}

// Handles onChange functionality for phone and email input fields
export default function useHandleKeystrokes({
  addRecipient,
  builtWorkspacesClone,
  currentEmailValue,
  currentPhoneValue,
  currentRecipientsValue,
  filterWorkspace,
  inputFocussed,
  isCreatable,
  isValidPhoneNumber,
  setInputFocussed,
  setSearchableBuiltWorkspacesClone,
  searchableBuiltWorkspacesClone,
  setShowInputValidationWarning,
  showInputValidationWarning,
  toggle,
  setCursorIndex,
  cursorIndex,
  numberOfWorkspaces,
  numberOfUsersInWorkspace,
  dropdownRef,
  numberOfUsersInPreviousWorkspace,
  inputRef,
}: {
  addRecipient: (currentFieldValue: string, bid: number) => void;
  builtWorkspacesClone: Workspace;
  currentEmailValue: string;
  currentPhoneValue: string;
  currentRecipientsValue: string;
  filterWorkspace: (arg0: (arg0: WorkspaceOptions) => boolean) => Workspace;
  inputFocussed: boolean;
  isCreatable: () => boolean;
  isValidPhoneNumber: boolean;
  setInputFocussed: (arg0: boolean) => void;
  setSearchableBuiltWorkspacesClone: (arg0: Workspace) => void;
  searchableBuiltWorkspacesClone: Workspace;
  setShowInputValidationWarning: (arg0: boolean) => void;
  showInputValidationWarning: boolean;
  toggle: Toggle;
  setCursorIndex: Dispatch<SetStateAction<CursorIndex>>;
  cursorIndex: CursorIndex;
  numberOfWorkspaces: number;
  numberOfUsersInWorkspace: number;
  dropdownRef: MutableRefObject<HTMLDivElement | undefined>;
  numberOfUsersInPreviousWorkspace: number;
  inputRef: RefObject<HTMLDivElement | null>;
}) {
  useEffect(() => {
    if (showInputValidationWarning) {
      setShowInputValidationWarning(false);
    }

    // prevent infinite loop - if dropdown not focused return
    if (!dropdownRef.current?.focus) {
      return;
    }

    // Reset cursor when blurred
    if (!inputFocussed) {
      setCursorIndex({
        workspaceIndex: 0,
        optionsIndex: 0,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyPress = (e: any) => {
      // Prevent browsers from submitting forms when the enter key is pressed and input is focussed
      if (e.key === Keycode.ENTER) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyUp = (e: any) => {
      const key = e.keyCode;

      // A manual approach detecting comma and space on android keyboard
      const androidCreateEntryKeystroke =
        e.key === 'Unidentified' &&
        (e.target.value[e.target.value.length - 1] === ' ' ||
          e.target.value[e.target.value.length - 1] === ',');

      const createEntryKeystroke =
        key === Keycode.COMMA ||
        key === Keycode.SPACE ||
        key === Keycode.ENTER ||
        androidCreateEntryKeystroke;
      // exit out of dropdown if user presses esc
      if (key === Keycode.ESCAPE) {
        // unfocus input field when a recipient has been added to the list and the dropdown has been closed
        if (inputRef && inputRef.current) {
          (
            inputRef.current.children[0].lastChild?.lastChild as HTMLElement
          ).blur();
        }
        return setInputFocussed(false);
      }

      // Reopen the dropdown if a selection was previously made and it was closed. Don't perform the rest of arrow_down logic
      if (key === Keycode.ARROW_DOWN && !inputFocussed) {
        return setInputFocussed(true);
      }

      if (key === Keycode.ARROW_LEFT || key === Keycode.ARROW_RIGHT) {
        return e.stopPropagation();
      }

      if (dropdownRef && dropdownRef.current) {
        const dropDownOptionClientHeight = 35;

        if (key === Keycode.ARROW_DOWN) {
          if (cursorIndex.optionsIndex < numberOfUsersInWorkspace - 1) {
            setCursorIndex({
              workspaceIndex: cursorIndex.workspaceIndex,
              optionsIndex: cursorIndex.optionsIndex + 1,
            });
            dropdownRef.current.scrollTop += dropDownOptionClientHeight;
            return;
          }

          if (cursorIndex.workspaceIndex < numberOfWorkspaces - 1) {
            setCursorIndex({
              workspaceIndex: cursorIndex.workspaceIndex + 1,
              optionsIndex: 0,
            });
            dropdownRef.current.scrollTop += dropDownOptionClientHeight * 2.5;
            return;
          }

          // return if user is at the bottom or top of the dropdown list and still presses the arrow key
          return;
        }
        if (key === Keycode.ARROW_UP) {
          if (cursorIndex.optionsIndex > 0) {
            setCursorIndex({
              workspaceIndex: cursorIndex.workspaceIndex,
              optionsIndex: cursorIndex.optionsIndex - 1,
            });
            dropdownRef.current.scrollTop -= dropDownOptionClientHeight;
            return;
          }

          if (cursorIndex.workspaceIndex > 0) {
            setCursorIndex({
              workspaceIndex: cursorIndex.workspaceIndex - 1,
              optionsIndex: numberOfUsersInPreviousWorkspace - 1,
            });
            dropdownRef.current.scrollTop -= dropDownOptionClientHeight * 2;
            return;
          }

          // return if user is at the bottom or top of the dropdown list and still presses the arrow key
          return;
        }
      }

      if (!createEntryKeystroke) {
        // Begin searching workspaces in dropdown by character
        const filterCondition = (option: WorkspaceOptions) =>
          option.value.toLowerCase().startsWith(e.target.value?.toLowerCase());
        const results = filterWorkspace(filterCondition);
        setSearchableBuiltWorkspacesClone(results);
      } else {
        // If a create entry keystroke has been pressed, show all users
        setSearchableBuiltWorkspacesClone(builtWorkspacesClone);
      }
      //
      const updateRecipients = (currentFieldValue: string, type: Toggle) => {
        if (inputFocussed && createEntryKeystroke && isCreatable()) {
          // New recipient workflow: if no workspaces are left in the searchable array, begin validation
          if (Object.keys(searchableBuiltWorkspacesClone).length === 0) {
            if (type === Toggle.email) {
              // validate emails before adding to recipient array
              if (!validateEmail(currentFieldValue.slice(0, -1))) {
                return setShowInputValidationWarning(true);
              }
            }
            if (type === Toggle.phone) {
              // validate phone numbers before adding to recipient array
              if (!isValidPhoneNumber) {
                return setShowInputValidationWarning(true);
              }
            }

            // if whitespace then remove whitespace
            if (currentFieldValue.indexOf(' ') >= 0) {
              currentFieldValue = currentFieldValue.replaceAll(' ', '');
            }

            // workspace is brand new set it to 0
            return addRecipient(currentFieldValue, 0);
          }

          // Existing Recipient workflow:
          const value = Object.values(searchableBuiltWorkspacesClone)[
            cursorIndex.workspaceIndex
          ]?.options[cursorIndex.optionsIndex]?.value;
          const bid = Object.values(searchableBuiltWorkspacesClone)[
            cursorIndex.workspaceIndex
          ]?.options[cursorIndex.optionsIndex]?.bid;

          addRecipient(value, bid);
        }
      };

      if (toggle === Toggle.phone) {
        updateRecipients(
          currentPhoneValue.trim().replace(',', ''),
          Toggle.phone
        );
      }

      if (toggle === Toggle.email) {
        updateRecipients(
          currentEmailValue.trim().replace(',', ''),
          Toggle.email
        );
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPhoneValue,
    currentEmailValue,
    currentRecipientsValue,
    inputFocussed,
    isValidPhoneNumber,
    setCursorIndex,
    cursorIndex,
    numberOfWorkspaces,
    numberOfUsersInWorkspace,
    dropdownRef,
    numberOfUsersInPreviousWorkspace,
  ]);
}
// add more to dep array?
