import routes from 'routes';
import { useHistory } from 'react-router-dom';
import AuthenticationController from 'auth/AuthenticationController';
import { useState } from 'react';
import useSignInWindow from './useSignInWindow';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const DOMAIN = `${window.location.protocol}//${window.location.host}`;
const REDIRECT_URL = `${DOMAIN}/auth-redirect.html`;
const SCOPE = 'openid profile email';
const AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPE}&response_type=id_token&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}`;

export function useGoogleLogin(redirectTo: string = routes.home) {
  const history = useHistory();
  const [error, setError] = useState<Error>();

  const nonce =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  const requestGoogleLogin = useSignInWindow({
    url: `${AUTH_URL}&nonce=${nonce}`,
    name: 'Sign in with Google',
    onCallback: async (params: URLSearchParams) => {
      const idToken = params.get('id_token') || undefined;
      if (idToken) {
        try {
          await AuthenticationController.federatedLogin(idToken);
          history.push(redirectTo);
        } catch (e) {
          if (e instanceof Error) {
            setError(e);
          }
        }
      }
    },
  });
  return { requestGoogleLogin, error };
}
