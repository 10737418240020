import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { DeviceType } from 'dashboard/models/Device';

import { StyleSheet } from 'dashboard/scss/StyleSheet';

import { setFeedback } from 'dashboard/services/feedback/setFeedback';

import CompleteScreen from 'dashboard/features/FeedbackForm/components/CompleteScreen';
import InputSection from 'dashboard/features/FeedbackForm/components/InputSection';
import NextButton from 'dashboard/features/FeedbackForm/components/NextButton';
import OptionSection from 'dashboard/features/FeedbackForm/components/OptionSection';
import ProgressDots from 'dashboard/features/FeedbackForm/components/ProgressDots';

import { setFeedbackMode } from 'store/reducers/application';
import { Box } from '@chakra-ui/react';
import BackButton from './components/BackButton';

type DisplayResponse = {
  type: 'FeedbackForm.displayResponse';
  progress: number;
  feedbackDocumentId?: string;
};

type DisplayInput = {
  type: 'FeedbackForm.displayInput';
  progress: number;
  feedbackDocumentId: string;
};

type DisplayComplete = {
  type: 'FeedbackForm.displayComplete';
};

type State = DisplayComplete | DisplayInput | DisplayResponse;

export enum FeedbackResponse {
  POOR = 'poor',
  FAIR = 'fair',
  GOOD = 'good',
  EXCELLENT = 'excellent',
}

const FeedbackForm: React.FC = () => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState<State>({
    type: 'FeedbackForm.displayResponse',
    progress: 1,
  });

  const [experienceResponse, setExperienceResponse] =
    useState<FeedbackResponse | null>(null);

  const [feedbackInput, setFeedbackInput] = useState<string>('');

  const [allowXQToContact, setAllowXQToContact] = useState<boolean>(false);

  const {
    application: { currentDeviceType },
    currentUser,
  } = useSelector((state: RootState) => ({
    application: state.application,
    currentUser: state.user.currentUser,
  }));

  const onNextClick = async (response?: FeedbackResponse) => {
    switch (formState.type) {
      case 'FeedbackForm.displayResponse': {
        if (!response) break;

        const feedbackDocumentId = await setFeedback({
          user: currentUser,
          experience: response,
          feedbackDocumentId: formState.feedbackDocumentId,
        });

        return setFormState({
          type: 'FeedbackForm.displayInput',
          progress: 2,
          feedbackDocumentId,
        });
      }

      case 'FeedbackForm.displayInput':
        await setFeedback({
          comments: feedbackInput,
          user: currentUser,
          feedbackDocumentId: formState.feedbackDocumentId,
        });

        return setFormState({ type: 'FeedbackForm.displayComplete' });

      case 'FeedbackForm.displayComplete':
        return null;
    }
  };

  const selectedView = () => {
    switch (formState.type) {
      case 'FeedbackForm.displayResponse':
        return (
          <div style={styles.centerContainer}>
            <div style={styles.contentSection}>
              <OptionSection
                onResponseClick={(option) => setExperienceResponse(option)}
                selectedOption={experienceResponse}
              />
            </div>
            <div style={styles.buttonSection}>
              <BackButton onClick={() => dispatch(setFeedbackMode(false))} />
              <NextButton
                isDisabled={!experienceResponse}
                onClick={() =>
                  onNextClick(experienceResponse as FeedbackResponse)
                }
              />
            </div>
          </div>
        );
      case 'FeedbackForm.displayInput':
        return (
          <div style={styles.centerContainer}>
            <div style={styles.contentSection}>
              <InputSection
                allowXQToContact={allowXQToContact}
                onSetAllowXQToContact={() =>
                  setAllowXQToContact(!allowXQToContact)
                }
                onSetFeedbackInput={setFeedbackInput}
                feedbackInput={feedbackInput}
              />
            </div>
            <div style={styles.buttonSection}>
              <BackButton
                onClick={() =>
                  setFormState({
                    ...formState,
                    type: 'FeedbackForm.displayResponse',
                    progress: 1,
                  })
                }
              />
              <NextButton
                isDisabled={feedbackInput.length === 0}
                onClick={onNextClick}
              />
            </div>
          </div>
        );
      case 'FeedbackForm.displayComplete':
        return (
          <CompleteScreen
            onStartConversation={() => dispatch(setFeedbackMode(false))}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      style={{
        ...styles.mainContainer,
        padding: currentDeviceType === DeviceType.MOBILE ? '5rem 0 0 0' : '0',
      }}
    >
      {selectedView()}
      {formState.type !== 'FeedbackForm.displayComplete' && (
        <div style={{ position: 'fixed', bottom: 50 }}>
          <ProgressDots selectedNumber={formState.progress} />
        </div>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  mobileMainContainer: { padding: '5rem' },
  title: {
    color: '#202020',
    fontSize: 32,
    fontWeight: 'bold',
    letterSpacing: '0.03em',
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80%',
    position: 'relative',
  },
  contentSection: {
    alignItems: 'center',
    display: 'flex',
    flex: 0.5,
    justifyContent: 'center',
  },
  buttonSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
});

export default FeedbackForm;
