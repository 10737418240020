import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { authorizeUser } from 'dashboard/services/XQSDK';
import { Step } from './index';

export type PhoneLoginFormValues = {
  phone: string;
};

const schema = yup.object().shape({
  phone: yup.string().required('Enter your phone number'),
});

const defaultValues: PhoneLoginFormValues = {
  phone: '',
};

export default function usePhoneLoginForm(
  setStep: React.Dispatch<React.SetStateAction<Step>>
): FormHandler<PhoneLoginFormValues> {
  return useFormHandler<PhoneLoginFormValues>(
    async ({ phone }) => {
      const text =
        "[XQ] Your login verification code is $pin. Don't share this with anyone. We will never contact you for this code.";

      const response = await authorizeUser({ user: phone, text });
      if (response?.statusCode === 200) {
        return setStep(Step.verification);
      }

      throw new Error('Invalid Phone Number');
    },
    defaultValues,
    schema,
    true
  );
}
