import {
  FaBold,
  FaCode,
  FaItalic,
  FaLink,
  FaListOl,
  FaListUl,
  FaUnderline,
} from 'react-icons/fa';
import { BiCodeBlock } from 'react-icons/bi';

export const inlineStyles = [
  { type: 'BOLD', toolTip: 'Bold', icon: FaBold },
  { type: 'ITALIC', toolTip: 'Italic', icon: FaItalic },
  {
    type: 'UNDERLINE',
    toolTip: 'Underline',
    icon: FaUnderline,
  },
  {
    type: 'CODE',
    toolTip: 'Code Snippet',
    icon: FaCode,
  },
];

export const blockStyles = [
  {
    type: 'code-block',
    toolTip: 'Code Block',
    icon: BiCodeBlock,
  },

  {
    type: 'unordered-list-item',
    toolTip: 'Unordered List',
    icon: FaListUl,
  },
  {
    type: 'ordered-list-item',
    toolTip: 'Ordered List',
    icon: FaListOl,
  },
];

export const entityStyles = [{ type: 'LINK', toolTip: 'Link', icon: FaLink }];
