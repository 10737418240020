import {
  ConversationDocument,
  ConversationName,
  MessageExpiryTime,
  ProcessedConversation,
} from 'dashboard/models/Conversation';

import { streamConversationSettings } from 'dashboard/services/chat';
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { setSelectedConversationSettings } from 'store/reducers/conversations';

import store from 'store';
import {
  AvailableCollections,
  updateFirestoreDocument,
} from 'dashboard/services/firebase';

interface useConversationSettingSubscriberProps {
  selectedConversation: ProcessedConversation;
  dispatch: Dispatch;
}

const useConversationSettingsSubscriber = ({
  selectedConversation,
  dispatch,
}: useConversationSettingSubscriberProps) => {
  useEffect(() => {
    const state = store.getState();
    const { currentUser } = state.user;

    const unsubscribeConversationSettingsListener = streamConversationSettings(
      selectedConversation.id,
      {
        next: async (querySnapshot) => {
          const conversationDocumentRefArray = querySnapshot.docs;

          const data =
            conversationDocumentRefArray[0].data() as ConversationDocument;

          const settings = {
            messageExpiryTimeList:
              data.messageExpiryTimeList as MessageExpiryTime[],
            conversationNameList:
              data.conversationNameList as ConversationName[],
            // avatar: data.avatar,
            // name: data.name,
          };

          const userNotificationCount = data.notifications[currentUser.id];

          // clear notification count if user is currently selecting the conversation
          if (userNotificationCount > 0) {
            const updatedNotifications = {
              notifications: { ...data.notifications, [currentUser.id]: 0 },
            };
            await updateFirestoreDocument(
              AvailableCollections.CONVERSATIONS,
              selectedConversation.id,
              updatedNotifications
            );
          }
          dispatch(setSelectedConversationSettings(settings));
        },
      }
    );
    return () => {
      unsubscribeConversationSettingsListener();
    };
  }, [selectedConversation.id, dispatch, selectedConversation.notifications]);
  return null;
};

export default useConversationSettingsSubscriber;
