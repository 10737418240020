import { BusinessOverview } from 'backend/api-types/dashboard';
import { User } from 'dashboard/models/User';
import { XQStoredWorkspace } from 'dashboard/models/Workspace';
import { AvailableCollections, FIREBASE_FIRESTORE } from './firebase';

/**
 * A function utilized to find saved user workspaces in Firestore, else add them.
 * @param queriedXQWorkspaces - an array of queried workspaces users are apart of
 * @returns void
 */
export const findOrAddWorkspaces = async (
  queriedXQWorkspaces: BusinessOverview[],
  userId: string
) => {
  if (!queriedXQWorkspaces) {
    return;
  }
  return queriedXQWorkspaces.forEach((workspace) => {
    const workspaceData: XQStoredWorkspace = { ...workspace };

    const workspaceDocumentId = workspaceData.id.toString();
    const workspaceName = workspaceData.name;

    const workspaceDocumentReference = FIREBASE_FIRESTORE.collection(
      AvailableCollections.WORKSPACES
    ).doc(workspaceDocumentId);

    const userDocumentReference = FIREBASE_FIRESTORE.collection(
      AvailableCollections.USERS
    ).doc(userId);

    workspaceDocumentReference.get().then((workspaceDocument) => {
      // if workspace doesn't exist, create it.
      if (!workspaceDocument.exists) {
        const newWorkspaceDocument = {
          id: workspaceDocumentId,
          name: workspaceName,
        };
        workspaceDocumentReference.set(newWorkspaceDocument, {
          merge: true,
        });
      }
    });

    userDocumentReference.get().then((userDocument) => {
      // if workspace doesn't exist on user's workspace list, add it.
      const userData = userDocument.data() as User;
      const workspaceExistsOnUserData =
        !!userData.workspaces[workspaceDocumentId];

      if (!workspaceExistsOnUserData) {
        const newWorkspaceDocument = {
          workspaces: { [workspaceDocumentId]: true },
        };
        userDocumentReference.set(newWorkspaceDocument, {
          merge: true,
        });
      }
    });
  });
};
