import { Box } from '@chakra-ui/react';
import { FileResult } from 'dashboard/features/Thread';
import {
  ContentBlock,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import React, { useEffect, useRef, useState } from 'react';

import colors from 'theme/foundations/colors';
import Toolbar, { strategyDecorator } from './Toolbar';
import { clearInlineStyles } from './utils/clearInlineStyles';
import { getCurrentBlockType } from './utils/getCurrentBlockType';
import { getResetEditorState } from './utils/getResetEditorState';
import { getSelectedBlock } from './utils/getSelectedBlockList';
import { getSelectionEntity } from './utils/getSelectionEntity';
import { getSelectionInlineStyle } from './utils/getSelectionInlineStyles';
import { insertText } from './utils/insertText';
import moveSelectionToEnd from './utils/moveSelectionToEnd';

interface DesktopInputProps {
  fileAttachments: FileResult[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputMessageRef: React.MutableRefObject<any>;
  messageInput: string;
  onAddFileAttachment: () => void;
  onSendMessage: () => void;
  newMessageViewed: boolean;
  focussed: boolean;
  onFocus: () => void;
  onBlur: () => void;
  handleFocus: () => void;
  setMessageInput: (input: string) => void;
}

/**
 * A functional React component utilized to render the `DesktopInput` component
 */
const DesktopInput: React.FC<DesktopInputProps> = ({
  inputMessageRef,
  onSendMessage,
  messageInput,
  fileAttachments,
  onAddFileAttachment,
  onFocus,
  onBlur,
  focussed,
  handleFocus,
  setMessageInput,
}) => {
  const [codeSnippetEnabled, setCodeSnippetEnabled] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty(strategyDecorator)
  );

  useEffect(() => {
    // Focus input field when user loads chat
    inputMessageRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendMessageHandler = async () => {
    await onSendMessage();
    setMessageInput('');
    setCodeSnippetEnabled(false);
    return setEditorState((prevEditorState) =>
      getResetEditorState(prevEditorState)
    );
  };

  const hasFocus = editorState.getSelection().getHasFocus();

  const blockStyle = (block: ContentBlock) => {
    const type = block.getType();
    switch (type) {
      case 'code-block':
        return 'code-block';
      default:
        return '';
    }
  };

  const handleKeyCommand = (command: string) => {
    const newCurrentContent = editorState.getCurrentContent();
    let contentExists = newCurrentContent.getPlainText().length > 0;

    if (!contentExists && fileAttachments.length) {
      contentExists = true;
    }

    switch (command) {
      case 'shift-enter': {
        if (newCurrentContent.getLastBlock().getType() === 'code-block') {
          const newEditorState = RichUtils.insertSoftNewline(editorState);
          if (newEditorState !== editorState) {
            setEditorState(newEditorState);
          }
        } else {
          const selection = editorState.getSelection();
          const textWithEntity = Modifier.splitBlock(
            newCurrentContent,
            selection
          );

          let newEditorState = EditorState.push(
            editorState,
            textWithEntity,
            'split-block'
          );

          if (editorState.getCurrentInlineStyle().includes('CODE')) {
            newEditorState = clearInlineStyles(newEditorState, ['CODE']);
          }

          setEditorState(newEditorState);
        }

        return 'handled';
      }
      case 'enter': {
        if (!contentExists) {
          return 'handled';
        }

        onSendMessageHandler();

        return 'handled';
      }
      case 'right-code': {
        setEditorState(
          insertText(RichUtils.toggleInlineStyle(editorState, 'CODE'), ' ')
        );
        return 'handled';
      }
      case 'space-link': {
        setEditorState(
          insertText(
            RichUtils.toggleLink(
              editorState,
              editorState.getSelection(),
              getSelectionEntity(editorState) as string
            ),
            ' '
          )
        );
        return 'handled';
      }
      case 'backspace-block': {
        setEditorState(
          RichUtils.toggleBlockType(
            editorState,
            getCurrentBlockType(editorState)
          )
        );

        return 'handled';
      }
      default:
        return 'not-handled';
    }
  };

  const keyBindingFn = (e: React.KeyboardEvent<Record<string, unknown>>) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();

    if (e.key === 'Enter') {
      if (e.nativeEvent.shiftKey) {
        return 'shift-enter';
      }
      return 'enter';
    }
    if (
      e.key === 'ArrowRight' &&
      getSelectionInlineStyle(editorState).CODE &&
      // Is the end of the code snippet
      selection.getEndOffset() === currentContent.getPlainText().length
    ) {
      return 'right-code';
    }
    if (
      e.key === ' ' &&
      getSelectionEntity(editorState) &&
      // Is the end of the link selection
      selection.getEndOffset() === currentContent.getPlainText().length
    ) {
      return 'space-link';
    }
    if (e.key === 'Backspace') {
      if (
        getSelectedBlock(editorState)?.getText().length === 0 &&
        getCurrentBlockType(editorState) !== 'unstyled'
      ) {
        return 'backspace-block';
      }
    }

    return getDefaultKeyBinding(e);
  };

  const styleItems = {
    UNDERLINE: {
      open: function open() {
        return '<span style="text-decoration: underline">';
      },

      close: function close() {
        return '</span>';
      },
    },
    ITALIC: {
      open: function open() {
        return '*';
      },

      close: function close() {
        return '*';
      },
    },
  };

  const editorRef = useRef<Editor>(null);

  const customStyles = {
    CODE: {
      backgroundColor: '#292d3e',
      color: '#c27f69',
      border: '1px solid #c27f69',
      borderRadius: '3px',
      padding: '5px',
      fontSize: '12px',
      fontFamily: 'monospace',
      letterSpacing: '0.02em',
    },
  };
  return (
    <Box
      w="100%"
      background="#fbfbfb"
      zIndex="202"
      onFocus={onFocus}
      onBlur={onBlur}
      position="relative"
      borderWidth="1px"
      borderRadius="8px"
      borderColor={focussed ? colors.gray[200] : colors.gray[100]}
      pt="4px"
      pb="4px"
      onClick={() => {
        if (!hasFocus && editorRef.current) {
          editorRef.current.focus();
          setEditorState(moveSelectionToEnd(editorState));
        }
      }}
      className={!hasFocus ? 'blurred' : ''}
    >
      <Toolbar
        editorState={editorState}
        setEditorState={setEditorState}
        messageInput={messageInput}
        fileAttachments={fileAttachments}
        onAddFileAttachment={onAddFileAttachment}
        onSendMessage={onSendMessageHandler}
        hasFocus={hasFocus}
        codeSnippetEnabled={codeSnippetEnabled}
        setCodeSnippetEnabled={(value) => setCodeSnippetEnabled(value)}
      />
      <Editor
        onChange={(_editorState) => {
          setEditorState(_editorState);
          const content = _editorState.getCurrentContent();
          const rawObject = convertToRaw(content);

          const markdownString = draftToMarkdown(rawObject, {
            preserveNewlines: true,
            escapeMarkdownCharacters: true,
            styleItems,
          });
          return setMessageInput(markdownString);
        }}
        customStyleMap={customStyles}
        editorState={editorState}
        onFocus={handleFocus}
        blockStyleFn={blockStyle}
        onBlur={() => setCodeSnippetEnabled(false)}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
        ref={editorRef}
      />
    </Box>
  );
};

export default DesktopInput;
