import React, { useState, useEffect, useCallback } from 'react';
import { format, formatDistanceStrict } from 'date-fns';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { ProcessedMessage } from 'dashboard/models/Message';
import { FirestoreTimestamp } from 'dashboard/services/firebase';

import { Box, ScaleFade } from '@chakra-ui/react';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';
import BackButton from 'dashboard/components/Backbutton';
import LoadingBox from 'shared/components/LoadingBox';
import { convertStatusToMessage } from 'event-log/convertStatusToMessage';
import { useGetTokenEventLogs } from 'dashboard/hooks/useGetTokenEventLogs';

import Linkify from 'react-linkify';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import MapTracing from '../MapTracing';
import { Marker } from '../MapTracing/types';
import EventLogStatus from '../EventLogStatus';

interface ActivityViewProps {
  isMobile?: boolean;
  onNavigateBack: () => void;
  selectedMessage: ProcessedMessage;
}

/**
 * A functional component which renders an activity list view for a given locator token. Includes
 * event data such as the action, date initiated, user, location and ip.
 *
 * @param onNavigateBack - `() => void`; a function utilized to navigate back to the conversation security panel view
 * @param selectedMessage - `ProcessedMessage`; the message associated with the locator token. This also contains the relevant locator token.
 * @returns `React.FC`
 */
const ActivityView: React.FC<ActivityViewProps> = ({
  isMobile = false,
  onNavigateBack,
  selectedMessage,
}) => {
  const { userId } = useSelector((state: RootState) => ({
    userId: state.user.currentUser.id,
  }));

  const [originPoint, setOriginPoint] = useState<Marker | null>(null);
  const [destinationPoints, setDestinationPoints] = useState<Marker[] | null>(
    null
  );

  const [eventLogStatus, setEventLogStatus] = useState<number>();
  const { logs } = useGetTokenEventLogs(selectedMessage.locatorToken);
  const timeFromNow = (time: FirestoreTimestamp) => {
    const timestampToDate = new Date(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );

    return formatDistanceStrict(timestampToDate, new Date(), {
      addSuffix: true,
    });
  };

  const getOriginPoint = useCallback((): {
    _originPoint: Marker | null;
    _destinationPoints: Marker[];
  } => {
    if (!logs.length) return { _originPoint: null, _destinationPoints: [] };

    const log = logs.find((_log) => _log.action === 'val://addpacket/chat');

    const _destinationPoints: Marker[] = [];
    const ipExists: Record<string, boolean> = {};

    logs.forEach((_log) => {
      if (!ipExists[_log.ip]) {
        ipExists[_log.ip] = true;
        _destinationPoints.push({
          highestThreat: _log.threat,
          ip: _log.ip,
          originIp: log?.ip === _log.ip ? _log.ip : undefined,
          longitude: _log.long,
          latitude: _log.lat,
        });
      }
    });

    const _originPoint = log
      ? {
          highestThreat: log.threat,
          ip: log.ip,
          originIp: log.ip,
          longitude: log.long,
          latitude: log.lat,
        }
      : null;

    return {
      _originPoint,
      _destinationPoints,
    };
  }, [logs]);

  useEffect(() => {
    if (logs && logs.length) {
      const { threat } = logs.reduce((acc, prev) => {
        // eslint-disable-next-line
        acc.threat = Math.max(acc.threat, prev.threat);
        return acc;
      });

      setEventLogStatus(threat);

      const { _originPoint, _destinationPoints } = getOriginPoint();

      setOriginPoint(_originPoint);
      setDestinationPoints(_destinationPoints);
    }
  }, [logs, getOriginPoint, selectedMessage, userId]);

  const renderList = () => {
    if (!(logs || []).length) {
      return (
        <ScaleFade in style={styles.loadingContainer}>
          <LoadingBox text="Loading events" />
        </ScaleFade>
      );
    }

    return logs.map((event) => {
      return (
        <ScaleFade in key={event.id}>
          <div style={styles.listItem}>
            <EventLogStatus eventLogStatus={event.threat} />

            <div style={styles.listItemDetailContainer}>
              <Linkify
                componentDecorator={(_decoratedHref, decoratedText, key) => (
                  <span
                    key={key}
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {decoratedText}
                  </span>
                )}
              >
                <div style={styles.activityDetailText}>
                  {convertStatusToMessage(event, true)}
                </div>
              </Linkify>
              <span style={styles.activityDetailText}>
                {format(event.created, 'h:mm:ss a')}
              </span>
              <span style={styles.activityDetailText}>{event.location}</span>
              <span style={styles.activityDetailText}>{event.ip}</span>
            </div>
          </div>
        </ScaleFade>
      );
    });
  };
  return (
    <div style={{ ...styles.mainContainer, padding: isMobile ? 20 : 0 }}>
      <div style={styles.topNavBar}>
        <BackButton onClick={onNavigateBack} />
        <span style={styles.headerTitle}>Message Security</span>
      </div>
      <div style={styles.headerContainer}>
        <div style={styles.headerDetailContainer}>
          <div style={{ display: 'flex' }}>
            <DefaultAvatar
              src={selectedMessage.sender?.avatar}
              name={selectedMessage.sender?.name}
            />
            <div style={styles.userAndDate}>
              <span style={{ ...styles.activityDetailText, fontSize: '1rem' }}>
                {selectedMessage.sender.name}
              </span>
              <span style={styles.activityDetailText}>
                Created {timeFromNow(selectedMessage.date)}
              </span>
            </div>
          </div>
          {eventLogStatus && <EventLogStatus eventLogStatus={eventLogStatus} />}
        </div>
      </div>

      {originPoint &&
        destinationPoints &&
        selectedMessage.sender.id === userId && (
          <Box px={2} mb="10px">
            <MapTracing
              originPoint={logs.length ? originPoint : null}
              destinationPoints={logs.length ? destinationPoints : []}
            />
          </Box>
        )}

      <div style={styles.centerContainer}>
        <span style={styles.title}>Activity</span>
        <div style={styles.listContainer}>{renderList()}</div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  headerTitle: {
    color: '#0082ff',
    fontFamily: 'Avenir',
    fontSize: '1.3rem',
    fontWeight: 500,
    marginLeft: 15,
  },
  topNavBar: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  headerContainer: {
    flex: 0.2,
    width: '100%',
    marginBottom: 10,
    padding: '10px 0 ',
  },
  headerDetailContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  userAndDate: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
  },
  centerContainer: {
    width: '100%',
  },
  title: {
    color: '#9b9b9b',
    fontFamily: 'Avenir',
    fontSize: '1.5rem',
    fontWeight: 500,
    paddingLeft: 10,
  },
  activityDetailText: {
    color: '#000',
    fontFamily: 'Avenir',
    fontSize: '.8rem',
    fontWeight: 500,
    letterSpacing: '-.24px',
  },
  listContainer: {
    marginTop: 10,
    padding: '10px 0 50px 0',
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 18,
  },
  listItemDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    width: '100%',
  },
});

export default ActivityView;
