import React from 'react';
import CodeError from '../components/CodeError';
import CodeSuccess from '../components/CodeSuccess';
import { useLoginWithLink } from '../components/useLoginWithLink';
import { Status } from '../types';

const SMSLoginResult: React.FC = () => {
  const { hasToken, status } = useLoginWithLink('sms');
  if (!hasToken) {
    return <CodeError />;
  }

  return <CodeSuccess invalid={status === Status.Invalid} />;
};

export default SMSLoginResult;
