import React from 'react';
import { Box, BoxProps, Heading, HeadingProps } from '@chakra-ui/react';

export const CenteredLayoutHeading: React.FC<HeadingProps> = (props) => {
  return <Heading as="h1" size="lg" mb={8} textAlign="center" {...props} />;
};

export const CenteredLayoutContent: React.FC<
  BoxProps & { noValidate?: boolean }
> = (props) => {
  return <Box width="100%" my="auto" px={6} {...props} />;
};

export const CenteredLayoutContentInner: React.FC<BoxProps> = (props) => {
  return <Box maxWidth="md" width="100%" mx="auto" {...props} />;
};

export const CenteredLayoutFooter: React.FC<BoxProps> = (props) => {
  return <Box mt="auto" pt={8} px={6} {...props} />;
};
