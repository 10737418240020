import React from 'react';

import { Avatar, AvatarBadge } from '@chakra-ui/react';
import { avatarGradients } from 'dashboard/models/Avatar';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import {} from '@chakra-ui/icons';

const isDefaultAvatar = (src = '') =>
  src.toString().includes('icon_avatar_chat');

/**
 * When processing users and their data (name, avatar, etc), the returned avatar source is l
 * ocally stored SVG. We use this fn to properly find the default avatarr source key to dynamically
 * set gradient backgrounds based on their set avatar
 */
const cleanAvatarSource = (src?: string) => {
  if (!src) {
    return 'icon_avatar_chat_0.svg';
  }
  if (src.includes('icon_avatar_chat_0')) {
    return 'icon_avatar_chat_0.svg';
  }
  if (src.includes('icon_avatar_chat_1')) {
    return 'icon_avatar_chat_1.svg';
  }
  if (src.includes('icon_avatar_chat_2')) {
    return 'icon_avatar_chat_2.svg';
  }
  if (src.includes('icon_avatar_chat_3')) {
    return 'icon_avatar_chat_3.svg';
  }
  if (src.includes('icon_avatar_chat_4')) {
    return 'icon_avatar_chat_4.svg';
  }
  if (src.includes('icon_avatar_chat_5')) {
    return 'icon_avatar_chat_5.svg';
  }

  return 'icon_avatar_chat_0.svg';
};

interface DefaultAvatarProps {
  isLoading?: boolean;
  name?: string;
  src: string;
  style?: Record<string, string | number>;
  number?: number;
  onClick?: () => void;
  isConversationAvatar?: boolean;
}

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({
  name,
  src,
  style,
  number,
  onClick,
  isConversationAvatar,
}) => {
  const renderAvatarBadge = () => {
    if (!number) {
      return null;
    }

    return (
      <AvatarBadge boxSize="2em" bg="white" style={styles.badge}>
        +{number}
      </AvatarBadge>
    );
  };

  const addGradient = () => {
    if (!src) {
      return {};
    }

    if (isDefaultAvatar(src)) {
      return avatarGradients[cleanAvatarSource(src)];
    }
    return { backgroundColor: 'white' };
  };

  // if avatar has not yet loaded, use basic white-background
  if (!src) {
    return (
      <div style={styles.mainContainer}>
        <div
          style={{
            ...styles.avatar,
            color: 'white',
            fontSize: 5,
            fontWeight: 600 as const,
            ...addGradient(),
            ...style,
          }}
        />
      </div>
    );
  }

  return (
    <div style={styles.mainContainer}>
      <Avatar
        onClick={() => (onClick ? onClick() : null)}
        name={isConversationAvatar ? name?.split(' ')[0] : name}
        src={typeof src !== 'string' || isDefaultAvatar(src) ? undefined : src}
        style={{
          ...styles.avatar,
          color: 'white',
          fontSize: 5,
          fontWeight: 600 as const,
          ...addGradient(),
          ...style,
        }}
      >
        {renderAvatarBadge()}
      </Avatar>
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    positiion: 'relative',
    userSelect: 'none',
  },
  avatar: {
    alignItems: 'center',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    fontSize: '1em',
    fontWeight: 600 as const,
    height: 45,
    justifyContent: 'center',
    width: 45,
  },
  badge: {
    fontSize: 12,
    fontWeight: 500,
    color: '#94979b',
  },
});

export default DefaultAvatar;
