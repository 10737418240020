import React from 'react';

import { User } from 'dashboard/models/User';
import { formatEmail } from 'shared/utils/stringFormatting';

import DefaultAvatar from 'dashboard/components/DefaultAvatar';

import './ProfileButton.scss';

interface ProfileButtonProps {
  currentUser: User;
  onClick: () => void;
}

/**
 * A functional component which renders the profile avatar within a container as a clickable button
 * @param currentUser - the `User`, the avatar will be used from this `User`.
 * @param onClick - the action which takes place upon click of the `ProfileButton`
 */

const ProfileButton: React.FC<ProfileButtonProps> = ({
  currentUser,
  onClick,
}) => (
  <button
    type="button"
    tabIndex={0}
    onClick={currentUser.avatar ? onClick : () => null}
    className={`profile-button ${currentUser.avatar ? 'active' : ''}`}
  >
    <DefaultAvatar
      src={currentUser.avatar}
      name={currentUser.name || formatEmail(currentUser.email)}
      style={{ borderRadius: 5, width: 48, height: 48 }}
    />
  </button>
);

export default ProfileButton;
