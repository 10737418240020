import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface ConversationNameProps {
  value: string;
  text: string;
}

const ConversationName: React.FC<ConversationNameProps> = ({ value, text }) => {
  return (
    <Flex alignSelf="center">
      <div>
        <Flex justifyContent="center" p={3}>
          <Text
            fontSize="16px"
            lineHeight="16px"
            letterSpacing="0.02em"
            fontFamily="proxima-nova"
            color="#a6a6a6"
          >{`${text} "${value}".`}</Text>
        </Flex>
      </div>
    </Flex>
  );
};

export default ConversationName;
