import React, { createContext, useContext } from 'react';

export interface CurrentPageContext {
  content: Map<string, string>;
  setPage: (pageId: string, path: string) => void;
  setEvent: (pageEventId: string, eventId: string) => void;
  getCurrentPage: () => string;
  isCurrentPage: () => boolean;
  mkCurrentPage: () => string;
  getCurrentPageEvent: (eventId: string) => string;
  isCurrentPageEvent: (eventId: string) => boolean;
  mkCurrentPageEvent: (eventId: string) => string;
  remove: (key: string) => void;
  getAny: (pattern: string) => string[];
  removeAny: (pattern: string) => void;
}

export const globalMap = new Map();

const pageMap: CurrentPageContext = {
  content: globalMap,
  getCurrentPage(): string {
    return globalMap.get(`current-page:${window.location.pathname}`);
  },
  getCurrentPageEvent(eventId: string): string {
    return globalMap.get(
      `current-page:${this.getCurrentPage()}:current-event:${eventId}`
    );
  },
  isCurrentPage(): boolean {
    return window.location.pathname === this.getCurrentPage();
  },
  isCurrentPageEvent(eventId: string): boolean {
    return eventId === this.getCurrentPageEvent(eventId);
  },
  mkCurrentPage(): string {
    this.removeAny('current-page:.*');
    this.setPage(
      `current-page:${window.location.pathname}`,
      window.location.pathname
    );
    return window.location.pathname;
  },
  mkCurrentPageEvent(eventId: string): string {
    this.removeAny(`current-page:${this.getCurrentPage()}:current-event:.*`);
    this.setEvent(
      `current-page:${this.getCurrentPage()}:current-event:${eventId}`,
      eventId
    );
    return eventId;
  },
  setPage(key: string, value: string): void {
    // console.warn(`setting ${key}`);
    globalMap.set(key, value);
  },
  setEvent(key: string, value: string): void {
    // console.warn(`setting ${key}`);
    globalMap.set(key, value);
  },
  remove(key: string): void {
    globalMap.delete(key);
  },
  getAny(pattern: string): string[] {
    const expression = new RegExp(pattern);
    const items: Array<string> = [];
    Array.from(globalMap.keys()).forEach((key) => {
      if (key.match(expression)) {
        items.push(globalMap.get(key));
      }
    });
    return items;
  },
  removeAny(pattern: string): void {
    const expression = new RegExp(pattern);
    Array.from(globalMap.keys()).forEach((key) => {
      if (key.match(expression)) {
        // console.warn(`unsetting ${key}`);
        globalMap.delete(key);
      }
    });
  },
};

// eslint-disable-next-line no-redeclare
const CurrentPageContext = createContext<CurrentPageContext>(pageMap);

export const CurrentPageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <CurrentPageContext.Provider value={pageMap}>
      {children}
    </CurrentPageContext.Provider>
  );
};
export function usePage() {
  return useContext(CurrentPageContext);
}
