import React from 'react';
import {
  Alert,
  AlertProps,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { ErrorResponse } from 'shared/utils/errors';

export interface FormErrorAlertProps extends AlertProps {
  error?: ErrorResponse;
}

/**
 * A custom alert to display form error messages that are not for a specific field.
 */
const FormErrorAlert: React.FC<FormErrorAlertProps> = ({ error, ...props }) => {
  if (!error) {
    return null;
  }

  return (
    <Alert
      status="error"
      variant="subtle"
      alignItems="flex-start"
      borderRadius="md"
      p={4}
      mb={4}
      {...props}
    >
      <AlertIcon boxSize={6} ml={0} mr={3} />
      <AlertDescription>{error.message}</AlertDescription>
    </Alert>
  );
};

export default FormErrorAlert;
