import { EventLogItem } from 'event-log/types';

export enum EventThreatLevel {
  Benign = 0,
  Info,
  Warn,
  Alert,
  Critical,
}

export type EventThreatGroup = 'unknown' | 'good' | 'warn' | 'threat';

// Group multiple EventThreatLevel under a descriptive label
export const eventThreatLevelsByGroup: Record<
  EventThreatGroup,
  EventThreatLevel[]
> = {
  unknown: [EventThreatLevel.Benign],
  good: [EventThreatLevel.Info],
  warn: [EventThreatLevel.Warn],
  threat: [EventThreatLevel.Alert, EventThreatLevel.Critical],
};

// Look up threat labels by EventThreatLevel
export const eventThreatGroupsByLevel: Record<
  EventThreatLevel,
  EventThreatGroup
> = Object.entries(eventThreatLevelsByGroup).reduce(
  (accGroups, [group, levels]) => {
    return {
      ...accGroups,
      ...levels.reduce((accLevels, level) => {
        return {
          ...accLevels,
          [level]: group,
        };
      }, {}),
    };
  },
  {
    [EventThreatLevel.Benign]: 'unknown',
    [EventThreatLevel.Info]: 'unknown',
    [EventThreatLevel.Warn]: 'unknown',
    [EventThreatLevel.Alert]: 'unknown',
    [EventThreatLevel.Critical]: 'unknown',
  }
);

export type EventLog = {
  data: EventLogItem[] | null;
  total: number;
  loading: boolean;
  error?: Error;
  loadMore: () => void;
  hasData: boolean;
  isInitialLoading: boolean;
};

export const convertStatusToMessage = (
  event: EventLogItem | null,
  enableFullDetail: boolean
) => {
  if (!event) {
    return '';
  }

  const currentStatus = event.action;
  const eventUser = event.user || 'User';

  switch (currentStatus) {
    case 'sub://updatepassword':
      return enableFullDetail
        ? 'Your password was updated'
        : 'Your password was updated';
    case 'sub://updatepasswordapi':
      return enableFullDetail
        ? 'Your password was updated'
        : 'Your password was updated';
    case 'sub://validatesubscriber':
      return enableFullDetail
        ? 'A new chat member has been authorized to view this message'
        : 'Chat member added';
    case 'val://fetchkey':
      return enableFullDetail
        ? `Successfully read a message addressed to you.`
        : "Everything's OK";
    case 'val://fetchkey/sender':
      return enableFullDetail
        ? 'Your message has been read by an authorized recipient.'
        : `Everything's OK`;
    case 'val://fetchkey/badrecipients':
      return enableFullDetail
        ? `You were blocked from accessing this key because you are not an authorized recipient.`
        : 'Suspicious access attempt';
    case 'val://fetchkey/sender/badrecipients':
      return enableFullDetail
        ? `An unauthorized user requested access to a key you own. The request was blocked.`
        : 'Suspicious access attempt';
    case 'val://fetchkey/locked':
      return enableFullDetail
        ? `The user ${eventUser} has attempted to read a temporarily locked a message; a locked message cannot be accessed by anyone (an alternative to permanent revocation)`
        : 'This message is temporarliy unavailable';
    case 'val://fetchkey/sender/locked':
      return enableFullDetail
        ? `The user ${eventUser} has attempted to read your temporarily locked a message; a locked message cannot be accessed by anyone (an alternative to permanent revocation)`
        : 'This message is temporarliy unavailable';
    case 'val://fetchkey/expired':
      return enableFullDetail
        ? `The user ${eventUser} attempted to retrieve a key for an expired message. The request was blocked.`
        : 'Someone tried to read this expried thread.';
    case 'val://fetchkey/sender/expired':
      return enableFullDetail
        ? 'You attempted to retrieve a key for an expired message. The request was blocked.'
        : 'You tried to read an expired thread';
    case 'val://fetchkey/unusual':
      return enableFullDetail
        ? 'We have detected an IP that they have never connected from before (looks through logs for that user and reports)'
        : 'Suspicious access location attempt';
    case 'val://fetchkey/sender/unusual':
      return enableFullDetail
        ? 'We have detected an IP that you have never connected from before (looks through logs for that user and reports)'
        : 'Suspicious access location attempt';
    case 'val://addpacket':
      return enableFullDetail
        ? 'An encryption key was created to encrypt a packet of data.'
        : `Everything's OK.`;
    case 'val://addpacket/readers':
      return enableFullDetail
        ? 'You were added as an authorized recipient of this message.'
        : `Everything's OK.`;
    case 'val://addpacket/file':
      return enableFullDetail
        ? 'An encryption key was created to encrypt a file.'
        : `Everything's OK.`;
    case 'val://addpacket/msg':
      return enableFullDetail
        ? 'An encryption key was created to encrypt a message.'
        : `Everything's OK.`;
    case 'val://addpacket/data':
      return enableFullDetail
        ? 'An encryption key was created to encrypt a packet of data.'
        : `Everything's OK.`;
    case 'val://addpacket/unknown':
      return enableFullDetail
        ? 'An encryption key was created to encrypt a packet of data.'
        : `Everything's OK.`;
    case 'val://addpacket/failed':
      return enableFullDetail
        ? 'There was a local error that prevented an encryption key from being created.'
        : `Something went wrong. We'll try again.`;
    case 'val://addpacket/spoof':
      return enableFullDetail
        ? `The user ${eventUser} submitting a payload to the validation server is different than the user in the payload.`
        : `Suspicious activity alert`;
    case 'val://revoke':
      return enableFullDetail
        ? `Access to this message has been revoked.`
        : 'This data has been revoked';
    case 'saas://updatesubscription/failed':
      return enableFullDetail
        ? 'There was a failure to add an authorized user or change the policy for a key (expiration data, trusted IP ranges, etc).'
        : `We couldn't added everyone to the chat`;
    case 'saas://client/encryptedmail/failed':
      return enableFullDetail
        ? 'There was an issue sending the message.'
        : 'Your message failed to send. Please try again.';
    case 'val://revokeuseraccess/failed':
      return enableFullDetail
        ? 'We couldnt revoke this data. Please try again.'
        : 'Key revocation failed, please try again';
    case 'val://addpacket/chat':
      return `${eventUser} has sent an encrypted message.`;
    default:
      return `An event using the ${event.action} endpoint has been logged`;
  }
};
