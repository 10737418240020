import React, { useEffect, useState } from 'react';
import { BusinessOverview } from 'backend/api-types/dashboard';

import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { ProcessedMessage } from 'dashboard/models/Message';

import ConversationSection from 'dashboard/features/Sidebar/components/ConversationSection';
import SearchBar from 'dashboard/features/Sidebar/components/SearchBar';
import { EventLogItem } from 'event-log/types';
import { convertStatusToMessage } from 'event-log/convertStatusToMessage';
import ActivityView from './ActivityView';

interface SecurityPanelProps {
  conversationList: ProcessedConversation[];
  currentUser: User;
  eventLogs: EventLogItem[];
  onNavigateBack: () => void;

  searchInput: string;
  selectedMessageViaLocatorToken?: ProcessedMessage;
  setSearchInput: (input: string) => void;
  workspacesList: BusinessOverview[];
  currentWorkspaceId: string;
}

const SecurityPanel: React.FC<SecurityPanelProps> = ({
  conversationList,
  currentUser,
  eventLogs,
  onNavigateBack,
  searchInput,
  selectedMessageViaLocatorToken,
  setSearchInput,
  workspacesList,
  currentWorkspaceId,
}) => {
  const [eventsList, setEventsList] = useState<EventLogItem[]>([]);

  useEffect(() => {
    if (eventLogs) {
      // ensure all events are relevant to our current conversationList contents
      const updated = eventLogs.filter((event) => {
        const eventConversationId = event.meta.conversationId || '';
        const conversationIdList: string[] = conversationList.map(
          (conversation) => conversation.id
        );

        return conversationIdList.includes(eventConversationId);
      });

      setEventsList(updated);
    }
  }, [conversationList, eventLogs]);

  const renderExternalConversationSection = () => {
    const updatedConversationList = conversationList
      .filter(
        (conversation) => Number.parseInt(conversation.workspaceId, 10) === 0
      )
      .map((conversation) => {
        const selectedConversationEventLogs = eventsList.filter(
          (event) => event.meta.conversationId === conversation.id
        );
        const selectedConversationThreatLevel =
          selectedConversationEventLogs.map((event) => event.threat);

        const eventLogStatus = selectedConversationThreatLevel.length
          ? Math.max(...selectedConversationThreatLevel)
          : 0;

        const event =
          (eventLogs &&
            eventLogs.find(
              (eventItem) => eventItem.meta.conversationId === conversation.id
            )) ||
          null;

        return {
          ...conversation,
          eventLogStatus,
          eventStatusMessage: convertStatusToMessage(event, false),
        };
      });

    return (
      <ConversationSection
        conversationList={updatedConversationList}
        currentUser={currentUser}
        headerTitle="External"
        isSecurityMode
        searchInput={searchInput}
        currentWorkspaceId={currentWorkspaceId}
      />
    );
  };

  const renderWorkspaceConversationSections = () =>
    workspacesList
      .filter(
        (workspace: { id: number; name: string }) =>
          conversationList.filter(
            (conversation) =>
              conversation.workspaceId === workspace.id.toString()
          ).length
      )
      .sort(
        (a: { id: number; name: string }, b: { id: number; name: string }) =>
          a.name.localeCompare(b.name)
      )
      .map((workspace: { id: number; name: string }) => {
        const updatedConversationList = conversationList
          .filter(
            (conversation) =>
              conversation.workspaceId === workspace.id.toString()
          )
          .map((conversation) => {
            const selectedConversationEventLogs = eventsList.filter(
              (event) => event.meta.conversationId === conversation.id
            );

            const selectedConversationThreatLevel =
              selectedConversationEventLogs.map((event) => event.threat);

            const eventLogStatus = selectedConversationThreatLevel.length
              ? Math.max(...selectedConversationThreatLevel)
              : 0;

            const event =
              (eventLogs &&
                eventLogs.find(
                  (eventItem) =>
                    eventItem.meta.conversationId === conversation.id
                )) ||
              null;

            return {
              ...conversation,
              eventLogStatus,
              eventStatusMessage: convertStatusToMessage(event, false),
            };
          });

        return (
          <ConversationSection
            conversationList={updatedConversationList}
            currentUser={currentUser}
            headerTitle={workspace.name}
            isSecurityMode
            key={workspace.id}
            searchInput={searchInput}
            currentWorkspaceId={currentWorkspaceId}
          />
        );
      });

  const renderBody = () => {
    if (selectedMessageViaLocatorToken) {
      return (
        <ActivityView
          onNavigateBack={onNavigateBack}
          selectedMessage={selectedMessageViaLocatorToken}
        />
      );
    }

    return (
      <>
        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
        {renderExternalConversationSection()}
        {renderWorkspaceConversationSections()}
      </>
    );
  };

  return <div style={styles.mainContainer}>{renderBody()}</div>;
};

const styles = {
  mainContainer: { height: '100%' },
};

export default SecurityPanel;
