import React from 'react';
import { Heading } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface SectionHeaderProps {
  title: string;
  children?: React.ReactNode;
  isListCollapsed?: boolean;
  onClick?: () => void;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  children,
  isListCollapsed,
  onClick,
}) => {
  const renderChevronIcon = () => {
    if (!onClick) {
      return null;
    }

    return isListCollapsed ? (
      <ChevronRightIcon boxSize={6} style={{ marginRight: 7 }} />
    ) : (
      <ChevronDownIcon boxSize={6} style={{ marginRight: 7 }} />
    );
  };

  return (
    <div style={styles.mainContainer}>
      <Heading style={styles.header} onClick={onClick}>
        {renderChevronIcon()}
        {title}
      </Heading>
      {children}
    </div>
  );
};

const styles = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row' as const,
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 20,
    padding: '0 8px 0 8px',
  },
  header: {
    color: '#9b9b9b',
    fontSize: 20,
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none' as const,
    marginRight: 10,
  },
};

export default SectionHeader;
