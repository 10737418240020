import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

import useDeviceType from 'dashboard/hooks/useDeviceType';
import DashboardRoutes from './dashboard/routes/DashboardRoutes';
import routes from './routes';
import AuthRoutes from './auth/AuthRoutes';

const App: React.FC = () => {
  const uaCode = process.env.REACT_APP_UA_CODE;
  ReactGA.initialize(uaCode ?? '');
  ReactGA.ga('create', {
    trackingId: uaCode,
    cookieDomain: 'auto',
    name: 'clientTracker',
  });

  useDeviceType();

  return (
    <>
      {/* Default meta tags for the application */}
      <Helmet>
        <title>XQ Secure Chat</title>
        <meta
          name="description"
          content="The easiest way to communicate securely"
        />
      </Helmet>

      <Switch>
        <Route
          path={[
            routes.auth.login,
            routes.auth.code,
            routes.auth.sms,
            routes.auth.guest,
          ]}
          component={AuthRoutes}
          exact
        />

        {/* Default route is the main application page */}
        <DashboardRoutes />
      </Switch>
    </>
  );
};

export default App;
