import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthenticationController from 'auth/AuthenticationController';

import { RootState } from 'store';
import {
  MobileViewOptions,
  setFeedbackMode,
  SidebarViewOptions,
} from 'store/reducers/application';
import { User } from 'dashboard/models/User';

import { DeviceType } from 'dashboard/models/Device';
import { ProcessedConversation } from 'dashboard/models/Conversation';

import { Box, Flex } from '@chakra-ui/react';

import { avatarOptions } from 'dashboard/models/Avatar';
import Profile from './components/ProfilePanel';
import SecurityPanel from './components/SecurityPanel';
import ConversationPanel from './components/ConversationPanel';
import ComposeConversationButton from './components/ComposeConversationButton';
import FeedbackFormButton from './components/FeedbackFormButton.tsx';

import '../../scss/main.scss';
import '../Thread/components/MessageRowItem/MessageRowItem.scss';
import './Sidebar.scss';
import ConversationSettings from './components/ConversationSettings';
import Footer from './components/Footer';

interface SidebarProps {
  onActivateComposeMode: () => void;
  onResetSidebarView: () => void;
}

export const getConversationName = (
  otherRecipientUsers: User[],
  selectedConversation?: ProcessedConversation
) => {
  if (selectedConversation?.name) {
    return selectedConversation.name;
  }

  if (otherRecipientUsers.length > 1) {
    return (
      otherRecipientUsers
        .map((user: { name: string; email: string }) =>
          user.name ? user.name : user.email
        )
        .join(', ')
        .substring(0, 30) + '...'
    );
  }

  const onlyRecipientUser = otherRecipientUsers[0];

  return onlyRecipientUser.name || onlyRecipientUser.email.length > 30
    ? onlyRecipientUser.email.substring(0, 30) + '...'
    : onlyRecipientUser.email;
};

const Sidebar: React.FC<SidebarProps> = ({
  onActivateComposeMode,
  onResetSidebarView,
}) => {
  const {
    application: { currentDeviceType, currentView, isFeedbackMode },
    conversations: {
      conversationList,
      eventLogs,
      selectedMessage,
      selectedConversation,
    },
    user: {
      currentUser,
      currentWorkspaceId,
      workspacesList,
      selectedUserProfile,
      contactsList,
    },
    messages: { latestMessageId },
  } = useSelector((state: RootState) => ({
    application: state.application,
    conversations: state.conversations,
    user: state.user,
    messages: state.messages,
  }));

  const history = useHistory();

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState<string>('');

  // This hook fetches contacts and finds the associated registered users, if present.
  useEffect(() => {
    // all Contacts, exluding the current user.
    const filteredContacts: string[] = [];

    const _assignFilteredContacts = contactsList
      ?.filter((user: { email: string }) => user.email !== currentUser.email)
      .forEach((contact) => filteredContacts.push(contact.email));
  }, [contactsList, currentUser.email, currentUser.id]);

  const onLogOut = () => {
    AuthenticationController.logout();
    history.push('/login');
  };

  const otherRecipientUsers = selectedConversation.recipients.filter(
    (user: { id: string; name: string; email: string }) =>
      user.id !== currentUser.id
  );

  const renderBody = () => {
    switch (currentView.desktop.sidebar) {
      case SidebarViewOptions.CONVERSATION_LIST: {
        return (
          <ConversationPanel
            conversationList={conversationList}
            currentUser={currentUser}
            currentWorkspaceId={currentWorkspaceId}
            selectedConversation={selectedConversation}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            workspacesList={workspacesList}
          />
        );
      }

      case SidebarViewOptions.CONVERSATION_SETTINGS:
        return (
          <ConversationSettings
            conversation={selectedConversation}
            latestMessageId={latestMessageId}
            messageExpiryTime={
              selectedConversation.messageExpiryTimeList.length
                ? selectedConversation.messageExpiryTimeList[
                    selectedConversation.messageExpiryTimeList.length - 1
                  ].value
                : 24
            }
            avatar={
              selectedConversation.avatar ||
              avatarOptions[selectedConversation.avatar]
            }
            name={getConversationName(
              otherRecipientUsers,
              selectedConversation
            )}
            currentUser={currentUser}
            onNavigateBack={onResetSidebarView}
            recipients={selectedConversation.recipients}
          />
        );

      case SidebarViewOptions.PROFILE: {
        return (
          <Profile
            selectedUserProfile={selectedUserProfile}
            currentUser={currentUser}
            onNavigateBack={onResetSidebarView}
            profileUser={selectedUserProfile}
            onLogOut={onLogOut}
          />
        );
      }

      case SidebarViewOptions.SECURITY: {
        return (
          <SecurityPanel
            conversationList={conversationList}
            currentUser={currentUser}
            eventLogs={eventLogs}
            onNavigateBack={onResetSidebarView}
            searchInput={searchInput}
            selectedMessageViaLocatorToken={selectedMessage}
            setSearchInput={setSearchInput}
            workspacesList={workspacesList}
            currentWorkspaceId={currentWorkspaceId}
          />
        );
      }
    }
  };

  if (currentDeviceType === DeviceType.MOBILE) {
    return (
      <Box
        className="scrollbar-without-transition"
        id="sidebar-container"
        width="100%"
      >
        <Flex
          justifyContent="center"
          pt={currentView.mobile === MobileViewOptions.PROFILE ? '0px' : '5em'}
          height={currentView.mobile === MobileViewOptions.PROFILE ? '95%' : ''}
        >
          {renderBody()}
        </Flex>

        {currentView.mobile !== MobileViewOptions.CONVERSATION_SETTINGS && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="5rem"
            padding="0 1.5rem"
            position="fixed"
            bottom={0}
            left={0}
            bg="white"
          >
            <FeedbackFormButton
              onClick={() => dispatch(setFeedbackMode(!isFeedbackMode))}
            />
            <ComposeConversationButton onClick={onActivateComposeMode} />
          </Flex>
        )}
      </Box>
    );
  }

  return (
    <Box
      transform="translateZ(0)"
      overflow="hidden"
      width="30em"
      boxShadow="0px 0px 40px -20px rgba(1, 28, 53, 0.45)"
    >
      <Box className="scrollbar-without-transition" id="sidebar-container">
        {renderBody()}
        <ComposeConversationButton onClick={onActivateComposeMode} />

        {currentDeviceType === DeviceType.DESKTOP && <Footer />}
      </Box>
    </Box>
  );
};

export default Sidebar;
