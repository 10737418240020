import avatarOne from 'assets/svg/icons/icon_avatar_chat_1.svg';
import avatarTwo from 'assets/svg/icons/icon_avatar_chat_2.svg';
import avatarThree from 'assets/svg/icons/icon_avatar_chat_3.svg';
import avatarFour from 'assets/svg/icons/icon_avatar_chat_4.svg';
import avatarFive from 'assets/svg/icons/icon_avatar_chat_5.svg';

type DefaultAvatars =
  | 'icon_avatar_chat_0.svg'
  | 'icon_avatar_chat_1.svg'
  | 'icon_avatar_chat_2.svg'
  | 'icon_avatar_chat_3.svg'
  | 'icon_avatar_chat_4.svg'
  | 'icon_avatar_chat_5.svg';

type AvatarOptions = {
  [index in DefaultAvatars | string]: string;
};

/**
 * A Map of default avatar options
 */
export const avatarOptions: AvatarOptions = {
  'icon_avatar_chat_0.svg': avatarOne,
  'icon_avatar_chat_1.svg': avatarOne,
  'icon_avatar_chat_2.svg': avatarTwo,
  'icon_avatar_chat_3.svg': avatarThree,
  'icon_avatar_chat_4.svg': avatarFour,
  'icon_avatar_chat_5.svg': avatarFive,
};

type AvatarGradients = {
  [index in DefaultAvatars | string]: Record<string, string>;
};

export const avatarGradients: AvatarGradients = {
  'icon_avatar_chat_0.svg': {
    // purple
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(161,16,255,1) 100%)',
  },
  // fuchsia
  'icon_avatar_chat_1.svg': {
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(255,0,218,1) 100%)',
  },
  // dark blue
  'icon_avatar_chat_2.svg': {
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(0, 5, 69, 1) 100%)',
  },
  // lime
  'icon_avatar_chat_3.svg': {
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(0,255,68,1) 100%)',
  },
  // orange
  'icon_avatar_chat_4.svg': {
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(255,179,0,1) 100%)',
  },
  // purple
  'icon_avatar_chat_5.svg': {
    background:
      'linear-gradient(90deg, rgba(0,130,255,1) 0%, rgba(161,16,255,1) 100%)',
  },
};
