import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import store from 'store';
import { onCreateConversation } from 'store/thunks/conversations/onCreateConversation';
import { userSliceState } from 'store/reducers/user';
import { WorkspaceOptions } from 'dashboard/models/Contact';

export type InviteNewUserFormValues = {
  email: string;
  phone: string;
  recipients: string;
};

const schema = yup.object().shape({
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  recipients: yup.string().nullable(),
});

const defaultValues: InviteNewUserFormValues = {
  email: '',
  phone: '',
  recipients: '',
};

// filter out any duplicates from recipients hash
const buildUniqueRecipients = (
  formData: { recipients: string },
  user: userSliceState
): string[] => {
  const uniqueRecipients: Set<string> = new Set();

  JSON.parse(formData.recipients).forEach((recipient: WorkspaceOptions) =>
    uniqueRecipients.add(recipient.value)
  );

  return [...uniqueRecipients, user.currentUser.email];
};

const determineWorkspaceId = (formData: { recipients: string }): string => {
  const recipients: WorkspaceOptions[] = JSON.parse(formData.recipients);

  const comparator = recipients[0].bid;

  const result = recipients.every((recipient) => recipient.bid === comparator);

  // if all recipient business ID's match the first business ID then set workspace ID to the comparator else it is an external workspace
  return result ? String(comparator) : '0';
};

export default function useInviteNewUserForm(): FormHandler<InviteNewUserFormValues> {
  return useFormHandler<InviteNewUserFormValues>(
    async (formData) => {
      if (formData.recipients.length <= 0) return;
      const { user } = store.getState();
      const recipientEmailList = buildUniqueRecipients(formData, user);
      const workspaceId = determineWorkspaceId(formData);
      const { currentUser } = user;

      await store.dispatch(
        onCreateConversation({
          recipientEmailList,
          workspaceId,
          currentUser,
        })
      );
    },
    defaultValues,
    schema,
    true
  );
}
