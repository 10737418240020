import React from 'react';
import { Marker } from 'react-map-gl';
import { ReactComponent as OriginPoint } from 'assets/svg/active-origin-point.svg';
import { getColorFromThreat } from '../helpers';

interface OriginPointMarkerProps {
  longitude: number;
  latitude: number;
  threat: number;
}

const OriginPointMarker: React.FC<OriginPointMarkerProps> = ({
  threat,
  latitude,
  longitude,
}) => {
  return (
    <Marker longitude={longitude} latitude={latitude} capturePointerMove>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <OriginPoint
          style={{
            position: 'absolute',
          }}
          fill={getColorFromThreat(threat)}
          stroke={getColorFromThreat(threat)}
        />
      </div>
    </Marker>
  );
};

export default OriginPointMarker;
