import * as turf from '@turf/turf';
import { Feature } from '@turf/turf';

export const generateCurvedLine = (origin: number[], dest: number[]) => {
  let route: turf.LineString = {
    type: 'LineString',
    coordinates: origin[0] > dest[0] ? [origin, dest] : [dest, origin],
  };
  route = turf.projection.toWgs84(route);
  const routeFeature: Feature<turf.LineString> = {
    type: 'Feature',
    geometry: route,
    properties: {},
  };
  const lineD = turf.lineDistance(routeFeature, { units: 'kilometers' });
  const mp = turf.midpoint(route.coordinates[0], route.coordinates[1]);
  const center = turf.destination(
    mp,
    lineD,
    turf.bearing(route.coordinates[0], route.coordinates[1]) - 90
  );
  const lA = turf.lineArc(
    center,
    turf.distance(center, route.coordinates[0]),
    turf.bearing(center, route.coordinates[1]),
    turf.bearing(center, route.coordinates[0]),
    { steps: 1000 }
  );
  return turf.projection.toMercator(lA);
};
