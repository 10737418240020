import React from 'react';
import { Image, ImageProps } from '@chakra-ui/react';
import logo from './logo.png';
import logoWithText from './logo-with-text.svg';

export type LogoProps = ImageProps & {
  withText?: boolean;
};

const Logo: React.FC<LogoProps> = ({
  height = 50,
  withText = false,
  ...props
}) => {
  return (
    <Image
      alt="XQ Message"
      height={height}
      {...props}
      src={withText ? logoWithText : logo}
      ignoreFallback
    />
  );
};

export default Logo;
