import { useCallback, useEffect, useRef } from 'react';

export interface SignInOptions {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  url: string;
  name?: string;
  onCallback: (result: URLSearchParams) => void;
  onError?: () => void;
}

export default function useSignInWindow({
  width = 600,
  height = 700,
  top = 300,
  left = 300,
  url,
  name = 'Sign in',
  onCallback,
  onError = () => {
    // Do nothing
  },
}: SignInOptions) {
  const windowRef = useRef<Window | null>(null);
  // Keep a reference to the handler so it can be removed later
  const handlerRef = useRef((e: MessageEvent) => {
    if (
      !e.isTrusted ||
      !e.data ||
      typeof e.data !== 'object' ||
      !e.data.authRedirect ||
      !e.data.hash
    ) {
      // Ignore any events that we aren't expecting. Keep waiting for the one that we are.
      return;
    }

    window.removeEventListener('message', handlerRef.current, false);
    if (windowRef.current) {
      windowRef.current.close();
    }
    windowRef.current = null;
    onCallback(new URLSearchParams(e.data.hash.substr(1)));
  });

  // One last cleanup before leaving in case the window is left open
  useEffect(() => {
    const handler = handlerRef.current;
    return () => {
      window.removeEventListener('message', handler, false);
    };
  }, []);
  const features = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;

  return useCallback(() => {
    // If the ref exists, the window is already open then close it and remove the event listener
    if (windowRef.current) {
      windowRef.current.close();
      window.removeEventListener('message', handlerRef.current, false);
    }

    windowRef.current = window.open(url, name, features);
    if (windowRef.current) {
      window.addEventListener('message', handlerRef.current, false);
    } else {
      // Popup is blocked in this case
      onError();
    }
  }, [url, name, onError, features]);
}
