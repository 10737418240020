import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import AuthenticationController from 'auth/AuthenticationController';
import routes from 'routes';

import { FileResult } from 'dashboard/features/Thread';
import { User } from 'dashboard/models/User';

import ProfileView from 'dashboard/features/Sidebar/components/ProfilePanel/ProfileView';
import BackButton from 'dashboard/components/Backbutton';
import { saveEditedProfile } from 'dashboard/services/user/saveEditedProfile';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'store/reducers/user';
import useIsMounted from 'dashboard/hooks/useIsMounted';
import isEmpty from 'lodash/isEmpty';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

interface ProfileProps {
  currentUser: User;
  selectedUserProfile: User;
  onLogOut: () => void;
  onNavigateBack: () => void;
  profileUser: User;
}

const Profile: React.FC<ProfileProps> = ({
  currentUser,
  selectedUserProfile,
  onLogOut,
  onNavigateBack,
  profileUser,
}) => {
  const mounted = useIsMounted();

  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const currentUserProfile = profileUser || currentUser;

  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(
    currentUserProfile.phone
  );
  const [updatedAvatar, setUpdatedAvatar] = useState({} as FileResult);
  const [updatedScreenName, setUpdatedScreenName] = useState(
    selectedUserProfile.name
  );

  const [updatedNotificationSetting, setUpdateNotificationSetting] = useState(
    currentUserProfile.settings.notificationsEnabled
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowDimensions.width]);

  useEffect(() => {
    if (currentUser === selectedUserProfile) {
      setUpdatedScreenName(currentUser.name);
    }
    setUpdatedScreenName(selectedUserProfile.name);
  }, [currentUser, selectedUserProfile]);

  const handleAuthenticationClick = useCallback(() => {
    if (AuthenticationController.data?.sub) {
      localStorage.removeItem('workId');
      return onLogOut();
    }

    history.push(routes.auth.login);
  }, [history, onLogOut]);
  const openFileDialog = () => {
    inputRef.current?.click();
  };

  const onUpdateAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = e.target as HTMLInputElement;
    const fileList = eventTarget.files as FileList;
    for (const targetFile of fileList) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (listener) => {
        const isViewableAttachment = targetFile.type.includes('image/');
        const listenerTarget = listener.target as FileReader;

        if (!isViewableAttachment) {
          return;
        }

        const fileResult = {
          name: targetFile.name,
          file: targetFile,
          canView: isViewableAttachment,
          preview: listenerTarget.result as string,
        };
        setUpdatedAvatar(fileResult);
      });
      fileReader.readAsDataURL(targetFile);
    }
  };

  const handleSubmit = useCallback(async () => {
    const noUpdatesMade =
      isEmpty(updatedAvatar) &&
      currentUser.name === updatedScreenName &&
      currentUser.phone === updatedPhoneNumber &&
      currentUser.settings.notificationsEnabled === updatedNotificationSetting;

    if (noUpdatesMade || selectedUserProfile !== currentUser) {
      return;
    }

    dispatch(
      setCurrentUser({
        ...currentUser,
        avatar: updatedAvatar.preview || currentUser.avatar,
        name: updatedScreenName,
        settings: {
          ...currentUser.settings,
          notificationsEnabled: updatedNotificationSetting,
        },
      })
    );

    await saveEditedProfile(
      updatedAvatar || currentUser.avatar,
      updatedScreenName,
      updatedPhoneNumber,
      updatedNotificationSetting,
      currentUser
    );
  }, [
    currentUser,
    selectedUserProfile,
    dispatch,
    updatedAvatar,
    updatedNotificationSetting,
    updatedPhoneNumber,
    updatedScreenName,
  ]);

  useEffect(() => {
    // update redux state before unmounting
    return () => {
      if (!mounted()) {
        handleSubmit();
      }
    };
  }, [mounted, handleSubmit]);

  const onBackPress = () => {
    handleSubmit();
    return onNavigateBack();
  };

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex alignItems="center" width="100%">
        <Box alignSelf="flex-start">
          <BackButton onClick={onBackPress} />
        </Box>
        {currentUser.id === selectedUserProfile.id && (
          <Text
            width="100%"
            textAlign="center"
            fontFamily="proxima-nova"
            fontWeight={400}
            fontSize="16px"
            lineHeight="16px"
            letterSpacing="0.02em"
            marginRight="40px"
          >
            Profile Settings
          </Text>
        )}
      </Flex>

      <ProfileView
        avatar={updatedAvatar.preview || currentUserProfile.avatar}
        email={currentUserProfile.email}
        name={updatedScreenName}
        isCurrentUser={currentUser.id === selectedUserProfile.id}
        onSignout={handleAuthenticationClick}
        onSetPhoneNumber={(e) => setUpdatedPhoneNumber(e.target.value)}
        onSetScreenName={(e) => setUpdatedScreenName(e.target.value)}
        onSetNotificationSettings={() =>
          setUpdateNotificationSetting(!updatedNotificationSetting)
        }
        onUpdateAvatar={openFileDialog}
        phone={updatedPhoneNumber}
        notificationSetting={updatedNotificationSetting}
      />
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUpdateAvatar(e)}
      />
    </Flex>
  );
};

export default Profile;
