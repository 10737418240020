import React, { useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ErrorResponse } from 'shared/utils/errors';
import { CodeLoginFormValues } from './useCodeValidatorLoginForm';

export default function CodeValidatorInputField({
  isMobile,
  name,
  form,
  responseError,
}: {
  isMobile: boolean;
  name: 'code';
  form: UseFormMethods<CodeLoginFormValues>;
  responseError: ErrorResponse | undefined;
  onSubmit: (e?: React.FormEvent<Element> | undefined) => Promise<void>;
}) {
  const [focussed, setFocussed] = useState(true);
  const [isLargerThan425] = useMediaQuery('(min-width: 425px)');
  const value = form.watch(name);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDown = (e: any) => {
      // Allow user to use backspace if boxes are focussed and they haven't input more than 6 digits
      if (focussed && e.key === 'Backspace' && value.length >= 0) {
        form.setValue(name, value.slice(0, -1));
      }
      const reg = /^\d+$/;
      // Allow user to type code if boxes are focussed and they haven't input more than 6 digits
      if (focussed && reg.test(e.key) && value.length < 6) {
        const newValue = value + e.key;
        form.setValue(name, newValue);
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, focussed]);

  const codeArr = Array(6).fill(null);

  const hiddenMobileInput = React.useRef<HTMLInputElement>(null);

  const handleDisplayKeyboardMobile = () => {
    if (hiddenMobileInput.current) {
      hiddenMobileInput.current.focus();
    }
  };

  // @ts-ignore ignore key index
  const fieldError = name && form.errors[name];
  const isInvalid = !!responseError || !!fieldError;

  // handles unfocusing boxes when other inputs are selected
  const handleBlur = () => {
    if (focussed) {
      setFocussed(false);
    }
  };

  const handleFocus = () => {
    if (!focussed) {
      setFocussed(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    // Handle autocomplete for 'one-time-code' on mobile. Safari pastes autocomplete codes one at a time and Chrome all at once.
    if (target.value?.length === 6) {
      return form.setValue(name, target.value);
    }
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel mt={isMobile ? '0px' : '8px'}>Verification Code</FormLabel>
      <Controller
        name={name}
        control={form.control}
        render={() => (
          <Flex
            maxW="447px"
            w="100%"
            flexDirection="row"
            justifyContent="space-between"
            mt={isMobile ? '0px' : '10px'}
            onClick={handleDisplayKeyboardMobile}
          >
            {/* This hidden input field is used to display the keyboard on mobile and as a proxy for handling onFocus events */}
            <input
              autoComplete="one-time-code"
              type="tel"
              onChange={handleOnChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              maxLength={6}
              style={{
                width: '20px',
                height: '1px',
                position: 'absolute',
                left: '-999999px',
              }}
              ref={hiddenMobileInput}
            />
            {codeArr.map((_, i) => (
              <>
                <Flex
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  border="1px solid hsla(229, 11%, 38%, 0.7)"
                  borderRadius="6px"
                  backgroundColor="white"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  margin={isMobile ? 'calc(17px/2)' : 'calc(27px/2)'}
                  mb="0"
                  mt="0"
                  w="100%"
                  maxWidth="48px"
                  _after={{
                    content: '""',
                    display: 'block',
                    paddingBottom: '100%',
                  }}
                >
                  <Text fontSize={isLargerThan425 ? '24px' : '20px'}>
                    {value[i]}
                  </Text>
                </Flex>
              </>
            ))}
          </Flex>
        )}
      />
      {isInvalid && (
        <FormErrorMessage>
          {fieldError?.message || responseError?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
