import {
  isConversationDisabled,
  isReadyToDelete,
} from 'dashboard/features/Sidebar/utils/conversationFilter';
import useSoundNotification from 'dashboard/features/Thread/hooks/useSoundNotification';
import { avatarOptions } from 'dashboard/models/Avatar';
import {
  ConversationDocument,
  ConversationState,
  ProcessedConversation,
} from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { streamConversations } from 'dashboard/services/chat';

import {
  fetchUser,
  FirestoreDocumentSnapshot,
} from 'dashboard/services/firebase';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setConversationList } from 'store/reducers/conversations';
import { AnyAction } from 'redux';
import { onDeleteConversation } from 'store/thunks/conversations/onDeleteConversation';
import { ThunkDispatch } from '@reduxjs/toolkit';

const checkDisabledAndUpdate = (conversation: ConversationDocument) => {
  const conversationDisabled = isConversationDisabled(conversation);

  return conversationDisabled
    ? ConversationState.EXPIRED
    : ConversationState.ACTIVE;
};

/**
 * A function utilized to process a `ConversationDocument` from firestore and produce a `ProcessedConversation`.
 * The `ProcessedConversation` adds fetched recipient information (avatar, name, etc.), as well as conversation state.
 * @param conversationDocument
 * @returns
 */
export const processConversation = async (
  conversationDocument: ConversationDocument
): Promise<ProcessedConversation> => {
  const processedrecipientList = await Promise.all(
    conversationDocument.recipients.map(async (id) => {
      const fetchedUser = await fetchUser(id);
      const userAvatar = avatarOptions[fetchedUser.avatar]
        ? avatarOptions[fetchedUser.avatar]
        : fetchedUser.avatar;

      return {
        ...fetchedUser,
        avatar: userAvatar,
      };
    })
  );

  return {
    ...conversationDocument,
    recipients: processedrecipientList,
    state: checkDisabledAndUpdate(conversationDocument),
  };
};

const conversationObserver = async (
  querySnapshot: FirestoreDocumentSnapshot,
  currentUser: User,
  handleSoundNotification: () => void,
  setIsInit: (arg0: boolean) => void,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  const selectedConversationQuery = [];
  const conversationDocumentList = querySnapshot.docs.map((doc) =>
    doc.data()
  ) as ConversationDocument[];

  selectedConversationQuery.push(conversationDocumentList);
  if (selectedConversationQuery[0].length === conversationDocumentList.length) {
    const processConversations = conversationDocumentList
      .map((conversationDocument: ConversationDocument) => {
        const conversationReadyForDeletion =
          isReadyToDelete(conversationDocument);

        if (conversationReadyForDeletion) {
          dispatch(
            onDeleteConversation({
              conversationId: conversationDocument.id,
              isFullDeletion: true,
            })
          );
        }
        return conversationDocument;
      })
      .map((conversationDocument: ConversationDocument) =>
        processConversation(conversationDocument)
      );

    const processedConversationList = await Promise.all(processConversations);

    // detect new messages for audio notification trigger
    const recentlyUpdatedConversations = querySnapshot
      .docChanges()
      .filter((change) => change.type === 'modified');

    if (recentlyUpdatedConversations.length) {
      recentlyUpdatedConversations.forEach((change) => {
        const conversation = change.doc.data() as ConversationDocument;
        if (
          conversation.notifications[currentUser.id] > 0 &&
          currentUser.settings.notificationsEnabled
        ) {
          handleSoundNotification();
        }
      });
    }

    const currentWorkspaceConversationList = processedConversationList.filter(
      (conversation: ProcessedConversation) =>
        !conversation.isHidden[currentUser.id]?.now
    );

    dispatch(setConversationList(currentWorkspaceConversationList));
    setIsInit(true);
  }
};

/**
 * A hook which manages the fetch operation of conversations by which the user is subscribed to
 * @param currentUser - User document
 * @returns [conversationList: ProcessedConversation[], isInit: boolean]
 */
const useInitializeConversations = (currentUser: User): [boolean] => {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [handleSoundNotification] = useSoundNotification();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.id) {
      const unsubscribeConversationStream = streamConversations(
        'desc',
        currentUser.id,
        {
          next: async (querySnapshot) => {
            await conversationObserver(
              querySnapshot,
              currentUser,
              handleSoundNotification,
              setIsInit,
              dispatch
            );
          },
        }
      );
      return () => {
        unsubscribeConversationStream();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return [isInit];
};

export default useInitializeConversations;
