import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setSelectedConversationSettings } from 'store/reducers/conversations';
import { FileResult } from 'store/reducers/messages';
import {
  ConversationName,
  MessageExpiryTime,
} from 'dashboard/models/Conversation';
import { isEmpty } from 'lodash';
import { updateConversation } from 'dashboard/services/chat/updateConversation';

/**
 * a thunk utilized to update a conversation
 *
 * @param conversationId: string
 * @param avatar: FileResult
 * @param messageExpiryTime: MessageExpiryTime
 * @param name: string
 * @returns void
 */
export const onUpdateConversation = createAsyncThunk<
  void,
  {
    conversationId: string;
    avatar?: FileResult;
    messageExpiryTime?: MessageExpiryTime;
    conversationName?: ConversationName;
  }
>(
  'conversations/update',
  async (
    { conversationId, avatar, messageExpiryTime, conversationName },
    { dispatch, getState }
  ) => {
    const {
      conversations: { selectedConversation },
    } = getState() as RootState;

    const updatedSettings: {
      avatar?: FileResult;
      messageExpiryTime?: MessageExpiryTime;
      conversationName?: ConversationName;
      name?: string;
    } = {};

    if (!isEmpty(avatar)) {
      updatedSettings.avatar = avatar;
    }

    if (
      selectedConversation.name !== conversationName?.value &&
      conversationName?.value?.length
    ) {
      updatedSettings.conversationName = conversationName;
      updatedSettings.name = conversationName.value;
    }

    if (messageExpiryTime) {
      updatedSettings.messageExpiryTime = messageExpiryTime;
    }

    dispatch(
      setSelectedConversationSettings({
        ...updatedSettings,
        avatar: updatedSettings.avatar
          ? updatedSettings.avatar.preview
          : selectedConversation.avatar,
      })
    );

    return updateConversation({
      conversationId,
      ...updatedSettings,
    });
  }
);
