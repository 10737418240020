import React from 'react';
import SVG from 'react-inlinesvg';

interface SVGComponentProps {
  url: string;
  onClick: () => void;
  style: Record<string, string | number>;
}

const SVGComponent: React.FC<SVGComponentProps> = ({ url, onClick, style }) => {
  return <SVG src={url} style={style} height="auto" onClick={onClick} />;
};

export default SVGComponent;
