import * as React from 'react';
import LoadingBox from 'shared/components/LoadingBox';

type LoadingEntities = 'application' | 'conversation';

const capitalizeString = (string: string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();

const LoadingScreen: React.FC<{ text?: LoadingEntities }> = ({ text }) => {
  const loadingText = text ? `Loading ${capitalizeString(text)}` : '';
  return (
    <div style={styles.mainContainer}>
      <LoadingBox text={loadingText} />
    </div>
  );
};

const styles = {
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100%',
  },
};

export default LoadingScreen;
