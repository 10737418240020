/**
 * A function utilized to create a guest invitation message w/ a provided link
 * @param link - the url the guest user will utilize
 * @returns `string`
 */
export const generateGuestInvitationMessage = (link: string) => {
  const invitationMessage =
    process.env.REACT_APP_ENV === 'dev'
      ? link
      : `Let’s chat! You can join this secure conversation as a guest using this one-time link: ${link} \n\nFor extra security, this conversation link will expire in 24 hours.`;

  return invitationMessage;
};
