/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Box, Button, Input, Switch } from '@chakra-ui/react';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';

import cameraIcon from 'assets/svg/icon-camera.svg';
import logoutIcon from 'assets/svg/icon-logout.svg';

interface ProfileViewProps {
  avatar: string;
  email: string;
  name: string;
  notificationSetting: boolean;
  isCurrentUser: boolean;

  onSignout: () => void;
  onSetNotificationSettings: (event: any) => void;
  onSetPhoneNumber: (event: any) => void;
  onSetScreenName: (event: any) => void;
  onUpdateAvatar: (event: any) => void;
  phone: string;
}

const ProfileView: React.FC<ProfileViewProps> = ({
  avatar,
  email,
  name,
  notificationSetting,
  isCurrentUser,
  onSignout,
  onSetScreenName,
  onSetNotificationSettings,
  onUpdateAvatar,
}) => {
  const [showIcon, setShowIcon] = useState(false);

  return (
    <Box width="100%" padding="0 20px">
      <div style={styles.headerSectionContainer}>
        <div style={{ position: 'relative' }}>
          <DefaultAvatar
            src={avatar}
            name={name}
            style={{ width: 110, height: 110, zIndex: 1, borderRadius: 55 }}
          />

          {isCurrentUser && (
            <Box
              style={{
                position: 'absolute',
                backgroundColor: showIcon ? 'rgba(0,0,0,.25)' : 'transparent',
                width: 110,
                height: 110,
                borderRadius: 55,
                top: 0,
                zIndex: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              role="button"
              onClick={onUpdateAvatar}
              onKeyDown={() => null}
              tabIndex={0}
              onMouseEnter={() => setShowIcon(true)}
              onMouseLeave={() => setShowIcon(false)}
            >
              {showIcon && (
                <img
                  alt="camera-icon"
                  src={cameraIcon}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              )}
            </Box>
          )}
        </div>
      </div>
      <div style={styles.inputSectionContainer}>
        <div style={styles.spacingForProfile}>
          <div style={styles.inputItemContainer}>
            {((!isCurrentUser && name) || isCurrentUser) && (
              <div style={styles.inputTitle}>Screen Name</div>
            )}
            {isCurrentUser ? (
              <Input
                value={name}
                variant="filled"
                onChange={onSetScreenName}
                size="lg"
                border=".5px solid #0082FF"
                style={{
                  ...styles.inputText,
                  border: '.5px solid #0082FF',
                  backgroundColor: 'white',
                }}
              />
            ) : (
              <Box
                style={{
                  color: '#101b4f',
                  fontWeight: 600,
                  overflowWrap: 'break-word',
                  fontSize: 18,
                }}
              >
                {name}
              </Box>
            )}
          </div>

          <div style={styles.inputItemContainer}>
            <div style={styles.inputTitle}>Contact</div>
            <div style={styles.inputText}>{email}</div>
          </div>

          {isCurrentUser && (
            <div style={styles.inputItemContainer}>
              <div style={styles.inputTitle}>Enable Sounds</div>

              <Switch
                aria-label="enable sounds switch"
                size="lg"
                style={{ marginTop: 5, marginLeft: 4 }}
                isChecked={notificationSetting}
                onChange={onSetNotificationSettings}
              />
            </div>
          )}
        </div>
      </div>
      {isCurrentUser && (
        <Button
          alignSelf="center"
          variant="outline"
          colorScheme="red"
          onClick={onSignout}
          style={styles.signoutButton}
        >
          <img
            alt="logout-icon"
            src={logoutIcon}
            style={{
              width: 15,
              height: 15,
              marginRight: 8,
            }}
          />
          Logout
        </Button>
      )}
    </Box>
  );
};

const styles = {
  headerSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '25px',
    minHeight: 150,
  },
  inputSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: '20px 0',
    minHeight: 150,
  },
  inputItemContainer: {
    marginBottom: 12,
  },
  inputTitle: {
    color: '#94979b',
    fontFamily: 'proxima-nova',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '10px',
  },
  inputText: {
    color: '#000',
    fontFamily: 'proxima-nova',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  spacingForProfile: {
    marginTop: 10,
    width: '100%',
  },
  signoutButton: {
    border: 'solid 2px rgba(255, 0, 0, .9)',
    color: 'rgba(255, 0, 0, .9)',
    fontSize: 12,
    padding: 10,
    width: '100%',
  },
};
export default ProfileView;
