import { FileAttachment } from 'dashboard/models/Message';

import {
  FileResult,
  setFileUploadBytesTransferred,
} from 'store/reducers/messages';
import { Dispatch } from 'redux';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { encryptFile } from './XQSDK';
import { FIREBASE_STORAGE } from './firebase';

const fetchEncryptedFileAttachment = async (
  sourecFile: File,
  encryptedFileName: string,
  fileType: string
) => {
  const fileAttachmentUrl = await FIREBASE_STORAGE.ref('messages')
    .child(`${encryptedFileName}`)
    .getDownloadURL();

  const fileAttachment: FileAttachment = {
    url: fileAttachmentUrl,
    type: fileType,
    name: sourecFile.name,
    size: sourecFile.size,
  };

  return fileAttachment;
};

export const encryptAndUploadFiles = async (
  fileAttachments: FileResult[],
  recipients: string[],
  fileUploadBytesTransferred: number,
  dispatch: Dispatch,
  selectedConversation: ProcessedConversation
) => {
  const messageExpiryTime =
    selectedConversation.messageExpiryTimeList[
      selectedConversation.messageExpiryTimeList.length - 1
    ].value;

  const files = fileAttachments.map(async ({ file: sourceFile }) => {
    const encryptedFile = await encryptFile(
      sourceFile,
      recipients,
      messageExpiryTime
    );

    if (!(encryptedFile instanceof File)) {
      throw new Error('There was an issue encrypting the supplied file');
    }

    return new Promise((resolve, _reject) => {
      const uploadTask = FIREBASE_STORAGE.ref(
        `/messages/${sourceFile.name}`
      ).put(encryptedFile);

      return uploadTask.on(
        'state_changed',
        (snapshot) => {
          dispatch(
            setFileUploadBytesTransferred(
              fileUploadBytesTransferred + snapshot.bytesTransferred
            )
          );
        },
        console.error,
        async () => {
          const encryptedFileAttachment = await fetchEncryptedFileAttachment(
            encryptedFile,
            sourceFile.name,
            sourceFile.type
          );

          resolve(encryptedFileAttachment);
        }
      );
    });
  });

  return files as Promise<FileAttachment>[];
};
