import { createSlice } from '@reduxjs/toolkit';
import { BusinessOverview, ContactSummary } from 'backend/api-types/dashboard';
import { baseUserDocument, User } from 'dashboard/models/User';

export interface userSliceState {
  accessToken: string;
  currentUser: User;
  currentWorkspaceId: string;
  selectedUserProfile: User;
  workspacesList: BusinessOverview[];
  contactsList: ContactSummary[];
}

const initialState = () =>
  ({
    accessToken: '',
    currentUser: baseUserDocument,
    currentWorkspaceId: '',
    selectedUserProfile: baseUserDocument,
    workspacesList: [],
    contactsList: [],
  } as userSliceState);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    setAccessToken(state, action) {
      const accessToken = action.payload;
      state.accessToken = accessToken;
    },
    setCurrentUser(state, action) {
      const user = action.payload;
      state.currentUser = user;
    },
    setCurrentWorkspaceId(state, action) {
      const currentWorkspaceId = action.payload;
      state.currentWorkspaceId = currentWorkspaceId;
    },
    setSelectedUserProfile(state, action) {
      const selectedUserProfile = action.payload;
      state.selectedUserProfile = selectedUserProfile;
    },
    setWorkspacesList(state, action) {
      const workspacesList = action.payload;
      state.workspacesList = workspacesList;
    },
    setContactsList(state, action) {
      const contactsList = action.payload;
      state.contactsList = contactsList;
    },
    resetUserState: () => initialState(),
  },
});

export const {
  setAccessToken,
  setCurrentUser,
  setCurrentWorkspaceId,
  setSelectedUserProfile,
  setWorkspacesList,
  setContactsList,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
