import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Workspace, WorkspaceOptions } from 'dashboard/models/Contact';
import { CursorIndex, Toggle } from '../InviteNewUserForm';

export default function Dropdown({
  builtWorkspaces,
  handleClick,
  toggle,
  currentEmailValue,
  currentPhoneValue,
  isCreatable,
  isMobile,
  cursorIndex,
  dropdownRef,
}: {
  builtWorkspaces: Workspace;
  handleClick: (value: string, bid: number) => void;
  toggle: Toggle;
  currentEmailValue: string;
  currentPhoneValue: string;
  isCreatable: () => boolean;
  isMobile: boolean;
  cursorIndex: CursorIndex;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownRef: any;
}) {
  const renderCreateButton = (currentValue: string) => (
    <Text
      fontFamily="proxima-nova"
      fontWeight="400"
      fontSize="15px"
      color="black"
      paddingTop="6px"
      paddingBottom="12px"
      pl="12px"
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'rgba(149, 202, 255, 0.7)',
      }}
      onClick={() => handleClick(currentValue, 0)}
    >
      Create &quot;{currentValue}&quot;
    </Text>
  );

  return (
    <Box
      position="absolute"
      bgColor="white"
      maxW="444px"
      w="100%"
      maxH={isMobile ? '130px' : '200px'}
      zIndex="20000"
      borderBottomRightRadius="6px"
      borderBottomLeftRadius="6px"
      boxShadow="0 5px 5px 0 rgba(16, 27, 79, 0.15)"
      top="80px"
      pt="8px"
      overflowY="scroll"
      className="scrollbar-without-transition"
      ref={dropdownRef}
    >
      {toggle === Toggle.email &&
        currentEmailValue.length > 0 &&
        isCreatable() &&
        renderCreateButton(currentEmailValue)}
      {toggle === Toggle.phone &&
        currentPhoneValue.length > 0 &&
        isCreatable() &&
        renderCreateButton(currentPhoneValue)}
      {Object.keys(builtWorkspaces).length > 0 &&
        Object.values(builtWorkspaces).map(
          (workspace, workspaceIndex: number) => (
            <Box pb="12px" key={workspace.label}>
              <Text
                fontFamily="proxima-nova"
                fontWeight="400"
                fontSize="15px"
                color="hsl(220, 4%, 69%)"
                paddingBottom="12px"
                pl="12px"
              >
                {workspace.label}
              </Text>
              {workspace.options.map(
                (option: WorkspaceOptions, optionsIndex: number) => (
                  <Text
                    key={option.value}
                    pl="12px"
                    fontFamily="proxima-nova"
                    fontWeight="400"
                    fontSize="18px"
                    paddingBottom="12px"
                    onClick={() => handleClick(option.value, option.bid)}
                    backgroundColor={
                      cursorIndex.workspaceIndex === workspaceIndex &&
                      cursorIndex.optionsIndex === optionsIndex
                        ? 'rgba(149, 202, 255, 0.7)'
                        : 'transparent'
                    }
                    // TODO: implement hover back
                    _hover={{
                      cursor: 'pointer',
                      backgroundColor: 'rgba(149, 202, 255, 0.3)',
                    }}
                  >
                    {option.value}
                  </Text>
                )
              )}
            </Box>
          )
        )}

      {Object.keys(builtWorkspaces).length <= 0 && !isCreatable() && (
        <Flex
          justifyContent="center"
          alignItems="center"
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'rgba(149, 202, 255, 0.7)',
          }}
        >
          <Text
            fontFamily="proxima-nova"
            fontWeight="400"
            fontSize="15px"
            color="hsl(220, 4%, 69%)"
            paddingTop="6px"
            paddingBottom="12px"
          >
            No options
          </Text>
        </Flex>
      )}
    </Box>
  );
}
