import React, { useEffect, useState } from 'react';

import { CircularProgress, IconButton } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import goodAlert from 'assets/svg/good-alert.svg';
import warningAlert from 'assets/svg/warning-alert.svg';
import issueAlert from 'assets/svg/issue-alert.svg';

interface EventLogStatusProps {
  eventLogStatus?: number;
}

const EventLogStatus: React.FC<EventLogStatusProps> = ({ eventLogStatus }) => {
  const [current, setCurrent] = useState<number>(-1);

  useEffect(() => {
    if (eventLogStatus) {
      setCurrent(eventLogStatus);
    }

    // stops waiting for now, until eventLogStatus is updated again up the component tree
    if (!eventLogStatus) {
      setTimeout(() => {
        setCurrent(0);
      }, 5000);
    }
  }, [eventLogStatus]);

  if (current === -1) {
    return (
      <CircularProgress
        isIndeterminate
        size="25px"
        style={{ paddingRight: 10, paddingTop: 10 }}
        color="#0082ff"
      />
    );
  }
  const determineStatusIcon = () => {
    switch (current) {
      case 0:
        return goodAlert;
      case 1:
        return goodAlert;
      case 2:
        return warningAlert;
      case 3:
        return issueAlert;
      default:
        return goodAlert;
    }
  };
  return (
    <IconButton
      aria-label="back-button"
      colorScheme="transparent"
      isRound
      size="sm"
      style={{ ...styles.iconButton }}
    >
      <img
        aria-label="event-log-status-icon"
        style={styles.icon}
        src={determineStatusIcon()}
      />
    </IconButton>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    height: 40,
    width: 40,
  },
  icon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
});

export default EventLogStatus;
