import { BusinessOverview, ContactSummary } from 'backend/api-types/dashboard';
import { Workspace, WorkspaceOptions } from 'dashboard/models/Contact';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { useMemo } from 'react';

interface useBuildWorkspacesProps {
  workspaces: BusinessOverview[];
  conversationList: ProcessedConversation[];
  contacts: ContactSummary[];
  currentUser: User;
}

const useBuildWorkspaces = ({
  workspaces,
  conversationList,
  // handleSetAvailableUsers,
  contacts,
  currentUser,
}: useBuildWorkspacesProps) => {
  // filter out current user and flatten multi nested array of recent users
  const recentUsers: WorkspaceOptions[] = useMemo(() => {
    const filteredConversationList = [] as WorkspaceOptions[];
    conversationList.map((c: ProcessedConversation) => {
      const recipient = c.recipients.filter(
        (user: User) => user.email !== currentUser.email
      );
      recipient.map((user: User) =>
        filteredConversationList.push({
          value: user.email,
          bid: parseInt(c.workspaceId, 10),
        })
      );
      return c;
    });
    return filteredConversationList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationList, currentUser.email]);

  // Build workspace data structure
  const buildWorkspace = useMemo(
    () =>
      workspaces.reduce((acc: { [key: number]: string }, workspace) => {
        acc[workspace.id] = workspace.name;
        return acc;
      }, {}),
    [workspaces]
  );

  const workspace: Workspace = useMemo(() => {
    const initialWorkspaceState =
      recentUsers.length > 0
        ? {
            0: {
              label: 'Recent users',
              options: [],
            },
          }
        : {};

    return contacts
      .filter((contact) => contact.state === 'Validated')
      .reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: Workspace | any, contact) => {
          // if contact is in a workspace and their email isn't the current user, append object
          if (
            contact.bid in buildWorkspace &&
            contact.email !== currentUser.email
          ) {
            // if business ID has already been added to the new object, add to existing entry and sort alphabetically
            if (contact.bid in acc) {
              acc[contact.bid] = {
                ...acc[contact.bid],
                options: [
                  ...acc[contact.bid].options,
                  {
                    bid: contact.bid,
                    value: contact.email,
                  },
                ],
                // Add back sort? makes it O(n^2) figure out a better way

                // .sort((a: WorkspaceOptions, b: WorkspaceOptions) =>
                //   a.value.localeCompare(b.value)
                // ),
              };
            } else {
              // if business ID hasn't been added to the new object, create new entry
              acc[contact.bid] = {
                label: buildWorkspace[contact.bid],
                options: [
                  {
                    bid: contact.bid,
                    value: contact.email,
                  },
                ],
              };
            }
          }
          return acc;
        },
        // Initial State
        initialWorkspaceState
      );
  }, [buildWorkspace, contacts, currentUser.email, recentUsers]);

  // Add recent users to workspaces
  const builtWorkspaces = useMemo(() => {
    if (!workspace[0]) return workspace;
    const set = new Set();
    // filter for only unique users
    const uniqueRecentUsers = recentUsers.filter((user) => {
      if (set.has(user.value)) {
        return false;
      }
      set.add(user.value);
      return true;
    });

    workspace[0].options = uniqueRecentUsers;
    return workspace;
  }, [recentUsers, workspace]);

  return builtWorkspaces;
};

export default useBuildWorkspaces;
