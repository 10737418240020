import { EditorState, Modifier } from 'draft-js';

export const insertText = (
  editorState: EditorState,
  characterToInsert: string
) => {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    'insert-characters'
  );

  return newEditorState;
};
