import React from 'react';

import { Textarea, Image, IconButton, Box } from '@chakra-ui/react';

import { FileResult } from 'dashboard/features/Thread';
import ResizeTextarea from 'react-textarea-autosize';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import fileLogo from 'assets/svg/icon-lock-file-inactive.svg';
import sendButtonActive from 'assets/svg/send-solid-blue.svg';
import sendButtonDisabled from 'assets/svg/send-solid-grey.svg';
import colors from 'theme/foundations/colors';

interface MobileInputProps {
  fileAttachments: FileResult[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputMessageRef: React.MutableRefObject<any>;
  inputPlaceholder: string;
  messageInput: string;
  onAddFileAttachment: () => void;
  onKeyDownSubmit: (event: React.KeyboardEvent) => void;
  onSendMessage: () => void;
  setMessageInput: (input: string) => void;
  focussed: boolean;
  onFocus: () => void;
  onBlur: () => void;
  handleFocus: () => void;
}

const MobileInput: React.FC<MobileInputProps> = ({
  fileAttachments,
  inputMessageRef,
  inputPlaceholder,
  messageInput,
  onAddFileAttachment,
  onKeyDownSubmit,
  onSendMessage,
  setMessageInput,
  focussed,
  onBlur,
  onFocus,
  handleFocus,
}) => {
  return (
    <Box
      w="100%"
      background="#fbfbfb"
      zIndex="202"
      onFocus={onFocus}
      onBlur={onBlur}
      position="relative"
      borderWidth="1px"
      borderRadius="8px"
      borderColor={focussed ? colors.gray[200] : colors.gray[100]}
      pr="4px"
      pt="4px"
      pb="4px"
    >
      <Textarea
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={ResizeTextarea as any}
        autoFocus
        onFocus={handleFocus}
        id="text-area"
        inputMode="text"
        maxRows={3}
        minH="unset"
        border="0"
        background="#fbfbfb"
        _hover={{ background: '#fbfbfb' }}
        minRows={1}
        onChange={(event) => setMessageInput(event.target.value)}
        onKeyDown={(event) => onKeyDownSubmit(event)}
        overflowY="auto"
        className="scrollbar-without-transition"
        paddingRight="70px"
        m="0"
        placeholder={
          inputPlaceholder.length > 23
            ? `${inputPlaceholder.slice(0, 23)}...`
            : inputPlaceholder
        }
        ref={inputMessageRef}
        resize="none"
        value={messageInput}
        variant="filled"
      />
      <IconButton
        onClick={onAddFileAttachment}
        aria-label="back-button"
        size="sm"
        style={styles.addFileAttachmentButton}
        colorScheme="transparent"
      >
        <Image objectFit="fill" alt="add-file-attachment" src={fileLogo} />
      </IconButton>
      <IconButton
        onClick={onSendMessage}
        aria-label="send-button"
        size="sm"
        style={styles.sendButton}
        colorScheme="transparent"
      >
        {messageInput.length || fileAttachments.length ? (
          <Image w="20px" alt="active-send-button" src={sendButtonActive} />
        ) : (
          <Image w="20px" alt="inactive-send-button" src={sendButtonDisabled} />
        )}
      </IconButton>
    </Box>
  );
};
const styles = StyleSheet.create({
  inputSectionBackground: {
    display: 'flex',
    justifyContent: 'center',
    // paddingTop: 5,
    paddingBottom: 20,
    width: '100%',
    alignSelf: 'flex-start',
    // backgroundColor: '#fbfbfb',
  },
  inputSectionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '16px',
    marginRight: '16px',
  },

  inputContainer: {},

  addFileAttachmentButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: '40px',
    transform: 'translate(0%, 50%)',
    bottom: '50%', // TODO: play around with this when iris's mockups for the message input redesign are finished
  },
  sendButton: {
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translate(0%, 50%)',
    right: '10px',
    bottom: '50%', // TODO: play around with this when iris's mockups for the message input redesign are finished
  },
});

export default MobileInput;
