import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AccessDenied from 'shared/components/AccessDenied';

export type RestrictedRouteProps = RouteProps & {
  canAccess?: boolean;
};

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  canAccess = false,
  ...props
}) => {
  return canAccess ? <Route {...props} /> : <AccessDenied />;
};

export default RestrictedRoute;
