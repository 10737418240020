import { User, UserModelFields } from 'dashboard/models/User';
import { FileResult } from 'dashboard/features/Thread';
import { updateUser } from '../auth';
import { FIREBASE_STORAGE } from '../firebase';

export const saveEditedProfile = async (
  updatedAvatar: FileResult,
  updatedName: string,
  updatedPhone: string,
  notificationsEnabled: boolean,
  currentUser: User
) => {
  const updated = {
    [UserModelFields.PHONE]: updatedPhone,
    [UserModelFields.NAME]: updatedName,
    [UserModelFields.AVATAR]: currentUser.avatar,
    [UserModelFields.SETTINGS]: {
      ...currentUser.settings,
      notificationsEnabled,
    },
  };

  if (updatedAvatar.file) {
    await FIREBASE_STORAGE.ref(`/users/${updatedAvatar.name}`).put(
      updatedAvatar.file
    );

    const fileAttachmentUrl = await FIREBASE_STORAGE.ref('users')
      .child(`${updatedAvatar.name}`)
      .getDownloadURL();

    updated[UserModelFields.AVATAR] = fileAttachmentUrl as string;
  }

  updateUser(updated, currentUser);
};
