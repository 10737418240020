import React, { useEffect, useMemo, useState } from 'react';

import {
  EncryptedMessageDocument,
  MessageState,
  ProcessedMessage,
} from 'dashboard/models/Message';
import { User } from 'dashboard/models/User';
import { Flex } from '@chakra-ui/react';

import MessageRowItem from 'dashboard/features/Thread/components/MessageRowItem/MessageRowItem';
import { convertStatusToMessage } from 'event-log/convertStatusToMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EventLogItem } from 'event-log/types';
import {
  ConversationName,
  MessageExpiryTime,
  ProcessedConversation,
} from 'dashboard/models/Conversation';

import './styles.scss';
import './MessageRowItem/MessageRowItem.scss';

import { isScrollbarVisible } from '../utils/getWindowDimensions';
import { isFireFox, isSafari } from '../utils/userAgents';
import MessageExpiry from './MessageRowItem/MessageExpiry';
import Name from './MessageRowItem/ConversationName';

interface MessageListProps {
  isLocatorTokenModeActive: boolean;
  messageListRef: React.MutableRefObject<HTMLDivElement>;
  currentUser: User;
  eventLogs: EventLogItem[];
  shouldLoadMoreMessages: boolean;
  shouldDisplayScrollToBottom: boolean;
  isMobile: boolean;
  messageExpiryTimeList: MessageExpiryTime[];
  conversationNameList?: ConversationName[];
  messageLoaders: EncryptedMessageDocument[];
  mobileHeight?: number;
  onSetCurrentLimit: () => void;
  onSelectMessageViaLocatorToken: (message: ProcessedMessage) => void;
  processedMessages: ProcessedMessage[];
  dismissScrollToBottom: () => void;
  handleScrollToBottom: () => void;
  conversation: ProcessedConversation;
}

const MessageList: React.FC<MessageListProps> = ({
  isLocatorTokenModeActive,
  messageListRef,
  currentUser,
  eventLogs,
  shouldLoadMoreMessages,
  isMobile,
  messageLoaders,
  messageExpiryTimeList,
  conversationNameList,
  onSetCurrentLimit,
  onSelectMessageViaLocatorToken,
  processedMessages,
  handleScrollToBottom,
  conversation,
}) => {
  const desktopOwnMessageMarginInitialState = isScrollbarVisible(messageListRef)
    ? '6px'
    : '12px';

  const [desktopOwnMessageMargin, setDesktopOwnMessageMargin] = useState(
    desktopOwnMessageMarginInitialState
  );

  useEffect(() => {
    if (isScrollbarVisible(messageListRef)) {
      let margin = '6px';
      if (isFireFox) {
        margin = '2px';
      }
      setDesktopOwnMessageMargin(margin);
    } else {
      setDesktopOwnMessageMargin('12px');
    }
    //
  }, [messageListRef]);

  const [messageExpandedList, setMessageExpandedList] = useState<
    Record<string, boolean>
  >({});

  const onExpandImage = (messageId: string) => {
    setMessageExpandedList({
      ...messageExpandedList,
      [messageId]: !messageExpandedList[messageId],
    });
  };

  const getMessagesWithExpiryUpdates = useMemo(() => {
    const totalMessages = [...processedMessages, ...messageLoaders];
    const messages: (
      | MessageExpiryTime
      | ProcessedMessage
      | EncryptedMessageDocument
      | ConversationName
    )[] = [];

    const inlineMessageList: Record<
      string,
      (MessageExpiryTime | ConversationName)[]
    > = {};

    // Any expiry changes before a message is sent
    messageExpiryTimeList.forEach((expiry) => {
      if (!expiry.messageId) return messages.push(expiry);

      if (inlineMessageList[expiry.messageId]) {
        inlineMessageList[expiry.messageId] = [
          ...inlineMessageList[expiry.messageId],
          expiry,
        ];
      }

      if (!inlineMessageList[expiry.messageId]) {
        inlineMessageList[expiry.messageId] = [expiry];
      }
    });

    if (conversationNameList?.length) {
      conversationNameList.forEach((name) => {
        if (!name.messageId) return messages.push(name);

        if (inlineMessageList[name.messageId]) {
          inlineMessageList[name.messageId] = [
            ...inlineMessageList[name.messageId],
            name,
          ];
        }

        if (!inlineMessageList[name.messageId]) {
          inlineMessageList[name.messageId] = [name];
        }
      });
    }

    for (const inlineMessageId in inlineMessageList) {
      if (inlineMessageList[inlineMessageId].length)
        inlineMessageList[inlineMessageId].sort((a, b) => {
          if (!a.date) return 0;
          if (!b.date) return 0;

          return a.date.toMillis() - b.date.toMillis();
        });
    }

    totalMessages.forEach((message) => {
      // Push message
      messages.push(message);

      const inlineMessage = inlineMessageList[message.id];

      if (inlineMessage && inlineMessage.length) {
        messages.push(...inlineMessage);
      }
    });
    return messages;
  }, [
    messageExpiryTimeList,
    messageLoaders,
    processedMessages,
    conversationNameList,
  ]);

  return (
    <Flex
      id="scrollableDiv"
      backgroundColor="#fbfbfb"
      flexDirection="column-reverse"
      width="100%"
      overflowY="auto"
      overflowX="hidden"
      ref={messageListRef}
      className={
        isMobile
          ? 'message-list-mobile'
          : `message-list ${isSafari ? 'safari' : ''}`
      }
    >
      <InfiniteScroll
        dataLength={getMessagesWithExpiryUpdates.length}
        next={onSetCurrentLimit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
        inverse
        hasMore={shouldLoadMoreMessages}
        loader={<div />}
        scrollableTarget="scrollableDiv"
      >
        {getMessagesWithExpiryUpdates.map(
          (
            message:
              | MessageExpiryTime
              | ProcessedMessage
              | EncryptedMessageDocument
              | ConversationName,
            i
          ) => {
            const event =
              (eventLogs &&
                eventLogs.find(
                  (eventItem) =>
                    eventItem.meta.messageId ===
                    (message as ProcessedMessage | EncryptedMessageDocument).id
                )) ||
              null;

            // If this is an expiry message
            if (typeof (message as MessageExpiryTime).value === 'number') {
              // If there are more messages to load but the only expiry message does not have a stub
              if (
                shouldLoadMoreMessages &&
                !(message as MessageExpiryTime).messageId
              ) {
                return null;
              }

              const user = conversation.recipients.find(
                (recipient) =>
                  (message as MessageExpiryTime).userId &&
                  recipient.id === (message as MessageExpiryTime).userId
              );

              const isUserAvailableText = user
                ? `${
                    user.name || user.email || user.phone
                  } has set the message expiry time to`
                : 'Message expiry time has been set to';

              const text =
                i === 0 ? `Message expiry time is set to` : isUserAvailableText;

              return (
                <MessageExpiry
                  text={text}
                  value={(message as MessageExpiryTime).value}
                />
              );
            }

            // If this is an conversation name message
            if (typeof (message as ConversationName).value === 'string') {
              // If there are more messages to load but the only converation name message does not have a stub
              if (
                shouldLoadMoreMessages &&
                !(message as ConversationName).messageId
              ) {
                return null;
              }

              const user = conversation.recipients.find(
                (recipient) =>
                  (message as ConversationName).userId &&
                  recipient.id === (message as ConversationName).userId
              );

              const text = user
                ? `${
                    user.name || user.email || user.phone
                  } has set the conversation name to`
                : 'Conversation name has been set to';

              return (
                <Name value={(message as ConversationName).value} text={text} />
              );
            }

            return (
              <MessageRowItem
                desktopOwnMessageMargin={desktopOwnMessageMargin}
                isLocatorTokenModeActive={isLocatorTokenModeActive}
                currentUserId={currentUser.id}
                isImageExpanded={
                  messageExpandedList[(message as ProcessedMessage).id]
                }
                isLoading={
                  (message as ProcessedMessage).state ===
                    MessageState.LOADING ||
                  (message as ProcessedMessage).state === MessageState.ACTIVE
                }
                isMobile={isMobile}
                key={(message as ProcessedMessage).id}
                message={message as ProcessedMessage}
                onExpandImage={onExpandImage}
                onSelectMessageViaLocatorToken={onSelectMessageViaLocatorToken}
                statusMessage={
                  convertStatusToMessage(event, false) ||
                  (message as ProcessedMessage).locatorToken
                }
                handleScrollToBottom={handleScrollToBottom}
                first={i + 1 === getMessagesWithExpiryUpdates.length}
              />
            );
          }
        )}
      </InfiniteScroll>
    </Flex>
  );
};

export default MessageList;
