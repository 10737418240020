import React, { useState } from 'react';
import CodeValidatorLogin from './CodeValidatorLogin';
import PhoneLogin from './PhoneLogin';

export enum Step {
  phone,
  verification,
}

export default function Phone({ isMobile }: { isMobile: boolean }) {
  const [step, setStep] = useState(Step.phone);
  return (
    <>
      {step === Step.phone && (
        <PhoneLogin isMobile={isMobile} setStep={setStep} />
      )}
      {step === Step.verification && <CodeValidatorLogin isMobile={isMobile} />}
    </>
  );
}
