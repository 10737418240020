import React from 'react';
import { Marker } from '../../types';
import DestinationPointMarker from '../DestinationPointMarker';

interface DestinationMarkersProps {
  destinationPoints: Marker[];
}

/**
 * This functional component is responsible for mapping over available `destinationPoints`, rendering
 * the destination point markers and corresponding trace-routes to the map.
 */
const DestinationMarkers: React.FC<DestinationMarkersProps> = ({
  destinationPoints,
}) => {
  const Markers = (destinationPoints || []).map((point: Marker) => {
    return (
      <DestinationPointMarker
        key={Math.random()}
        point={point}
        isOriginIp={point.ip === point.originIp}
      />
    );
  });

  return <>{Markers}</>;
};

export default DestinationMarkers;
