import React, { useState } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import copyImg from 'copy-image-clipboard';
import { formatDistanceToNow } from 'date-fns';
import { ReactComponent as TimeIcon } from 'assets/svg/time-icon.svg';
import {
  FileAttachment as FileAttachmentType,
  MessageState,
  ProcessedMessage,
} from 'dashboard/models/Message';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import iconError from 'assets/svg/icon-alert.svg';
import loadingDots from 'assets/svg/loading-dots.svg';

import './MessageRowItem.scss';

import { formatEmail } from 'shared/utils/stringFormatting';
import { FirestoreTimestamp } from 'dashboard/services/firebase';
import { copyTextToClipboard } from 'dashboard/utils/copyTextToClipboard';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import OptionMenu from './OptionMenu';
import FileAttachment from './FileAttachment';
import URLAttachment from './URLAttachment';
import LocatorToken from './LocatorToken';

export const MSG_ID_PREFIX = 'msgid_';

const isOwnMessage = (senderId: string, userId: string) => {
  return senderId === userId ? '#292f4c' : '#ffffff';
};

const isOwnMessageForText = (senderId: string, userId: string) => {
  return senderId === userId ? 'white' : 'black';
};

const isOwnMessageForPosition = (senderId: string, userId: string) => {
  return senderId === userId ? 'right' : 'left';
};

const isOwnMessageForAlert = (senderId: string, userId: string) => {
  return senderId === userId ? 'left' : 'right';
};

const isOwnMessageForDesignLoadLeft = (senderId: string, userId: string) => {
  return senderId === userId ? '20px' : '0';
};

const isOwnMessageForDesignLoadRight = (senderId: string, userId: string) => {
  return senderId === userId ? '0px' : '20px';
};

const LoadingDots: React.FC = () => (
  <Image
    backgroundColor="transparent"
    objectFit="contain"
    src={loadingDots}
    style={{ padding: '8px 5px' }}
  />
);

const isImage = (fileAttachment: FileAttachmentType) =>
  fileAttachment.type && fileAttachment.type.includes('image');

interface InnerMessageProps {
  isLocatorTokenModeActive: boolean;
  currentUserId: string;
  isImageExpanded: boolean;
  isLoading: boolean;
  isMobile: boolean;
  message: ProcessedMessage;
  onExpandImage: (messageId: string) => void;
  onSelectMessageViaLocatorToken: (message: ProcessedMessage) => void;
  statusMessage: string;
  handleScrollToBottom: () => void;
  first: boolean;
  desktopOwnMessageMargin: string;
}

const getShortUsername = (username: string) => {
  if (username.length > 10) {
    return `${username.slice(0, 7).trim()}...`;
  }
  return username;
};

const MessageRowItem: React.FC<InnerMessageProps> = ({
  isLocatorTokenModeActive,
  currentUserId,
  isImageExpanded,
  isLoading,
  isMobile,
  message,
  onExpandImage,
  onSelectMessageViaLocatorToken,
  statusMessage,
  handleScrollToBottom,
  first,
  desktopOwnMessageMargin,
}) => {
  const timeFromNow = (time: FirestoreTimestamp) => {
    const timestampToDate = new Date(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );

    const dateFromNowText = formatDistanceToNow(timestampToDate, {
      addSuffix: true,
    });

    if (dateFromNowText === 'less than a minute ago') {
      return 'a few moments ago';
    }

    return dateFromNowText;
  };

  const [hoveredMessageId, setHoveredMessageId] = useState<string>('');

  const onHover = (hoveredMessage: ProcessedMessage | '') => {
    return hoveredMessage
      ? setHoveredMessageId(hoveredMessage.id)
      : setHoveredMessageId('');
  };

  const onCopyMessageText = () => {
    copyTextToClipboard(message.text);
  };

  const onCopyMessageImage = async () => {
    return copyImg(message.fileAttachment.url);
  };

  /**
   * A function utilized to download a decrypted file attachment
   * @param fileReferenceUrl - url of the fileAttachment
   * @param name - name of the fileAttachment
   */
  const onDownloadFile = async (fileReferenceUrl: string, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.target = '_blank';
    link.href = fileReferenceUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderAvatar = () => {
    const isCurrentUser = message.senderId === currentUserId;
    if (isCurrentUser) {
      return false;
    }

    return true;
  };
  const marginLeft = isMobile ? '20px' : '20px';
  const marginRight = isMobile ? '12px' : desktopOwnMessageMargin;
  return (
    <Box
      className="message-container"
      style={
        isMobile ? styles.mobileMainContainer : styles.desktopMainContainer
      }
      mb={first ? '23px' : '15px'}
      id={MSG_ID_PREFIX + message.id}
      key={message.id}
    >
      <div
        style={{
          marginLeft:
            isOwnMessageForPosition(message.senderId, currentUserId) === 'right'
              ? 0
              : marginLeft,
          marginRight:
            isOwnMessageForPosition(message.senderId, currentUserId) === 'left'
              ? 0
              : marginRight,
        }}
      >
        {renderAvatar() && !isLoading && (
          <div
            className="animated animatedFadeInUp fadeInUp"
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 10,
              alignItems: 'center',
              float: isOwnMessageForPosition(message.senderId, currentUserId),
              marginLeft:
                isOwnMessageForPosition(message.senderId, currentUserId) ===
                'right'
                  ? marginLeft
                  : 0,
              marginRight:
                isOwnMessageForPosition(message.senderId, currentUserId) ===
                'left'
                  ? marginRight
                  : 0,
            }}
          >
            <DefaultAvatar
              src={message.sender.avatar}
              name={formatEmail(message.sender.name)}
            />
            {getShortUsername(message.sender.name)}
          </div>
        )}
        <Box
          maxW={isMobile ? '95%' : '70%'}
          className="animated animatedFadeInUp fadeInUp"
          float={isOwnMessageForPosition(message.senderId, currentUserId)}
          style={{
            ...styles.messageContainer,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderTopRightRadius: isOwnMessageForDesignLoadRight(
              message.senderId,
              currentUserId
            ),
            borderTopLeftRadius: isOwnMessageForDesignLoadLeft(
              message.senderId,
              currentUserId
            ),
            border:
              message.status !== 200 && !isLoading ? 'solid 1px #ed4337' : '',
            backgroundColor: isOwnMessage(message.senderId, currentUserId),
          }}
          p={3}
          color={isOwnMessageForText(message.senderId, currentUserId)}
          onMouseEnter={() => onHover(message)}
          onMouseLeave={() => onHover('')}
        >
          {isLoading ? (
            <LoadingDots />
          ) : (
            <>
              <>
                <OptionMenu
                  isSender={message.senderId === currentUserId}
                  isVisible={
                    message.id === hoveredMessageId &&
                    message.state !== MessageState.DELETED
                  }
                  onCopyMessageImage={
                    isImage(message.fileAttachment)
                      ? onCopyMessageImage
                      : undefined
                  }
                  onCopyMessageText={
                    !isImage(message.fileAttachment)
                      ? onCopyMessageText
                      : undefined
                  }
                  message={message}
                  onDownloadFile={
                    message.fileAttachment.url
                      ? () =>
                          onDownloadFile(
                            message.fileAttachment.url,
                            message.fileAttachment.name
                          )
                      : undefined
                  }
                />

                {message.fileAttachment?.url && (
                  <FileAttachment
                    isImageExpanded={isImageExpanded}
                    onExpandImage={onExpandImage}
                    message={message}
                    handleScrollToBottom={handleScrollToBottom}
                  />
                )}

                {message.urlAttachments?.length ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  >
                    {message.urlAttachments.map((attachment, index) => {
                      const addMargin =
                        index === message.urlAttachments.length - 1 ? 0 : 8;
                      return (
                        <URLAttachment
                          urlAttachment={attachment}
                          isMobile={isMobile}
                          style={{
                            marginRight: isMobile ? 0 : addMargin,
                            marginTop: isMobile ? addMargin : 0,
                          }}
                        />
                      );
                    })}
                  </div>
                ) : null}
                <div style={styles.textContainer}>
                  {message.status !== 200 && (
                    <img
                      src={iconError}
                      alt="key"
                      style={{
                        float: isOwnMessageForAlert(
                          message.senderId,
                          currentUserId
                        ),
                        marginRight: 4,
                        height: 14,
                      }}
                    />
                  )}

                  <ReactMarkdown
                    className="markdown"
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      a: (props) => (
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={props.href}
                          key={props.key}
                          style={{
                            color: '#6997f4',
                            textDecoration: 'underline',
                          }}
                        >
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {message.text}
                  </ReactMarkdown>
                </div>
              </>

              {message.status === 200 && (
                <Flex alignItems="center">
                  <TimeIcon
                    height="10px"
                    width="10px"
                    fill={isOwnMessageForText(message.senderId, currentUserId)}
                    style={{ marginRight: '5px' }}
                  />
                  <Text style={styles.timeFromNow}>
                    {timeFromNow(message.date)}
                  </Text>
                </Flex>
              )}
            </>
          )}
        </Box>

        <LocatorToken
          isCurrentUser={message.senderId === currentUserId}
          isVisible={isLocatorTokenModeActive}
          locatorToken={statusMessage}
          messageStatus={message.status}
          onClick={() => onSelectMessageViaLocatorToken(message)}
        />
      </div>
    </Box>
  );
};

const styles = StyleSheet.create({
  desktopMainContainer: {
    display: 'inline-block',
    width: '100%',
  },
  mobileMainContainer: {
    display: 'inline-block',
  },
  messageContainer: {
    display: 'inline-block',
    // boxShadow:
    //   '0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  timeFromNow: {
    fontSize: 10,
    whiteSpace: 'nowrap',
  },
});

export default MessageRowItem;
