import { setContactsList, setWorkspacesList } from 'store/reducers/user';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBusinesses, getContacts } from 'dashboard/services/XQSDK';
import { XQStoredWorkspace } from 'dashboard/models/Workspace';

/**
 * a thunk that handles fetching workspaces and contacts for a user's team
 * @returns void
 */
export const onFetchTeam = createAsyncThunk<XQStoredWorkspace[], null>(
  'conversations/team',
  async (_, { dispatch }) => {
    const savedWorkspacesList =
      ((await getBusinesses()) as XQStoredWorkspace[]) || [];
    const contacts = await getContacts();

    dispatch(setWorkspacesList(savedWorkspacesList));
    dispatch(setContactsList(contacts));
    return savedWorkspacesList;
  }
);
