import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMessage } from 'dashboard/services/chat/deleteMessage';
import { RootState } from 'store';

/**
 * A thunk which is utilzied to delete a message from the given conversation via message id
 * @param messageId - ID of the message to be deleted
 */
export const onDeleteMessage = createAsyncThunk<
  void,
  {
    messageId: string;
    fileReferenceUrl?: string;
  }
>(
  'conversations/create',
  async ({ messageId, fileReferenceUrl }, { getState }) => {
    const {
      conversations: { selectedConversation },
    } = getState() as RootState;

    await deleteMessage(selectedConversation.id, messageId, fileReferenceUrl);
  }
);
