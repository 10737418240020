import React, { useState } from 'react';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { FeedbackResponse } from 'dashboard/features/FeedbackForm';
import { ScaleFade } from '@chakra-ui/react';

import { ReactComponent as ExcellentResponseOption } from 'dashboard/features/FeedbackForm/assets/excellent-response.svg';
import { ReactComponent as FairResponseOption } from 'dashboard/features/FeedbackForm/assets/fair-response.svg';
import { ReactComponent as GoodResponseOption } from 'dashboard/features/FeedbackForm/assets/good-response.svg';
import { ReactComponent as PoorResponseOption } from 'dashboard/features/FeedbackForm/assets/poor-response.svg';

interface OptionSectionProps {
  onResponseClick: (response: FeedbackResponse) => void;
  selectedOption: FeedbackResponse | null;
}

/**
 * A screen utilized to select from an options list, with each option representing the rating of the experience
 * @param onResponseClick - a function utilized to update the response option
 */
const OptionSection: React.FC<OptionSectionProps> = ({
  onResponseClick,
  selectedOption,
}) => {
  const [hoveredOption, setHoveredOption] = useState<FeedbackResponse>();

  return (
    <ScaleFade in>
      <div style={styles.mainContainer}>
        <span style={styles.optionSectionTitle}>
          How was your experience today?
        </span>
        <div style={styles.optionsContainer}>
          <div style={styles.option}>
            <PoorResponseOption
              fill={
                hoveredOption === FeedbackResponse.POOR ||
                selectedOption === FeedbackResponse.POOR
                  ? 'rgba(149, 202, 255, 0.4)'
                  : 'white'
              }
              onClick={() => onResponseClick(FeedbackResponse.POOR)}
              onMouseEnter={() => setHoveredOption(FeedbackResponse.POOR)}
              onMouseLeave={() => setHoveredOption(undefined)}
              style={styles.icon}
            />
            <span
              style={
                hoveredOption === FeedbackResponse.POOR ||
                selectedOption === FeedbackResponse.POOR
                  ? styles.optionTextSelected
                  : styles.optionText
              }
            >
              Poor
            </span>
          </div>
          <div style={styles.option}>
            <FairResponseOption
              fill={
                hoveredOption === FeedbackResponse.FAIR ||
                selectedOption === FeedbackResponse.FAIR
                  ? 'rgba(149, 202, 255, 0.55)'
                  : 'white'
              }
              onClick={() => onResponseClick(FeedbackResponse.FAIR)}
              onMouseEnter={() => setHoveredOption(FeedbackResponse.FAIR)}
              onMouseLeave={() => setHoveredOption(undefined)}
              style={styles.icon}
            />
            <span
              style={
                hoveredOption === FeedbackResponse.FAIR ||
                selectedOption === FeedbackResponse.FAIR
                  ? styles.optionTextSelected
                  : styles.optionText
              }
            >
              Fair
            </span>
          </div>
          <div style={styles.option}>
            <GoodResponseOption
              fill={
                hoveredOption === FeedbackResponse.GOOD ||
                selectedOption === FeedbackResponse.GOOD
                  ? 'rgba(149, 202, 255, 0.7)'
                  : 'white'
              }
              onClick={() => onResponseClick(FeedbackResponse.GOOD)}
              onMouseEnter={() => setHoveredOption(FeedbackResponse.GOOD)}
              onMouseLeave={() => setHoveredOption(undefined)}
              style={styles.icon}
            />
            <span
              style={
                hoveredOption === FeedbackResponse.GOOD ||
                selectedOption === FeedbackResponse.GOOD
                  ? styles.optionTextSelected
                  : styles.optionText
              }
            >
              Good
            </span>
          </div>

          <div style={styles.option}>
            <ExcellentResponseOption
              fill={
                hoveredOption === FeedbackResponse.EXCELLENT ||
                selectedOption === FeedbackResponse.EXCELLENT
                  ? 'rgba(149, 202, 255, 0.85)'
                  : 'white'
              }
              onClick={() => onResponseClick(FeedbackResponse.EXCELLENT)}
              onMouseEnter={() => setHoveredOption(FeedbackResponse.EXCELLENT)}
              onMouseLeave={() => setHoveredOption(undefined)}
              style={styles.icon}
            />
            <span
              style={
                hoveredOption === FeedbackResponse.EXCELLENT ||
                selectedOption === FeedbackResponse.EXCELLENT
                  ? styles.optionTextSelected
                  : styles.optionText
              }
            >
              Excellent
            </span>
          </div>
        </div>
      </div>
    </ScaleFade>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionSectionTitle: {
    color: '#202020',
    fontSize: 20,
    padding: '10px 0 25px 0',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '24em',
    padding: '0 10px',
  },
  option: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 1,
  },
  icon: {
    cursor: 'pointer',
  },
  optionText: {
    color: '#202020',
    fontSize: 18,
    fontWeight: 500,
    marginTop: 5,
    userSelect: 'none',
  },
  optionTextSelected: {
    color: '#0082ff',
    fontSize: 18,
    fontWeight: 500,
    marginTop: 5,
    userSelect: 'none',
  },
});

export default OptionSection;
