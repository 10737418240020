import {
  EncryptedMessageDocument,
  ProcessedMessage,
} from 'dashboard/models/Message';
import { MutableRefObject, useEffect } from 'react';
import { Dispatch } from 'redux';
import {
  setNewMessageViewed,
  setShouldDisplayScrollToBottom,
} from 'store/reducers/messages';

interface useOnScrollNotificationProps {
  dispatch: Dispatch;
  newMessageViewed: boolean;
  messageListRef: MutableRefObject<HTMLDivElement>;
  messageLoaders: EncryptedMessageDocument[];
  processedMessages: ProcessedMessage[];
  messageListViewportHeight: number;
  shouldDisplayScrollToBottom: boolean;
}

/**
 * This hook is responsible for listening for scroll events, toggling the new * message notification button/autoscrolling for the user depending on the * * scroll height of the MessageList viewport.
 */
const useOnScrollNotification = ({
  dispatch,
  newMessageViewed,
  messageListRef,
  messageLoaders,
  processedMessages,
  messageListViewportHeight,
  shouldDisplayScrollToBottom,
}: useOnScrollNotificationProps) => {
  useEffect(() => {
    const messageListDiv = messageListRef?.current;

    const shouldDisplayScrollToBottomButton = (el: Element) => {
      return el?.scrollTop > -500;
    };

    const isNewMessageViewed = (el: Element) => {
      return el.scrollTop === 0;
    };

    const scrollListener = () => {
      // If the user receives a message and the bottom div is not in the viewport, display scroll to bottom button.
      if (
        messageListDiv &&
        !shouldDisplayScrollToBottomButton(messageListDiv) &&
        !newMessageViewed
      ) {
        dispatch(setShouldDisplayScrollToBottom(true));
      }

      // If the user scrolls down to the bottom after receiving a new message, hide the scroll to bottom button.
      if (
        messageListRef &&
        messageListDiv &&
        isNewMessageViewed(messageListDiv)
      ) {
        if (shouldDisplayScrollToBottom && newMessageViewed) {
          dispatch(setShouldDisplayScrollToBottom(false));
        }
        if (!newMessageViewed) {
          dispatch(setNewMessageViewed(true));
        }
      }
    };

    scrollListener();

    if (messageListDiv)
      messageListDiv.addEventListener('scroll', scrollListener);

    return () => {
      if (messageListDiv)
        messageListDiv.removeEventListener('scroll', scrollListener);
    };
  }, [
    dispatch,
    messageListViewportHeight,
    messageLoaders,
    processedMessages,
    newMessageViewed,
    messageListRef,
    shouldDisplayScrollToBottom,
  ]);
};

export default useOnScrollNotification;
