import React from 'react';
import SpinnerButton from 'shared/components/SpinnerButton';
import { FormProvider } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import PhoneInputField from '../../../../shared/components/PhoneInputField';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right.svg';
import usePhoneLoginForm from './usePhoneLoginForm';
import { Step } from '.';

const PhoneLogin = ({
  isMobile,
  setStep,
}: {
  isMobile: boolean;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}) => {
  const { onSubmit, form, error } = usePhoneLoginForm(setStep);
  return (
    <FormProvider {...form}>
      <Box as="form" onSubmit={onSubmit}>
        <PhoneInputField name="phone" form={form} responseError={error} />

        <SpinnerButton
          type="submit"
          size={isMobile ? 'md' : 'lg'}
          isLoading={form.formState.isSubmitting}
          // mt={isMobile ? '8px' : 4}
          mt={isMobile ? 4 : 8}
          w="100%"
          role="button"
          backgroundColor="hsla(216, 92%, 59%, 0.85)"
        >
          Send Verification Code
          <ArrowRight style={{ marginLeft: '12px' }} />
        </SpinnerButton>
      </Box>
    </FormProvider>
  );
};

export default PhoneLogin;
