export const generateSubscriptionHeaders = () => {
  const API_KEY = process.env.REACT_APP_VALIDATION_API_KEY;

  // Pre-auth token needed for subscription. After they confirm their account, they will send the pre-auth token to the server in return for an access token
  const localAccessToken = localStorage.getItem('accessToken');
  return {
    'accept-language': 'en_US',
    'api-key': API_KEY || 'API KEY MISSING',
    authorization: `Bearer ${localAccessToken ?? 'AUTHORIZATION MISSING'}`,
    'content-type': 'application/json',
  };
};
