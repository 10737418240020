import { useEffect } from 'react';
import {
  FlyToInterpolator,
  MapRef,
  ViewportProps,
  WebMercatorViewport,
} from 'react-map-gl';

import { getBounds } from '../helpers';
import { Marker } from '../types';

interface useFitMarkersToMapProps {
  destinationPoints: Marker[];
  mapRef: React.RefObject<MapRef>;
  setViewport: React.Dispatch<React.SetStateAction<ViewportProps>>;
  originPoint: Marker | null;
}

/**
 * This hook is responsible for fitting all initial markers to the map upon component mounting,
 * hence the empty dependency array.
 */
export const useFitMarkersToMap = ({
  originPoint,
  destinationPoints,
  setViewport,
  mapRef,
}: useFitMarkersToMapProps) => {
  useEffect(
    () => {
      if (!originPoint || !destinationPoints.length) return;

      // get the bounds of the markers available
      const bounds: [[number, number], [number, number]] = getBounds([
        ...destinationPoints,
        originPoint,
      ]) as [[number, number], [number, number]];

      setViewport((prevViewport) => {
        const transition = {
          transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
          transitionDuration: 2 as const,
        };
        const nextViewPort = new WebMercatorViewport({
          ...(prevViewport as WebMercatorViewport),
          width: mapRef.current?.getMap()._canvas.clientWidth,
          height: 250,
        }).fitBounds(bounds as [[number, number], [number, number]], {
          padding: 100,
          maxZoom: 10,
        });

        return {
          ...nextViewPort,
          ...transition,
        };
      });
    },
    // eslint-disable-next-line
    []
  );
  return null;
};
