import { MessageDocument, MessageState } from 'dashboard/models/Message';
import { AvailableCollections, FIREBASE_FIRESTORE } from '../firebase';

/**
 * A function utilized to delete a message via ID of a given `Conversation`
 * @param conversationId - the ID of the conversation
 * @param messageId - the ID of the message
 * @param fileReferenceUrl
 * @returns void
 */
export const expireMessage = async (
  conversationId: string,
  messageId: string,
  fileReferenceUrl?: string
) => {
  const messageDocumentRef = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  )
    .doc(conversationId)
    .collection(AvailableCollections.MESSAGES)
    .doc(messageId);

  const messageQuerySnapshot = await messageDocumentRef.get();

  const messageDocument = messageQuerySnapshot.data() as MessageDocument;
  const messagelocatorTokenToExpire = encodeURIComponent(
    messageDocument.locatorToken
  );
  const keysToRevoke = [messagelocatorTokenToExpire];

  // If there are no keys to process, skip the rest.
  if (keysToRevoke.length === 0) {
    return '';
  }

  if (fileReferenceUrl) {
    window.URL.revokeObjectURL(fileReferenceUrl);
  }

  const resetMessageDataFields = {
    payload: '',
    state: MessageState.EXPIRED,
  };

  messageDocumentRef.set({ ...resetMessageDataFields }, { merge: true });

  // @ts-ignore // sdk accepts string[] and is typed wrong
  return revokeKeyAccess(keysToRevoke);
};
