import getWindowDimensions from 'dashboard/features/Thread/utils/getWindowDimensions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setMessageListViewportHeight,
  setThreadOuterHeight,
} from 'store/reducers/application';

/**
 * This is hook is used to determine the height of the message list upon component mount and whenever the window dimensions change.
 *
 * @returns the value of the message list height
 */
// TODO: save in store?
const useSetMessageListViewportHeight = (
  messageListHeight: number | undefined
) => {
  const [windowHeight, setWindowHeight] = useState(
    getWindowDimensions().height
  );

  const dispatch = useDispatch();

  // anytime the screenHeight changes, recalculate the messageListHeight
  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowDimensions().height);
    }

    window.addEventListener('resize', handleResize);
    // message list height is 85vh, thus multiplying height by .85
    dispatch(setMessageListViewportHeight(windowHeight * 0.85));
    // reset threadOuterHeight for mobile only if a browser is resized
    dispatch(setThreadOuterHeight(getWindowDimensions().height));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight]);

  return messageListHeight;
};

export default useSetMessageListViewportHeight;
