import * as React from 'react';

import { IconButton, Tooltip } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import secureLock from 'assets/svg/icon-security-small-active-copy.svg';

interface SecureLockButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

const SecureLockButton: React.FC<SecureLockButtonProps> = ({
  onClick,
  style,
}) => (
  <IconButton
    aria-label="back-button"
    colorScheme="transparent"
    isRound
    onClick={onClick}
    size="sm"
    style={{ ...styles.iconButton, ...style }}
  >
    <Tooltip label="View encrypted message locator tokens">
      <img alt="secure-lock" style={styles.icon} src={secureLock} />
    </Tooltip>
  </IconButton>
);

const styles = StyleSheet.create({
  iconButton: {
    height: 40,
    width: 40,
  },
  icon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
});

export default SecureLockButton;
