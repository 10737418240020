import { ConversationDocument } from 'dashboard/models/Conversation';
import { setSelectedSettings } from 'dashboard/services/auth';

import { deleteBulkMessages } from './deleteMessage';
import { AvailableCollections, FIREBASE_FIRESTORE } from '../firebase';

export const deleteConversation = async (
  conversationId: string,
  userId: string,
  fullDeletion = false
) => {
  if (fullDeletion) {
    return FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
      .doc(conversationId)
      .delete();
  }

  const conversationDocumentReference = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  ).doc(conversationId);

  const updatedIsHidden = {
    now: true,
    date: new Date(),
  };

  // This operation set `isHidden` to true for the user.
  // This will hide the conversation for the current user
  // setIsHidden
  await conversationDocumentReference.set(
    {
      isHidden: {
        [userId]: updatedIsHidden,
      },
    },
    { merge: true }
  );

  const messagesListQuerySnapshot = await conversationDocumentReference
    .collection(AvailableCollections.MESSAGES)
    .where('senderId', '==', userId)
    .get();

  const messageIdsToRevoke = messagesListQuerySnapshot.docs.map(
    (messageDocument) => messageDocument.data().id
  );

  deleteBulkMessages(conversationId, messageIdsToRevoke);

  const conversation = (await conversationDocumentReference
    .get()
    .then((conversationSnapshot) =>
      conversationSnapshot.data()
    )) as ConversationDocument;

  // find all deletion settings per user for the conversation
  const allRecipientsIsHiddenSettings = Object.values(
    conversation.isHidden
  ).map((isHiddenSettings) => isHiddenSettings.now);

  const allRecipients = conversation.recipients;

  // if contains no `false` values present, this means all users have deleted the thread
  if (!allRecipientsIsHiddenSettings.includes(false)) {
    allRecipients.forEach(async (recipientId) => {
      await setSelectedSettings(recipientId, '', '');
    });
    return conversationDocumentReference.delete();
  }

  // regardless of deleting the thread, ensure to reset selected settings for user
  return setSelectedSettings(userId, '', '');
};
