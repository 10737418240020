import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import React from 'react';

const AccessDenied: React.FC = () => {
  return (
    <Alert
      status="error"
      flexDirection="column"
      p={8}
      borderRadius="lg"
      textAlign="center"
      mx="auto"
      maxWidth={400}
    >
      <AlertIcon boxSize={12} mb={4} mx={0} />
      <AlertTitle mx={0}>You do not have access to view this page.</AlertTitle>
    </Alert>
  );
};

export default AccessDenied;
