import { LayerProps } from 'react-map-gl';
import { getColorFromThreat } from '../../helpers';

export const generateLineLayer = (
  _id: string,
  threat: number,
  isDotted: boolean
) => {
  const props: LayerProps & { paint: Record<string, unknown> } = {
    id: _id,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': getColorFromThreat(threat),
      'line-width': 3,
    },
  };

  if (isDotted) {
    props.paint['line-dasharray'] = [0.5, 1.5];
  }

  return props;
};
