import { combineReducers } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import applicationSlice, { resetApplicationState } from './application';
import conversationsSlice, { resetConversationState } from './conversations';
import messagesSlice, { resetMessageState } from './messages';
import userSlice, { resetUserState } from './user';

export const rootReducer = combineReducers({
  application: applicationSlice,
  conversations: conversationsSlice,
  messages: messagesSlice,
  user: userSlice,
});

export const resetState = (dispatch: Dispatch) => {
  dispatch(resetUserState());
  dispatch(resetApplicationState());
  dispatch(resetConversationState());
  dispatch(resetMessageState());
};
