/* eslint-disable class-methods-use-this */
import bind from 'bind-decorator';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  dashboardService,
  setAuthToken,
  removeAuthToken,
} from 'backend/services';
import { CreateLogoutResponse } from 'backend/api-types/dashboard';

import {
  authorizeUser,
  authorizeAliasUser,
  dashboardLogin,
  verifyAccount,
  codeValidator,
  temporaryDashboardLogin,
} from 'dashboard/services/XQSDK';
import {
  findOrCreateUser,
  FirebaseSignInAnonymously,
  signOutAnonymously,
  updateUser,
} from 'dashboard/services/auth';
import { fetchAccessToken } from 'backend/xq';
import { setCurrentUser } from 'store/reducers/user';
import { UserModelFields } from 'dashboard/models/User';
import { onFetchTeam } from 'store/thunks/conversations/onFetchTeam';
import { checkForExistingConversationWithConversationId } from 'dashboard/services/chat';
import { resetState } from 'store/reducers';
import { isUserSignedInAnonymously } from 'dashboard/services/firebase';
import store from '../store';
import { Status } from './login/types';

const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_VERIFICATION_INTERVAL = 60000; // 60 Seconds

// TODO: fix the validateSession helper via SDK so we don't have to use dashboard service.
export const isValidSession = async (): Promise<boolean> => {
  try {
    const sessionResponse = await dashboardService.get('/session');
    if (sessionResponse.status !== 204) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export class AuthenticationController {
  public static async requestLoginLink(
    _body: Record<string, unknown>
  ): Promise<void> {
    const host = process.env.REACT_APP_BASE_HOST || '';

    const body = {
      host,
      ..._body,
    };

    await dashboardService.post('/login/link', body);
  }

  private _accessToken?: string;

  private _loading = false;

  private _isLoggingOut = false;

  constructor() {
    this._accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? undefined;
    if (this._accessToken) {
      this._loading = true;
      setAuthToken(this._accessToken);
      this._verifyAccount(true);
    }
  }

  public get accessToken() {
    return this._accessToken;
  }

  public get data(): JwtPayload | null {
    if (!this._accessToken) {
      return null;
    }

    try {
      return jwtDecode(this._accessToken);
    } catch (err) {
      // This token is not a properly formatted JWT token. This should never actually happen but here as a sanity check
      console.error('Invalid JWT Token: ', err);
      return null;
    }
  }

  public get loading() {
    return this._loading;
  }

  public get isLoggingOut() {
    return this._isLoggingOut;
  }

  @bind
  private async _userSignIn(): Promise<void> {
    // use locally cached user data and access user's email for sign-in process
    const userEmail = authController.data?.sub as string;

    if (!userEmail) {
      return this.resetAuth();
    }

    try {
      const fullToken = await fetchAccessToken();

      await authorizeUser({ user: userEmail, accessToken: fullToken });

      const isAuthUserSignedInAnonymously = isUserSignedInAnonymously();

      if (!isAuthUserSignedInAnonymously) {
        await FirebaseSignInAnonymously();
      }

      const state = store.getState();
      const isUserSet = !!state.user.currentUser.id;

      if (!isUserSet) {
        const userDocument = await findOrCreateUser({ email: userEmail });

        // ensure user has valid user document found or created.
        if (!userDocument.email) {
          return this.resetAuth();
        }

        await store.dispatch(onFetchTeam(null));
        store.dispatch(setCurrentUser(userDocument));
      }
    } catch {
      return this.resetAuth();
    }
  }

  @bind
  private async resetAuth() {
    this._accessToken = undefined;
    this._loading = false;
    this._isLoggingOut = false;

    localStorage.removeItem(ACCESS_TOKEN_KEY);
    removeAuthToken();
    resetState(store.dispatch);
  }

  @bind
  private async _checkAuthentication(): Promise<void> {
    await this._verifyAccount(false);

    await this._userSignIn();

    if (this._loading) {
      this._loading = false;
    }

    // Schedule the next check of the whether or not the user is still logged in
    let timeTillNextCheck = ACCESS_TOKEN_VERIFICATION_INTERVAL;
    if (
      this.data?.exp &&
      Date.now() + ACCESS_TOKEN_VERIFICATION_INTERVAL > this.data.exp * 1000
    ) {
      // The token is set to expire before the next check takes place. Lets schedule for 1 millisecond
      // past the expiry instead of waiting the entire interval.
      //
      // Safety applies a minimum wait time of 0 milliseconds in the bizarre event of the wait
      // time becoming negative.
      timeTillNextCheck = Math.max(this.data.exp * 1000 - Date.now() + 1, 0);
    }
    setTimeout(this._checkAuthentication, timeTillNextCheck);
  }

  @bind
  public async logout(): Promise<void> {
    this._isLoggingOut = true;
    try {
      const state = store.getState();

      const { isAliasUser } = state.user.currentUser.settings;

      if (!isAliasUser) {
        await dashboardService.post<CreateLogoutResponse>('/logout');
      }
    } catch (err) {
      // This should only ever fail due to network issues.
      // Let's ignore the error and continue with the logout.
      console.warn('Failed to logout: ', err);
    }
    return this.resetAuth();
  }

  @bind
  public updateAccessToken(token: string) {
    this._accessToken = token;
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    setAuthToken(token);
  }

  // used for google auth
  @bind
  public async federatedLogin(idToken: string): Promise<void> {
    this._loading = true;

    const dashboardAccessToken = await dashboardLogin(idToken);

    this.updateAccessToken(dashboardAccessToken);

    setImmediate(this._checkAuthentication);
  }

  @bind
  public async exchangeForDashboardAccessToken(token: string) {
    this.updateAccessToken(token);

    // replace subscribe accessToken with dashboard accessToken
    const dashboardAccessToken = await temporaryDashboardLogin();

    this.updateAccessToken(dashboardAccessToken.data);
    setImmediate(this._checkAuthentication);

    return dashboardAccessToken.data;
  }

  @bind
  public async loginWithPhone(pin: string) {
    const subscribeExchangeForAccessTokenResponse = await codeValidator(pin);
    if (subscribeExchangeForAccessTokenResponse?.statusCode === 200) {
      // TODO: @mitch: when dashboard login is updated in the jssdk-core, use login method: await this.login(subscribeAccessToken.payload);
      this._loading = true;

      await this.exchangeForDashboardAccessToken(
        subscribeExchangeForAccessTokenResponse.payload
      );

      setImmediate(this._checkAuthentication);
    }
    return subscribeExchangeForAccessTokenResponse;
  }

  @bind
  private async _verifyAccount(setAuthCheck = true) {
    const _isValidSession = await isValidSession();

    if (!_isValidSession || !this._accessToken) {
      return this.resetAuth();
    }

    // set user + profile for the in-memory cache of the JS SDK
    const dashboardAccessToken = await verifyAccount(this._accessToken);

    if (!dashboardAccessToken) {
      // The request was successful but we did not get an access token back. This
      // means that the user is properly verified but does not have access to the
      // dashboard application; they must be a "Vendor" or "Customer"
      return this.resetAuth();
    }

    this.updateAccessToken(dashboardAccessToken);

    this._loading = true;

    if (setAuthCheck) {
      // // Start checking whether or not you still have access
      setImmediate(this._checkAuthentication);
    }
  }

  // TODO(worstestes: 6/13/22): issues with SDK regarding `verify` and `session` endpoints.
  // utilizing the API directly for now
  public async loginViaMagicLink(token: string): Promise<boolean> {
    const res = await dashboardService.get<string>('/login/verify', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200 || !res.data) {
      // The request was successful but we did not get an access token back. This
      // means that the user is properly verified but does not have access to the
      // dashboard application; they must be a "Vendor" or "Customer"
      return false;
    }

    // If we get an access token back, we can log the user in.
    this.updateAccessToken(res.data);

    // Start checking whether or not you still have access
    setImmediate(this._checkAuthentication);
    return true;
  }

  @bind
  public async verifyGuestAccount(
    conversationId: string,
    setStatus: (arg0: Status) => void
  ): Promise<void> {
    // Set alias to use the conversationId as a unique identifier
    const guestUser = `guest-${conversationId.toLowerCase()}`;

    // Sign in to firebase here so we have permissions for the next function call
    await FirebaseSignInAnonymously();
    // look up by conversation ID, if it does't exist, set status Invalid
    const conversation = await checkForExistingConversationWithConversationId(
      conversationId
    );

    if (!conversation.exists) {
      return setStatus(Status.Invalid);
    }

    await this.loginGuest({
      email: guestUser,
      setStatus,
    });
  }

  // Used to authenticate guest users in an authenticated-guest relationship
  @bind
  public async loginGuest({
    email,
    setStatus,
    alias,
  }: {
    email: string;
    isAliasUser?: boolean;
    alias?: string;
    setStatus: (
      authStatus: Status.Initial | Status.Invalid | Status.Complete
    ) => void;
  }): Promise<void> {
    try {
      // if user is already signed into secure chat as an auth user, logout before visiting guest link
      const authorizeAliasUserResponse = await authorizeAliasUser(email);
      const error = authorizeAliasUserResponse?.statusCode !== 200;

      if (error) {
        // TODO(worstestes - 7.8.22): add error handling for guest login via auth screen
        return setStatus(Status.Initial);
      }

      const isAuthUserSignedInAnonymously = isUserSignedInAnonymously();

      if (!isAuthUserSignedInAnonymously) {
        await FirebaseSignInAnonymously();
      }

      const savedUserDocument = await findOrCreateUser({
        email,
        isAliasUser: true,
      });
      let modifiedSavedUserDocument = {
        ...savedUserDocument,
        [UserModelFields.SETTINGS]: {
          ...savedUserDocument.settings,
        },
      };

      // Set the users screen name if they are an unrestricted Guest user
      if (alias) {
        modifiedSavedUserDocument = {
          ...modifiedSavedUserDocument,
          [UserModelFields.NAME]: alias,
        };
      }

      // If User has already viewed their one time access link, sent them to an invalid code screen
      if (
        modifiedSavedUserDocument.settings.oneTimeAccess ||
        !modifiedSavedUserDocument
      ) {
        this._loading = false;
        return setStatus(Status.Invalid);
      }

      const updatedUserData = {
        ...modifiedSavedUserDocument,
        [UserModelFields.SETTINGS]: {
          ...modifiedSavedUserDocument.settings,
          oneTimeAccess: true,
        },
      };

      store.dispatch(setCurrentUser(updatedUserData));
      this._loading = false;
      await updateUser(updatedUserData, modifiedSavedUserDocument);
      return setStatus(Status.Complete);
    } catch (error) {
      await signOutAnonymously();
    }
  }
}

const authController = new AuthenticationController();
export default authController;
