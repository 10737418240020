import * as React from 'react';

interface ProgressDotsProps {
  selectedNumber: number;
}

const ProgressDots: React.FC<ProgressDotsProps> = ({ selectedNumber }) => (
  <div style={styles.progressContainer}>
    <div
      style={
        selectedNumber === 1 ? styles.selectedProgressDot : styles.progressDot
      }
    />
    <div
      style={
        selectedNumber === 2 ? styles.selectedProgressDot : styles.progressDot
      }
    />
  </div>
);

const styles = {
  progressContainer: {
    alignItems: 'center',
    bottom: 50,
    display: 'flex',
    flex: 0.25,
    justifyContent: 'center',
    width: '100%',
  },
  selectedProgressDot: {
    backgroundColor: '#3880F7',
    borderRadius: 5,
    height: 10,
    marginRight: 13,
    width: 10,
  },
  progressDot: {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    borderRadius: 5,
    height: 10,
    marginRight: 13,
    width: 10,
  },
};

export default ProgressDots;
