import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import AuthenticationController from 'auth/AuthenticationController';
import routes from 'routes';

export type CodeLoginFormValues = {
  code: string;
};

const schema = yup.object().shape({
  code: yup.string().required('Enter your pin'),
});

const defaultValues: CodeLoginFormValues = {
  code: '',
};

export default function useCodeValidationForm(): FormHandler<CodeLoginFormValues> {
  const history = useHistory();
  return useFormHandler<CodeLoginFormValues>(
    async ({ code }) => {
      const response = await AuthenticationController.loginWithPhone(code);
      if (response?.statusCode === 200) {
        history.push(routes.home);
      }
      throw new Error('Invalid Pin');
    },
    defaultValues,
    schema
  );
}
