const getInitialMessageLimit = (viewportHeight: number) => {
  switch (true) {
    case viewportHeight < 900:
      return -20;
    case viewportHeight < 1400:
      return -30;
    case viewportHeight >= 1400:
      return -35;
  }
};

export default getInitialMessageLimit;
