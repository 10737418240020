import { AvailableCollections, FIREBASE_FIRESTORE } from '../firebase';

/**
 * A function utilized to update a conversation document's `notification` map field.
 * We use `dismissNotifications` to set the current user's notification value in the current conversation document to 0.
 *
 * @param userId - string
 * @returns Promise<void>
 * */
const dismissNotifications = async (userId: string, conversationId: string) => {
  if (!conversationId) {
    return;
  }

  const conversationRef = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  ).doc(conversationId);

  const doc = await conversationRef.get();

  if (!doc.exists) {
    return;
  }

  return conversationRef.set(
    {
      notifications: {
        [userId]: 0,
      },
    },
    { merge: true }
  );
};

export default dismissNotifications;
