import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import LoginScene from './login/LoginScene';
import EmailLoginResultScene from './login/EmailLoginResultScene';
import InvitedGuestLoginResultScene from './login/InvitedGuestLoginResultScene';
import SMSLoginResultScene from './login/SMSLoginResultScene';

const AuthRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.auth.login} exact>
        <LoginScene />
      </Route>
      <Route path={routes.auth.code} exact>
        <EmailLoginResultScene />
      </Route>
      <Route path={routes.auth.sms} exact>
        <SMSLoginResultScene />
      </Route>
      <Route path={routes.auth.guest} exact>
        <InvitedGuestLoginResultScene />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
