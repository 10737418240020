import React, { useMemo } from 'react';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { isConversationDisabled } from 'dashboard/features/Sidebar/utils/conversationFilter';
import DisplayMessage from 'dashboard/features/ComposeConversation/InviteNewUser/components/RecentMessages/displayMessage';
import { useDispatch } from 'react-redux';
import { onSelectConversation } from 'store/thunks/conversations/onSelectConversation';

type RecentMessagesProps = {
  accessToken: string;
  conversationList: ProcessedConversation[];
  currentUser: User;
};

const RecentMessages: React.FC<RecentMessagesProps> = ({
  accessToken,
  conversationList,
  currentUser,
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allConversations = useMemo(() => conversationList.slice(0, 5), []);

  return (
    <>
      {allConversations.map((conversation: ProcessedConversation) => {
        const isDisabled = isConversationDisabled(conversation);
        return (
          <>
            {!isDisabled && (
              <div
                role="button"
                tabIndex={0}
                style={{ marginRight: 15, marginBottom: 20 }}
                onKeyDown={() => null}
                onClick={() =>
                  dispatch(
                    onSelectConversation({ conversationId: conversation.id })
                  )
                }
              >
                <DisplayMessage
                  currentUser={currentUser}
                  conversation={conversation}
                  accessToken={accessToken}
                />
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default RecentMessages;
