import {
  DesktopViewOptions,
  MobileViewOptions,
  setDesktopView,
  setMobileView,
  setSidebarView,
  SidebarViewOptions,
} from 'store/reducers/application';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConversation } from 'dashboard/services/chat/deleteConversation';
import { RootState } from 'store';
import { setSelectedConversationById } from 'store/reducers/conversations';
import { onSelectConversation } from './onSelectConversation';
import { DeviceType } from '../../../dashboard/models/Device';

/**
 * a thunk utilized to delete a conversation
 *
 * @param conversationId: string
 * @param isFullDeletion: boolean
 * @returns void
 */
export const onDeleteConversation = createAsyncThunk<
  void,
  { conversationId: string; isFullDeletion?: boolean }
>(
  'conversations/delete',
  async (
    { conversationId, isFullDeletion = false },
    { dispatch, getState }
  ) => {
    const {
      conversations: { conversationList },
      user: { currentUser },
      application: { currentDeviceType },
    } = getState() as RootState;

    // This can be re-written, was consolidating deleteConversation functions
    if (currentDeviceType === DeviceType.MOBILE) {
      dispatch(setMobileView(MobileViewOptions.LOADING));
    } else {
      const updatedConversationList = conversationList.filter(
        (conversation) => conversation.id !== conversationId
      );
      const nextConversationId = updatedConversationList[0]
        ? updatedConversationList[0].id
        : '';

      if (!updatedConversationList.length) {
        dispatch(setDesktopView(DesktopViewOptions.EMPTY_STATE));
        dispatch(setMobileView(MobileViewOptions.CONVERSATION_LIST));
      } else {
        // Thunk
        dispatch(onSelectConversation({ conversationId: nextConversationId }));
      }
    }

    await deleteConversation(conversationId, currentUser.id, isFullDeletion);
    if (currentDeviceType === DeviceType.MOBILE) {
      dispatch(setSelectedConversationById(''));
      dispatch(setSidebarView(SidebarViewOptions.CONVERSATION_LIST));
      dispatch(setMobileView(MobileViewOptions.CONVERSATION_LIST));
    }
  }
);
