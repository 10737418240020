import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import {
  setSelectedConversationById,
  setSelectedIncomingConversation,
} from 'store/reducers/conversations';
import CodeSuccess from '../components/CodeSuccess';
import { Status } from '../types';
import { useLoginWithInvitedGuestLink } from './useLoginWithInvitedGuestLink';

const RestrictedGuestLoginResult: React.FC = () => {
  const history = useHistory();
  const { status, conversationId } = useLoginWithInvitedGuestLink();

  const dispatch = useDispatch();
  if (status === Status.Complete) {
    history.push({ pathname: routes.home });
    dispatch(setSelectedIncomingConversation(conversationId));
    // can potentially remove
    dispatch(setSelectedConversationById(conversationId));
  }
  return <CodeSuccess invalid={status === Status.Invalid} />;
};

export default RestrictedGuestLoginResult;
