import * as React from 'react';
import LoadingBox from 'shared/components/LoadingBox';

const LoadingConversation: React.FC = () => (
  <div style={styles.mainContainer}>
    <LoadingBox text="Loading Conversation" />
  </div>
);

const styles = {
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fbfbfb',
  },
};

export default LoadingConversation;
