import { MutableRefObject } from 'react';
import { Dispatch } from 'redux';
import {
  setNewMessageViewed,
  setShouldDisplayScrollToBottom,
} from 'store/reducers/messages';

interface scrollToBottomProps {
  messageListRef: MutableRefObject<HTMLDivElement>;
  dispatch: Dispatch;
}

/**
 * A function utilized to focus on the `messageListRef` mutable ref object set at the bottom of the message list.
 * We fire this function off as soon as messages are done loading
 */
const scrollToBottom = ({ dispatch, messageListRef }: scrollToBottomProps) => {
  if (messageListRef.current) {
    const element = messageListRef.current as Element;

    dispatch(setShouldDisplayScrollToBottom(false));
    dispatch(setNewMessageViewed(true));

    element.scrollTop = 0;
  }
};

export default scrollToBottom;
