import axios from 'axios';
import { generateSubscriptionHeaders } from 'dashboard/utils/headers';
import { subscriptionServiceBaseURL } from '../services';

const requestAccessToken = `${subscriptionServiceBaseURL}/exchange?request=dashboard`;

/**
 * A function utilized to fetch the access token needed to authorize XQ Subscription/Validation operations for the user
 * @returns string
 */
export const fetchAccessToken = async () => {
  const fetchToken = await axios.get(requestAccessToken, {
    headers: generateSubscriptionHeaders(),
  });

  const token: string = await fetchToken.data;

  return token;
};
