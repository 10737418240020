import React from 'react';

import { User } from 'dashboard/models/User';
import { ProcessedConversation } from 'dashboard/models/Conversation';

import { Flex } from '@chakra-ui/react';
import SecureLockButton from 'dashboard/components/SecureLockButton';
import { DeviceType } from 'dashboard/models/Device';
import { CurrentView, DesktopViewOptions } from 'store/reducers/application';
import RecipientHeader from './components/RecipientHeader';
import getRecipientList from './utils/getRecipientList';
import ProfileButton from './components/ProfileButton';

import './DashboardHeader.scss';
import { useGetHeaderText } from './hooks/useGetHeaderText';

interface DashboardHeaderProps {
  currentUser: User;
  currentDeviceType: DeviceType;
  currentView: CurrentView;
  onActivateProfileMode: (user: User) => void;
  onActivateLocatorTokenMode: () => void;
  selectedConversation: ProcessedConversation;
}

/**
 * A component which renders the Dashboard header for the secure chat web variant
 */
const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  currentUser,
  currentDeviceType,
  currentView,
  onActivateProfileMode,
  onActivateLocatorTokenMode,
  selectedConversation,
}) => {
  const recipientList = getRecipientList(
    selectedConversation.recipients,
    currentUser?.id
  );
  const headerText = useGetHeaderText(recipientList, selectedConversation);
  const renderHeaderContent = () => {
    switch (currentView.desktop.main) {
      case DesktopViewOptions.COMPOSE:
        return (
          <div style={styles.conversationDetailSection}>
            <div style={styles.recipientListText}>New Message</div>
          </div>
        );
      case DesktopViewOptions.FEEDBACK_FORM:
        return (
          <div style={styles.conversationDetailSection}>
            <div style={styles.recipientListText}>User Feedback</div>
          </div>
        );
      case DesktopViewOptions.THREAD:
        if (!recipientList.length) {
          return null;
        }

        return (
          <div style={styles.conversationDetailSection}>
            <RecipientHeader
              selectedConversation={selectedConversation}
              currentDeviceType={currentDeviceType}
              headerText={headerText.split(',').join(', ')}
              onActivateProfileMode={onActivateProfileMode}
              recipientList={recipientList}
              currentUser={currentUser}
              avatar={selectedConversation.avatar}
            />

            {!currentUser.settings.isAliasUser && (
              <SecureLockButton onClick={onActivateLocatorTokenMode} />
            )}
          </div>
        );
      default:
        return <div style={styles.conversationDetailSection} />;
    }
  };

  return (
    <div id="header-container">
      <Flex
        alignItems="center"
        width="100%"
        pl="16px"
        pr="16px"
        justifyContent={
          recipientList.length ||
          currentView.desktop.main === DesktopViewOptions.COMPOSE ||
          currentView.desktop.main === DesktopViewOptions.ADD_NAME ||
          currentView.desktop.main === DesktopViewOptions.FEEDBACK_FORM
            ? 'space-between'
            : 'flex-end'
        }
      >
        {renderHeaderContent()}
        <ProfileButton
          currentUser={currentUser}
          onClick={() => onActivateProfileMode(currentUser)}
        />
      </Flex>
    </div>
  );
};

const styles = {
  backIcon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
  conversationDetailSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
  },
  newMessageSection: {
    alignItems: 'center',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
  },

  recipientListText: {
    color: '#101b4f',
    fontSize: '18px',
    fontWeight: 600,
    margin: '0 16px',
  },
  divider: {
    borderLeft: '1px solid #94979b',
    height: 40,
    marginLeft: 14,
    marginRight: 17,
    opacity: 0.2,
  },
};

export default DashboardHeader;
