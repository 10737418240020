import React, { useRef, useState } from 'react';
import {
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { User } from 'dashboard/models/User';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';
import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { DeviceType } from 'dashboard/models/Device';
import { formatEmail } from 'shared/utils/stringFormatting';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { useGetRecipientsString } from 'dashboard/features/Sidebar/hooks/useGetRecipientsString';

import './RecipientHeader.scss';

interface RecipientHeaderProps {
  selectedConversation: ProcessedConversation;
  currentDeviceType: DeviceType;
  headerText: string;
  onActivateProfileMode: (user: User) => void;
  recipientList: User[];
  currentUser: User;
  avatar?: string;
}

// TODO(worstestes) - temp patch for react18 upgrade issue with chakra-ui
const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

const RecipientHeader: React.FC<RecipientHeaderProps> = ({
  currentDeviceType,
  selectedConversation,
  headerText,
  onActivateProfileMode,
  recipientList,
  currentUser,
  avatar,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const popoverRef = useRef(null);
  const [isHovered, setHovered] = useState<string>('');

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  });

  const recipients = useGetRecipientsString(selectedConversation, currentUser);

  // Hack: Chakra UI popover class, when menu is visible we change the zIndex directly
  // since we cannot alter the specific element via css-in-js below in the component
  const container = document.getElementsByClassName('css-n0uled');

  if (container) {
    const containerA = container[0] as HTMLElement;
    const containerB = container[1] as HTMLElement;

    if (containerA && containerB) {
      containerA.style.zIndex = '9999';
      containerB.style.zIndex = '9999';
    }
  }

  const otherRecipientUsers = recipientList.filter(
    (user: { id: string; name: string; email: string }) =>
      user.id !== currentUser.id
  );

  return (
    <Popover
      isOpen={isOpen}
      placement={
        currentDeviceType === DeviceType.MOBILE ? 'bottom' : 'bottom-start'
      }
      onClose={onClose}
      onOpen={onOpen}
      autoFocus={false}
    >
      <PopoverTrigger>
        <div className="trigger-container" ref={popoverRef}>
          <DefaultAvatar
            src={avatar || recipientList[0]?.avatar}
            name={selectedConversation.name || recipients}
            style={styles.avatar}
            number={otherRecipientUsers.length - 1}
            isConversationAvatar
          />

          <div
            style={{
              ...styles.titleText,
              fontSize:
                currentDeviceType === DeviceType.MOBILE && recipients.length > 1
                  ? 13
                  : 16,
            }}
          >
            {headerText}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent style={styles.mainContainer}>
        <PopoverBody className="popover-body">
          {recipientList.map((recipient) => {
            const isSelected = isHovered === recipient.id;
            return (
              <div
                className={`popover-body-item ${isSelected && 'selected'}`}
                onMouseEnter={() => setHovered(recipient.id)}
                onMouseLeave={() => setHovered('')}
                onClick={() => {
                  onActivateProfileMode(recipient);
                  onClose();
                }}
                onKeyDown={() => {
                  onActivateProfileMode(recipient);
                  onClose();
                }}
                role="button"
                tabIndex={0}
                key={recipient.id}
              >
                <DefaultAvatar
                  src={recipient.avatar}
                  name={recipient.name || formatEmail(recipient.email)}
                  style={styles.avatar}
                />
                <div style={styles.itemText}>
                  {recipient.name || recipient.email}
                </div>
              </div>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 6,
    boxShadow: '0 5px 15px 0 rgba(16, 27, 79, 0.15)',
    paddingTop: 16,
    maxHeight: 500,
    zIndex: 100,
  },
  titleText: {
    color: '#101b4f',
    fontSize: 14,
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  triggerContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    margin: '0 0 16px 0',
    padding: 5,
  },
  avatar: { marginRight: 15 },
  itemText: {
    color: '#000',
    fontSize: 15,
    fontWeight: 500,
  },
});

export default RecipientHeader;
