import React from 'react';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';

export type SpinnerButtonProps = ButtonProps;

/**
 * Simple wrapper for the Chakra UI Button to change the default size of its
 * spinner to match the size prop passed to it.
 *
 * The default spinner size looks unproportionally small.
 */
const SpinnerButton: React.FC<SpinnerButtonProps> = (props) => {
  const { size } = props;
  return <Button spinner={<Spinner size={size} />} {...props} />;
};

export default SpinnerButton;
