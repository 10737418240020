import React, { useState } from 'react';

import {
  ConversationState,
  ProcessedConversation,
} from 'dashboard/models/Conversation';
import { User } from 'dashboard/models/User';
import { useDispatch, useSelector } from 'react-redux';
import { onSelectConversation } from 'store/thunks/conversations/onSelectConversation';
import { RootState } from 'store';
import { DesktopViewOptions } from 'store/reducers/application';
import { DeviceType } from 'dashboard/models/Device';
import SectionHeader from '../SectionHeader';
import Conversation from './Conversation';
import { filteredCoversationSearchValidation } from '../../utils/searchInputValidation';

interface ConversationSectionProps {
  conversationList: ProcessedConversation[];
  currentUser: User;
  headerTitle: string;
  isMobile?: boolean;
  isSecurityMode?: boolean;
  searchInput: string;
  currentWorkspaceId: string;
  selectedConversationId?: string | null;
}

const ConversationSection: React.FC<ConversationSectionProps> = ({
  conversationList,
  currentUser,
  headerTitle,
  isMobile = false,
  isSecurityMode = false,
  selectedConversationId,
  searchInput,
}) => {
  const {
    application: { currentView, currentDeviceType },
  } = useSelector((state: RootState) => ({
    application: state.application,
    messageExpiryTime:
      state.conversations.selectedConversation.messageExpiryTimeList,
  }));

  const [isHovered, setHovered] = useState(false);
  const [selectedConversationToHover, setConverationToHover] = useState('');
  const [isListCollapsed, setIsListCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onHover = (hover: boolean, conversationId: string) => {
    setHovered(hover);
    setConverationToHover(conversationId);
  };

  const filteredConversationList = conversationList
    .filter((conversation) =>
      filteredCoversationSearchValidation(conversation, searchInput)
    )
    .map((conversation, index: number) => (
      <div key={conversation.id} className="clickable">
        <Conversation
          messageExpiryTimeValue={
            conversation.messageExpiryTimeList[
              conversation.messageExpiryTimeList.length - 1
            ].value
          }
          conversation={conversation}
          currentUser={currentUser}
          index={index}
          isDisabled={conversation.state === ConversationState.EXPIRED}
          isOptionsMenuVisible={
            isHovered &&
            selectedConversationToHover === conversation.id &&
            currentDeviceType === DeviceType.DESKTOP
          }
          isMobile={isMobile}
          isSecurityMode={isSecurityMode}
          isSelected={
            selectedConversationId === conversation.id &&
            currentView.desktop.main === DesktopViewOptions.THREAD
          }
          onClick={() =>
            dispatch(onSelectConversation({ conversationId: conversation.id }))
          }
          onMouseEnter={() => onHover(true, conversation.id)}
          onMouseLeave={() => onHover(false, '')}
        />
      </div>
    ));

  const renderNotificationIndicator = () => {
    const conversationsWithNotifications = conversationList.filter(
      (conversation) => conversation.notifications[currentUser.id] !== 0
    );

    if (!conversationsWithNotifications.length) {
      return null;
    }

    return <div style={styles.notificationIndicator} />;
  };

  const renderConversationList = () => {
    if (isListCollapsed) {
      return null;
    }

    if (searchInput.length) {
      if (!filteredConversationList.length) {
        return <div style={styles.noResultText}>No Results</div>;
      }
    }

    return filteredConversationList;
  };

  return (
    <div style={styles.mainContainer}>
      <SectionHeader
        title={headerTitle}
        onClick={() => setIsListCollapsed(!isListCollapsed)}
        isListCollapsed={isListCollapsed}
      >
        {renderNotificationIndicator()}
      </SectionHeader>
      {renderConversationList()}
    </div>
  );
};

const styles = {
  mainContainer: {
    marginBottom: 30,
  },
  icon: { color: '#9b9b9b' },
  noResultText: {
    color: '#9b9b9b',
    fontSize: 16,
    fontWeight: 500,
    padding: '0 8px 0 8px',
  },
  notificationIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#ed4337',
  },
};

export default ConversationSection;
