import * as React from 'react';

import { IconButton } from '@chakra-ui/react';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import chevronLeft from 'assets/svg/chevron-left.svg';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label="back-button"
      size="sm"
      style={styles.iconButton}
      isRound
    >
      <img alt="back-button" style={styles.backIcon} src={chevronLeft} />
    </IconButton>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    height: 40,
    width: 40,
    backgroundColor: 'transparent',
  },
  backIcon: {
    display: 'initial',
    height: 24,
    width: 24,
  },
});

export default BackButton;
