import React, { memo } from 'react';
import { Marker as MapMarker } from 'react-map-gl';
import { ReactComponent as DestinationMarker } from 'assets/svg/destination-marker.svg';
import { ReactComponent as DottedLine } from 'assets/svg/dotted-traceroute.svg';
import { Flex } from '@chakra-ui/react';
import { Marker } from '../types';
import { getColorFromThreat } from '../helpers';
import './styles.scss';

interface DestinationPointMarkerProps {
  point: Marker;
  isOriginIp: boolean;
}

const DestinationPointMarker: React.FC<DestinationPointMarkerProps> = memo(
  ({ point, isOriginIp }) => {
    return (
      <MapMarker
        longitude={point.longitude}
        latitude={point.latitude}
        capturePointerMove
      >
        <Flex
          className="destination-marker-container"
          justifyContent="center"
          alignContent="center"
          cursor="pointer"
        >
          {isOriginIp && (
            <DottedLine
              className="destination-marker--dotted-line"
              stroke={getColorFromThreat(point.highestThreat)}
              fill={getColorFromThreat(point.highestThreat)}
            />
          )}

          <DestinationMarker
            className={
              isOriginIp
                ? 'destination-marker--icon-origin'
                : 'destination-marker--icon'
            }
            stroke="white"
            fill={getColorFromThreat(point.highestThreat)}
          />
        </Flex>
      </MapMarker>
    );
  }
);

export default DestinationPointMarker;
