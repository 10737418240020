import { useEffect, useState, useRef } from 'react';
import useSound from 'use-sound';
import notificationSfx from 'assets/sounds/effect3.wav';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useStateRef(initialValue: any) {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}

/**
 * a hook utilized to play a notification sound if the user is not currently focused upon the page
 */
const useSoundNotification = () => {
  const [isPageVisible, setIsPageVisible, ref] = useStateRef(false);

  const [play] = useSound(notificationSfx);

  useEffect(() => {
    const onSetIsPageVisible = (isFocused: boolean) => {
      return setIsPageVisible(isFocused);
    };

    const subscribe = () => {
      document.addEventListener('visibilitychange', () =>
        onSetIsPageVisible(!document.hidden)
      );
      window.addEventListener('blur', () => onSetIsPageVisible(false));
      window.addEventListener('focus', () => onSetIsPageVisible(true));
    };

    subscribe();

    return () => {
      const unsubscribe = () => {
        document.removeEventListener('visibilitychange', () =>
          onSetIsPageVisible(false)
        );
        window.removeEventListener('blur', () => onSetIsPageVisible(false));
        window.removeEventListener('focus', () => onSetIsPageVisible(false));
      };

      return unsubscribe();
    };
  }, [isPageVisible, setIsPageVisible]);

  const handleSoundNotification = () => {
    const isVisible = ref.current;

    if (!isVisible) {
      return play();
    }
  };

  return [handleSoundNotification];
};

export default useSoundNotification;
