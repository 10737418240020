import React from 'react';
import './NotificationCount.scss';

interface NotificationCountProps {
  notificationCount: number;
}

const NotificationCount: React.FC<NotificationCountProps> = ({
  notificationCount,
}) => {
  return <div id="notification-count-container">{notificationCount}</div>;
};

export default NotificationCount;
