import React, { useState, useEffect } from 'react';
import { format, differenceInDays } from 'date-fns';
import { FirestoreTimestamp } from 'dashboard/services/firebase';
import { Box } from '@chakra-ui/react';
import {
  EncryptedMessageDocument,
  ProcessedMessage,
} from 'dashboard/models/Message';
import DefaultAvatar from 'dashboard/components/DefaultAvatar';

import { StyleSheet } from 'dashboard/scss/StyleSheet';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import FileAttachment from 'dashboard/features/Thread/components/MessageRowItem/FileAttachment';
import { User } from 'dashboard/models/User';
import {
  processMessage,
  streamConversationMessages,
} from 'dashboard/services/chat';
import { useGetRecipientsString } from 'dashboard/features/Sidebar/hooks/useGetRecipientsString';

type RecentMessagesProps = {
  conversation: ProcessedConversation;
  currentUser: User;
  accessToken: string;
};

const DisplayMessage: React.FC<RecentMessagesProps> = ({
  conversation,
  currentUser,
  accessToken,
}) => {
  const [message, setMessage] = useState<ProcessedMessage>();
  const timeFromNow = (time: FirestoreTimestamp) => {
    const timestampToDate = format(time.toDate(), 'hh:mm a');
    return timestampToDate;
  };

  const checkWhenDateIs = (time: FirestoreTimestamp) => {
    const dateCheck = differenceInDays(new Date(), time.toDate());

    let textToDisplay;
    if (dateCheck === 0) {
      textToDisplay = 'Today';
    } else if (dateCheck === 1) {
      textToDisplay = 'Yesterday';
    } else {
      textToDisplay = `${dateCheck} days ago`;
    }
    return textToDisplay;
  };
  useEffect(() => {
    const unsubscribe = streamConversationMessages(conversation.id, 'desc', 1, {
      next: async (querySnapshot) => {
        const conversationDocumentList = querySnapshot.docs.map((doc) =>
          doc.data()
        ) as EncryptedMessageDocument[];
        if (conversationDocumentList.length > 0) {
          const processedMessageToDisplay = await processMessage(
            conversationDocumentList[0]
          );
          // should this be in the store as well to maintain data integrity?
          setMessage(processedMessageToDisplay);
        }
      },
    });

    return () => {
      unsubscribe();
    };
  }, [conversation.id, currentUser, accessToken]);

  const recipients = useGetRecipientsString(conversation, currentUser);

  return (
    <>
      {message && (
        <div className="or" style={{ marginBottom: 5 }}>
          {checkWhenDateIs(message.date)}
        </div>
      )}
      <div
        style={{
          ...styles.conversationHeaderContainer,
          marginBottom: conversation.recipients.length > 2 ? 5 : 10,
          marginTop: 3,
        }}
      >
        {message && (
          <DefaultAvatar
            src={conversation.recipients[0]?.avatar}
            name={conversation.name || recipients}
          />
        )}
        <div style={{ marginLeft: 8 }}>
          {message && (conversation.name || recipients)}
        </div>
      </div>
      {message && (
        <Box
          width="99%"
          className="animated animatedFadeInUp fadeInUp"
          style={styles.messageContainer}
          p={3}
          color="black"
        >
          {message && (
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: '#9b9b9b',
                float: 'right',
                marginBottom: 4,
              }}
            >
              {timeFromNow(message.date)}
            </div>
          )}
          <>
            {message && message.fileAttachment?.url && (
              <FileAttachment message={message} />
            )}
            <div>{message && message.text}</div>
          </>
        </Box>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  singleAvatar: {
    height: '41px',
    width: '40px',
    boxShadow:
      '0 6px 8px 0 rgba(0, 0, 61, 0.2), inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    marginRight: 15,
  },
  conversationHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messageContainer: {
    backgroundColor: 'white',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '20px',
    boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
    marginRight: '15px',
  },
});

export default DisplayMessage;
