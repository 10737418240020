import React from 'react';

import { AddIcon } from '@chakra-ui/icons';
import './ComposeConversationButton.scss';

type ComposeConversationButtonProps = {
  onClick: () => void;
  style?: Record<string, unknown>;
  containerStyle?: Record<string, unknown>;
};

const ComposeConversationButton: React.FC<ComposeConversationButtonProps> = ({
  onClick,
  containerStyle,
}) => (
  <button
    onClick={onClick}
    aria-label="compose-conversation"
    style={containerStyle}
    id="compose-pill"
    type="button"
  >
    <AddIcon w={5} h={5} color="white" />
    <span className="button-text">compose</span>
  </button>
);

export default ComposeConversationButton;
