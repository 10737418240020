import { extendTheme } from '@chakra-ui/react';
import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import shadows from './foundations/shadows';
import typography from './foundations/typography';
// import Accordion from './components/accordion';
import Alert from './components/alert';
import Badge from './components/badge';
import Button from './components/button';
import Code from './components/code';
import Form from './components/form';
import FormError from './components/form-error';
import FormLabel from './components/form-label';
import Input from './components/input';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import Select from './components/select';
import Switch from './components/switch';
import Tabs from './components/tabs';
import Textarea from './components/textarea';
import styles from './styles';

const customChakraTheme = extendTheme({
  breakpoints,
  colors,
  shadows,
  ...typography,
  components: {
    // Accordion,
    //
    Alert,
    Badge,
    Button,
    Code,
    Form,
    FormError,
    FormLabel,
    Input,
    Link, // is only this being used?
    Menu,
    Modal,
    Select,
    Switch,
    Tabs,
    Textarea,
  },
  styles,
});

export type CustomTheme = typeof customChakraTheme;

export default customChakraTheme;
